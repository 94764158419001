.select2-selection--single {
  background-color: #fff;
  border: 1px solid $border-input;
  border-radius: 0;

  .select2-selection__rendered {
    color: $color-input;
    line-height: $height-input;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: $color-input;
  }

  .select2-selection__arrow {
    height: $height-input;

    position: absolute;

    top: 1px;
    right: 1px;

    width: 20px;

    b {
      @include icon-svg('arrow_down_black', 16px, 16px);
      left: 0;

      margin-left: -10px;
      margin-top: -8px;

      position: absolute;

      top: 50%;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #white;
    cursor: default;
    opacity: 0.3;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        border-color: transparent transparent #888 transparent;
        border-width: 0 4px 5px 4px;
      }
    }
  }
}
