.maison {
	.cover {
		height: calc( 100vh - #{$header-height} );
		margin: 0 -20px;
		overflow: hidden;
		.cover-img {
			width: 100%;
			height: 100%;
			background: url('../images/maison/new_header_desktop.jpg') center center no-repeat;
			background-size: cover;
			transform: scale(1.4);
			transition: 6s;
			@include phone {
				background-image: url('../images/maison/new_header_mobile.jpg');
			}
			&.imgscale {
				transform: scale(1);
			}
		}
		h1 {
			font-size: 3rem;
			letter-spacing: 1.3rem;
			span {
				display: block;
				margin-bottom: 30px;
				font-size: 1.6rem;
				letter-spacing: 0.7rem;
			}
		}
	}
	h3 {
		font-size: 1.4rem;
		letter-spacing: 0.4rem;
		line-height: 1.43;
		margin-bottom: 30px;
		@include phone {
			font-size: 1.2rem;
			line-height: 1.5;	
			margin-bottom: 20px;
			letter-spacing: 0.2rem;
		}
	}
	p, 
	cite {
		font-size: 1.4rem;
		color: $gray1;
		line-height: 1.31;
		letter-spacing: 0.14rem;
		font-weight: 300;
		@include phone {
			font-size: 1.3rem;
			line-height: 1.5;	
			letter-spacing: 0.14rem;
		}
	}
	blockquote,
	cite {
		text-align: center;
		display: block;
	}
	cite {
		margin-bottom: 10px;
	}
	section {
		padding: 0 8%;
		margin-bottom: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include phone {
			flex-direction: column;
			padding: 0;
			margin-bottom: 30px;
		}
		img,
		.content {
			position: relative;
			transition: ease-in-out;
		}
		.content {
			max-width: 40%;
			@include bigdesktop {
				max-width: 28%;
			}
			@include phone {
				max-width: 100%; 
			}
		}
		p {
			@include phone {
				overflow: hidden;
				line-height: 3.5ex;
				max-height: 17ex;
			}
		}
		.expand {
			.btn-pink-blog {
				display: none;
			}
			p {
				@include phone {
					max-height: none;
				}
			}
		}
		img {
			max-width: 60%;
			width: 60%;
			height: auto;
			@include bigdesktop {
				max-width: 72%;
				width: 72%;
			}
			@include bigphone {
				max-width: 700%; 
				width: 70%; 
			}
			@include phone {
				max-width: 100%; 
				width: 100%; 
			}
		}
		&.section-1 {
			flex-direction: column;
			padding: 12rem 20px;
			max-width: 665px;
			margin: 0 auto;
			text-align: center;
			@include phone {
				padding: 20px 0;
			}
			h2 {
				font-weight: 700;
				font-size: 1.8rem;
				letter-spacing: 1.4rem;
				line-height: 1.31;
				@include phone {
					line-height: 1.86;	
					font-size: 1.4rem;
					letter-spacing: 0.4rem;
				}
				span {
					display: block;
					font-size: 1.6rem;
					line-height: 2.78;	
					letter-spacing: 0.6rem;			
					@include phone {
						font-size: 1.2rem;
						line-height: 1.5;	
						letter-spacing: 0.14rem;
					}
				}
			}
			p {
				font-size: 1.6rem;
				@include phone {
					font-size: 1.2rem;
				}
			}
		}
		&.maison-slider {
			overflow: hidden;
			max-width: calc( 100vw - 40px );
			@include phone {
				flex-direction: row;
			}
			.scroller {
				overflow-x: auto;
  				min-width: 100%;
			}
			.slide-container {
				display: flex;
				padding-bottom: 46px;
				@include phone {
					padding-bottom: 20px;
				}				  
				.slide {
					padding-right: 24px;
					position: relative;
					$slide2w: 22vw;
					$slide2mw: 426px;
					$slide3w: 27vw;
					$slide3mw: 537px; 
					&.slide-1 {
						min-width: 28vw;
						max-width: 552px; 
						@media (min-width: 1920px) {
							min-width: 522px;
						}
						@include phone {
							width: 96vw;
							min-width: 96vw;
							max-width: 96vw;
						}
						.product-info {
							width: 22vw;
							max-width: 426px; 
							padding-right: 48px;
							@media (min-width: 1920px) {
								min-width: 426px;
							}
							@include phone {
								width: 96vw;
								min-width: 96vw;
								max-width: 96vw;
							}
						}
					}
				}
			}
			h2 {
				font-size: 3rem;
				font-weight: 700;
				letter-spacing: 1.3rem;
			}
			img {
				max-width: 100%;
				height: auto;
			}
			.slide-big {
				display: flex;
				flex-direction: column;
				justify-content: center;
				@include bigphone {
					flex-direction: row;
				}
				img {
					flex-basis: 30%;
					align-self: center;
					max-width: 200px;
					width: 100%;
					height: auto;
					@include bigphone {
						max-width: 250px;
						flex-shrink: 0;
						flex-basis: auto;
					}
				}
			}
			.title {
				text-transform: none;
				letter-spacing: 0.2rem;
				line-height: 1.43;
				margin-bottom: 20px;
			}
			.item {
				max-width: 1000px;
				display: flex;
				width: 50vw;
				align-items: center;
				@include bigphone {
					flex-direction: column;
    				justify-content: center;
					margin: 0 10px;
				}
				@include phone {
					width: calc(95vw - 20px);
				}
				@include bigphone {
					&:nth-child(odd) {
						img {
							order: 1;
							margin-bottom: 40px;
						}
						.product-info {
							order: 2;
						}
					}
					&:nth-child(even) 	{
						img {
							order: 2;
						}
						.product-info {
							order: 1;
							margin-bottom: 40px;
						}
					}
				}
			}
			.product-info {
				flex-basis: 70%;
				@include bigphone {
					flex-basis: auto;
				}
			}
			.top,
			.bottom {
				display: flex;
			}
			.top {
				margin-bottom: 40px;
				@include bigphone {
					margin-bottom: 0;
				}
				.item {
					margin-right: 40px;
					@include bigphone {
						margin-right: 10px;
					}
				}
				.product-info {
					padding-left: 40px;
					@include bigphone {
						padding-left: 0px;
					}
				}
			}
			.bottom {
				.product-info {
					order: -1;
				}
				.item {
					margin-left: 40px;
					@include bigphone {
						margin-left: 10px;
					}
				}
				.product-info {
					padding-right: 40px;
					@include bigphone {
						padding-right: 0px;
					}
				}
			}
			.intro-section {
				p {
					max-width: 426px;
					margin-bottom: 50px;
				}
			}
		}
	}
}
