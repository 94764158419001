.customer-cover {
	h1 {
		color: white;
	}
}


.account {
	
	p {
		color: $gray1;
		font-weight: 300;
		line-height: 1.57;
		letter-spacing: 1px;
		font-size: 1.4rem;
	}

	.columns {
		max-width: 1260px;
		margin: 0 auto;
	}

	.block-title {
		margin-bottom: 40px;
		strong {
			font-weight: 600;
			font-size: 2rem;
			letter-spacing: 0.2rem;
		}
	}

	.box-actions {
		.action {
			@extend .button;
			@extend .black-button;
			&:hover {
				color: white;
			}
		}
	}

	.link {
		text-decoration: underline;
		font-size: 1.3rem;
		letter-spacing: 0.03rem;
	}

	#selected--zg-ul-select {
		@include phone {
			text-transform: uppercase;
		}
	}

}

.account {
	.welcome {
		margin-bottom: 60px;
	}
	.welcome-title {
		font-size: 2rem;
		letter-spacing: 0.4rem;
		text-transform: uppercase;
		margin-bottom: 14px;
		@include phone {
			font-size: 1.4rem;
			letter-spacing: 0.28rem;
		}
	}
	.page-main {
		@include phone {
			padding-top: 0;
		}
	}
	.page.messages {
		@include phone {
			margin-bottom: 0;
		}
	}
	.column.main {
		.block:not(.widget) {
			.block-content {
				display: flex;
				align-items: stretch;
				@include phone {
					flex-direction: column;
				}
				.box {
					display: flex;
					flex-direction: column;
					&:nth-child(even),
					&:nth-child(odd) {
						float: none;
					}
					@include phone {
						margin-bottom: 32px;
					}
					.box-actions {
						margin-top: auto;
						padding-top: 40px;
						@include phone {
							padding-top: 20px;
						}
					}
				}
			}
			.block-title {
				margin-bottom: 40px;
				@include phone {
					margin-bottom: 20px;
				}
				& > strong {
					font-weight: 600;
					font-size: 2rem;
					letter-spacing: 0.2rem;
					@include phone {
						font-size: 1.6rem;
						letter-spacing: 0.16rem;
					}
				}
			}
			.box-title {
				margin-bottom: 18px;
				@include phone {
					margin-bottom: 20px;
				}
				& > span {
					font-weight: 500;
					letter-spacing: 0.14rem;
					color: #1e1e1c;
				}
			}
		}
	}
}

.account.page-layout-2columns-left {
	.columns{
		display: flex;
	}
	.column.main {
		width: 76%;
		flex-basis: 76%;
		float: none;
		padding-top: 20px;
		padding-left: 100px;
		@include phone {
      		padding-left: 0px;
      		order: 2;
    	}
	}
	.sidebar-main{
		flex-basis: 24%;
		width: 24%;
		float: none;
		min-width: 280px;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

	.account.page-layout-2columns-left {
		.columns{
			display: block;
		}
		.column.main {
			width: 76%;
			float: right !important;
			padding-top: 20px;
			padding-left: 100px;
		}
		.sidebar-main{
			width: 24%;
			float: left !important;
			min-width: auto;
		}
	}

}

.block-collapsible-nav {
	@include phone {
		margin-bottom: 0;
		position: initial;

		.block-collapsible-nav-title {
			display: none;
		}
		.block-collapsible-nav-content {
			border: none;
		}
	}
	.item {
		margin: 0;
		border-left: 2px solid #e7e7e7;
		padding: 0 0 20px 0;
		font-size: 1.3rem;
		letter-spacing: 0.2rem;
		font-weight: 500;
		text-transform: uppercase;
		@include phone {
			padding: 0;
			text-transform: none;
			border: none;
		}
		a {
			&:hover {
				background: transparent;
				border-left: 2px solid #000;
				@include phone {
					border: none;
				}
				strong {
					border: none;
				}
			}
		}
		a, 
		strong {
			padding-left: 30px;
			margin-left: -2px;
			border-left: 2px solid transparent;
			@include phone {
				padding-left: 0;
				border: none;
				margin-left: 0;
				text-transform: uppercase;
			}
			strong {
				border: none;
				padding-left: 0;
				margin-left: 0;
			}
		}
		&.current {
			a, 
			strong {
				font-weight: 600;
				border-left: 2px solid #000;
				@include phone {
					font-weight: 500;
					border: none;
				}
				strong {
					border: none;
				}
			}
		}
	}

	.content {
		background: transparent;
	}
}

.block-dashboard-addresses,
.block-dashboard-info {
	padding-bottom: 42px;
	margin-bottom: 50px;
	border-bottom: 1px solid #d5d5d5; 
	@include phone {
		padding-bottom: 0;
		margin-bottom: 27px;
	}
}

.block-addresses-list {
	.block-content {
		color: $gray1;
		font-weight: 300;
		line-height: 1.57;
		letter-spacing: 1px;
		font-size: 1.4rem;
		li {
			width: 100%;
		}
	}
	.items.addresses {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.actions {
		display: flex;
		justify-content: space-between;
		padding-top: 40px;
		@include phone {
			padding-top: 0px;
			margin-top: 0;
		}
		a {
			color: black;
			text-decoration: underline;
		}
	}
}

.box-address-billing,
.box-address-shipping,
.block-dashboard-addresses {
	@include phone {
		padding-bottom: 27px;
	}
	.box-content {
		color: $gray1;
		font-weight: 300;
		line-height: 1.57;
		letter-spacing: 1px;
		font-size: 1.4rem;
	}
	.box-actions {
		padding-top: 40px;
		@include phone {
			padding-top: 0px;
			margin-top: 0;
		}
	}
}

.block-dashboard-orders {
	@include phone {
		overflow: hidden;
	}
	.box-actions {
		padding-top: 40px;
		@include phone {
			padding-top: 20px;
		}
	}
}

 .form-address-edit .actions-toolbar .action.primary {
 	font-size: 1.1rem;
 }

//RETURNS

.aw-rma-new {
	.title {
		font-weight: 500;
		text-transform: none;
		font-size: 1.4rem;
		letter-spacing: 0.1rem;
	}
}

.aw-rma__return-item-container .item-details.product-item {
	padding-left: 0;
}

.aw-rma__field {
	.select2-container,
	.select2-hidden-accessible{
		width: 100% !important;
	}
}

.aw-rma__actions,
.aw-rma__return-massaction {
	a, button {
		@include phone {
			width: 100%;
			margin-bottom: 15px !important;
			text-align: center;
		}
	}
}


//LOGIN SIGNUP

.customer-account-create,
.customer-account-login {
	.page-title {
		@extend h2;
	}
	.page-main {
		padding-top: 40px;
		max-width: 1260px;
	}
	.form.form-login {
		.actions-toolbar {
			.secondary {
				float: right;
			}
		}
	}
}

// Account Order List
.account.sales-order-history {
	.order-products-toolbar {
		.pages {
			position: relative;
			top: -22px;
		}
	}
}

// Account edit information
.account.customer-account-edit {
	.form.form-edit-account {
		.actions-toolbar {
			.secondary .action.back {
				display: block;
			}
		}
	}
}