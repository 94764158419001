
$max-full-width: 3840px;

//Homepage category tile
body.cms-index-index {
    .main-wrapper {
        .page-main {
            padding-right: 0px;
            padding-left: 0px;
            max-width: $max-full-width;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .row-full-width-inner {
        max-width: $max-full-width !important;
    }

    .columns .column.main {
        padding-bottom: 0px;
    }
}

//full main slider on homepage
body.cms-index-index .homepage-fullwidth {
    overflow: hidden;
    position: relative;
    width: 100vw;
    z-index: 1;
    margin-top: -96px;
    margin-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    .pagebuilder-slide-wrapper .pagebuilder-poster-content {
        align-self: self-end;
        margin-bottom: 65px;
        color: $white;

        @include tablet-down {
            bottom: 55px;
        }

        [data-element="content"] {
            h2 {
                @include deskH1;
                text-transform: inherit;
            }
            p {
                @include deskB1;
                @include tablet-down {
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }
        button.pagebuilder-slide-button {
            @include deskUI1;
            background-color: transparent;
            border: none;

        }
    }

    .homepage-fullslider ul.slick-dots {
        position: absolute;
        bottom: 20px;
        width: auto;
        @include center-horizontal;

        li {
            width: 6px;
            height: 6px;

            button {
                border: none;
                background: $white50;
                width: 6px;
                height: 6px;
            }

            &.slick-active button {
                border-color: $white;
                background: $white;
            }
        }
    }
}

// Slider de categories en homepage
body.cms-index-index {
    .homepage-categories {
        .slick-list {
            @media only screen and (max-width: 640px) {
                padding-left: 0px !important;

            }
        }
        .slick-slide {
            .pagebuilder-collage-content {
                color: $white;
                font-size: 17px;
                font-family: 'Jost';
                line-height: 24px;
                font-weight: normal;
                text-transform: none;
                position: relative;
                margin-top: -40px;
                left: 40px;
                height: 24px;
                @media only screen and (max-width: 640px) {
                    left: 20px;
                }
            }
            .pagebuilder-slide-wrapper {
                height: 75vb !important;
            }
            @media only screen and (max-width: 640px) {
                .pagebuilder-slide-wrapper {
                    width: 100%;
                    height: 0 !important;
                    padding-top: 125%;
                    background-size: contain !important;
                    min-height: fit-content !important;
                }
            }
            .left[data-content-type="slide"] {
                padding-right: 10px;
                min-height: fit-content !important;
            }
            .center[data-content-type="slide"] {
                padding-right: 10px;
                min-height: fit-content !important;
            }
            .right[data-content-type="slide"] {
                padding-right: 10px;
                min-height: fit-content !important;

                @media only screen and (max-width: 640px) {
                    padding-right: 10px;
                }
            }
            .pagebuilder-overlay {
                width: 100%;
                position: absolute;
                bottom: 0px;
                float: left;
                text-align: left;
                padding-left: 10px;
                @media only screen and (max-width: 640px) {
                    padding: 0;
                }
            }
        }

    }
}


//Slider de produits sur la homepage
body.cms-index-index {
    .homepage-products-slider {
        padding-left: 20px;
        padding-top: 50px;
        height: 820px;
        @include phone {
            padding-left: 0px;
            padding-bottom: 40px;
            height: auto;
        }

        .product-items {
            width: calc(100dvw - 60px);
            @include tablet-down {
                width: 100%;
            }
        }
        .product-item:first-child {
            padding-left: 20px;
        }
        .slick-slide .product-item {
            .product-image-photo {
                position: relative;
                width: 100%;
            }
            .product-item-photo-wrapper {
                background-color: $bgbeigeclair;
            }
            .product-item-details {
                font-family: 'Jost';

                .price-container,
                .product-item-metal {
                    line-height: 23px;
                    font-size: 15px;
                    color: $gray4;
                }
            }
        }
        .products-slider-navigation {
            width: calc(100vw - 65px);
            text-align: right;

            .products-slider-title {
                float: left;
                text-align: left;
                padding-top: 0px;
                padding-left: 20px;
                margin-left: 0px;
                position: absolute;
            }

            button {
                border: none;
                border-radius: 0;
                cursor: pointer;
                font-size: 0;
                height: 50px;
                line-height: 0;
                outline: none;
                padding: 0;
                width: 50px;
            }

            .carousel-prev:before,
            .carousel-next:before {
                font-family: 'pagebuilder-font';
                font-size: 12px;
                color: $gray16;
            }
            .carousel-next:before {
                content: '\f102';
            }
            .carousel-prev:before {
                content: '\f103';
            }
            .pagination {
                display: inline;
                @include chiffres;
                color: $darkgray;
                vertical-align: middle;

                span {
                    @include chiffres;
                }
            }

            //Hide pagination
            @include tablet-down {
                button,
                .carousel-prev,
                .carousel-next,
                .pagination {
                    visibility: hidden;
                }

            }

        }
    }
    .homepage-products h2 {
        @include deskH1;
        color: $darkgray;
        padding-top: 120px;
        padding-left: 40px;
        text-transform: inherit;
    }
}

//Slider collections homepage
body.cms-index-index {
    .homepage-collections {
        .slick-list {
            .slick-slide {
                position: relative;

                .pagebuilder-poster-content {
                    position: absolute;
                    bottom: 20px;

                    @include tablet-down {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    .pagebuilder-slide-button {
                        @include tablet-down {
                            display: none;
                        }

                        &.pagebuilder-button-link {
                            @include deskBtn1;
                            color: $white;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .dots-slide-title {
            .slick-dots {
                list-style-type: none;
                margin: 25px 0 0;
                padding: 0;
                position: absolute;
                bottom: 80px;
                @include tablet-down {
                    display: list-item;
                }

                li {
                    width: auto;
                    height: auto;
                    display: inline-block;
                    padding: 0 5px;
                    @include tablet-down {
                        display: block;
                        float: none;
                    }


                    button {
                        @include deskH1;
                        background: transparent;
                        width: auto;
                        border: none;
                        text-indent: inherit;
                        font-size: 32px;
                        height: auto;
                        padding: 10px;
                        opacity: 0.5;
                        color: $white;
                        &:before {
                            content: none;
                        }
                        &:hover {
                            opacity: 1;
                        }
                        @include tablet-down {
                            @include mobileH1;
                        }
                    }

                    &.slick-active {
                        button {
                            opacity: 1;
                        }

                    }
                }
            }
        }
    }
}


//Quote sur la homepage
body.cms-index-index .homepage-quote {
    margin-right: 200px;
    margin-left: 200px;
    min-height: 648px;

    .row-full-width-inner {
        margin: auto;

        [data-content-type="text"] {
            max-width: 1040px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include phone {
        margin-right: auto;
        margin-left: auto;
    }

    h2 {
        @include deskUI2;
        color: $darkgray;
    }

    p {
        @include deskH1;
        margin-top: 30px;
        color: $darkgray;
        @include phone {
            font-size: 23px;
            line-height: 31px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .black .pagebuilder-button-primary {
        background-color: $darkgray;
        padding: 18px 32px 17px 32px;
        border-color: $darkgray;
        font-family: 'Raleway';
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
        margin-top: 50px;
        border-radius: 0;

        span {
            @include deskBtn1;
        }

        &:visited,
        &:active,
        &:hover {
            color: $white;
        }
    }
}

body.cms-index-index {
    .homepage-push-left,
    .homepage-push-right {
        height: 100vb;
    }
}

.product-reinssurance,
.homepage-reinsurance {

    .row-full-width-inner {
        padding: 50px 0 60px;
        max-width: none !important;
        margin: 0 !important;

        @include tablet-down {
            padding: 35px 0 80px;
        }

        .pagebuilder-slider {
            min-height: auto !important;

            .pagebuilder-slide-wrapper {
                min-height: auto !important;

                .reinssurance-item {
                    h4 {
                        @include deskB1;
                        color: $darkgray;
                        text-align: center;
                        margin-bottom: 15px;
                        margin-top: 15px;
                    }
                    img {
                        width: 48px;
                        margin: auto;
                    }

                    .description {
                        @include deskB2;
                        text-align: center;
                        margin: 0 10%;
                        color: $gray4;

                        @include tablet-down {
                            display: none;
                        }
                    }
                }
            }

            .slick-dots {
                padding-bottom: 50px;

                @include tablet-down {
                    padding: 0;
                    position: absolute;
                    bottom: 0;
                }

                li {
                    &.slick-active button {
                        background-color: $darkgray;

                        @include tablet-down {
                            width: 6px;
                            height: 6px;
                        }
                    }

                    button {
                        background-color: $darkgray20;

                        @include tablet-down {
                            width: 6px;
                            height: 6px;
                        }
                    }
                }
            }

        }
    }

    .slick-dots {
        padding-bottom: 50px;

        li {
            width: 6px;
            height: 6px;

            button {
                border: none;
                background: $darkgray20;
                width: 6px;
                height: 6px;
            }

            &.slick-active button {
                border-color: $darkgray;
                background: $darkgray;
            }
        }
    }
}

.homepage-contentpush-bottom {
    .pagebuilder-column {
        position: relative;
    }
    @include desktop {
        .pagebuilder-column:first-child {
            margin-right: 5px;
        }
        .pagebuilder-column:last-child {
            margin-left: 5px;
        }
    }
    [data-content-type="buttons"] {
        position: absolute;
        bottom: 30px;
        width: 100%;
        a.pagebuilder-button-link {
            color: $white;
            @include deskH2;

            &:hover {
                text-decoration: none;
            }

            span {
                @include deskH2;
            }
        }
        @include tablet-down {
            bottom: 10px;
        }
    }
}


.mention-text {
    max-width: 642px;
    margin: auto;
    padding-top: 15px;

    @include phone {
        padding: 0 16px;

        & > *
         {
            margin-bottom: 15px;
        }
    }

    .body-1 {
        @include deskB1;

        * {
            @include deskB1;
        }

        strong {
            @include bold;
        }
    }

    .body-2 {
        @include deskB2;

        * {
            @include deskB2;
        }

        strong {
            @include bold;
        }
    }

    a {
        text-decoration: underline;
    }
}

.cms-page-view{
    @include phone {
        .main-wrapper {
            margin: 0;
        }
    }
}
