.page-footer {
  overflow: hidden;
  background-color: $darkgray;

  .footer {
    border: none;
    max-width: 100%;
    height: 470px;
    padding: 0;
    margin: 60px 40px 20px;
    color: $white;
    position: relative;
    @include deskB2;

    @include tablet-down {
      margin: 53px 28px 20px;
      padding-bottom: 80px;
      height: auto;
    }

    a {
      color: $white;
      text-decoration: none;
    }

    .gray {
      color: $gray4;
      padding-bottom: 3px;
      margin: 0;
      @include deskB2;
    }


    .logo {
      margin: 0;

      @include tablet-down {
        float: none;
      }

      .black {
        display: none;
      }

      img {
        height: 47px;

        @include tablet-down {
          margin: auto;
        }
      }
    }

    .footer-description {
      position: absolute;
      top: 77px;
      max-width: 243px;

      @include tablet-down {
        position: initial;
        max-width: none;
        margin-top: 30px;
      }

      .gray {
        @include tablet-down {
          max-width: 243px;
          margin: auto;
          text-align: center;
        }
      }

      .contact {
        margin-top: 15px;
        @include deskB2;
      }
    }

    .navigation_footer {
      width: 600px;
      position: absolute;
      top: 24px;
      left: 342px;

      @include tablet-down {
        position: initial;
        width: 100%;
        margin: 26px 3px 0;
      }

      .dropdown-menu {
        display: contents;

        .megamenu-content {
          display: flex;
          column-gap: 90px;
          justify-content: space-around;

          @include tablet-down {
            flex-direction: column;
            row-gap: 16px;
          }

          .title {
            @include tablet-down {
              display: flex;
              justify-content: space-between;
            }

            &:after {
              @include tablet-down {
                content: '';
                @include icon-svg('arrow_down_white', 16px, 16px);
                margin: 4px 8px 0 0;
              }
            }

            &.open:after {
              @include tablet-down {
                @include icon-svg('arrow_right_white', 16px, 16px);
              }
            }
          }

          .children {
            display: flex;
            flex-direction: column;
            padding-top: 4px;
            
            @include tablet-down {
              padding-top: 18px;
              row-gap: 13px;
            }

            &.hidden {
              @include tablet-down {
                display: none;
              }
            }

            span {
              @include deskB2;
            }
          }
        }
      }
    }

    .newsletter-container {
      button span {
        @include deskB1;
        text-transform: none;
      }
    }

    .nav-social-links {
      margin-right: 67px;
      display: flex;
      column-gap: 24px;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      top: 246px;

      @include tablet-down {
        position: initial;
        margin: 0;
        justify-content: flex-start;
      }
    }

    .legals {
      position: absolute;
      bottom: 46px;
      display: flex;
      column-gap: 24px;

      @include tablet-down {
        position: initial;
        flex-direction: column;
        margin-top: 50px;
      }
    }

    .block-switch {
      position: absolute;
      bottom: 0;

      select.language-select {
        color: $white;
        border: none;
        background: none;
        max-width: 80px;
        padding: 0 0 0 5px;
        @include deskB1;
      }

      .select2-container {
        .select2-selection__rendered {
          padding-left: 0;
          color: $white;
        }

        .select2-selection__arrow {
          height: 16px;
          left: 50px;
          position: absolute;
          top: 3px;
          width: 16px;

          b {
            @include icon-svg('arrow_down_white', 16px, 16px);
          }
        }
      }
    }

    .copyright {
      position: absolute;
      bottom: 53px;
      right: 2px;

      @include tablet-down {
        bottom: 26px;
        right: 0px;
      }

      span {
        @include deskUI2;
      }
    }

    .nav-toggle {
      display: none;
    }
  }
}

.select2-container--language-theme-footer.select2-container--open .select2-dropdown {
  border: 1px solid $gray4;
  border-radius: 0;

  .select2-results__options {
    padding: 8px 0;
    background-color: $darkgray;

    li.select2-results__option {
      width: 120px;
      display: flex;
      justify-content: space-between;
      border: none;
      color: $white !important;

      &[aria-disabled=true] {
        display: none;
      }

      p {
        margin: 0;
      }

      &.select2-results__option--highlighted {
        background-color: $gray8;
      }
    }
  }
}