.sales-order-printinvoice {
    &.page-print {
        .logo {
            display: block;
            max-width: 190px;
            margin: 40px auto 50px;
            .tablet-hidden {
                display: none !important;
            }
            .tablet-only {
                display: block !important;
            }
        }
        .main-wrapper {
            margin-top: 0;
        }
        .page-main {
            max-width: 760px;
        }
        .page-main > .page-title-wrapper .page-title {
            display: block;
            font-size: 2rem;
            letter-spacing: 0.6rem;
            text-align: center;
        }
        .table-wrapper {
            margin: 40px auto 0;
            .item-options {
                display: none;
            }
        }
        .price-including-tax, .price-excluding-tax {
            font-size: 1.4rem;
        }
        .page-title-wrapper {
            .order-status {
                display: none;
            }
            .order-date {
                margin-top: 0;
                display: block;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
                font-size: 1.6rem;
                span,
                label,
                date {
                    letter-spacing: 0.1rem;
                    font-size: 1.6rem;
                    font-weight: 400;
                }
            }
        }
        .order-title {
            display: block;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.1rem;
            font-size: 1.6rem;
            font-weight: 400;
        }
        .order-details-items {
            border: none;
            .order-title > strong {
                margin: 0;
            }
        }
        .block-order-details-view {
            margin-top: 50px;
            .block-content {
                display: flex;
                flex-wrap: wrap;
                border: 1px solid black;
                border-radius: 4px;
                padding: 24px;
                .box {
                    width: 50%;
                }
                .box-title {
                    font-size: 1.6rem;
                    letter-spacing: 0.16rem;
                    margin-bottom: 14px;
                    font-weight: 600;
                }
                .box-content {
                    color: $gray1;
                    font-size: 1.3rem;
                    letter-spacing: 0.13rem;
                    display: flex;
                    justify-content: space-between;
                    line-height: 1.5;
                    font-weight: 500;
                }
            }
        }
    }
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
      }
    .sales-order-printinvoice {
        &.page-print {
            .logo {
                max-width: 130px;
            }
            .page-main > .page-title-wrapper .page-title {
                font-size: 1.8rem;
                letter-spacing: 0.6rem;
            }
            .block-order-details-view {
                .block-content {
                    .box {
                        float: left;
                    }
                }
            }
        }
    }
    #website-preloader,
    .nav-toggle,
    .mobile-menu {
        display: none !important;
    }
}

@media print and (max-width: 768px) {
    .sales-order-printinvoice.account {
        .column.main .block:not(.widget) .block-content {
            flex-direction: row;
        }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td, 
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
            padding: 10px 10px;
        }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
            min-width: 100%;
        }
    }
}