.jade-link {
    color: #000;
    text-decoration: none; }
.jade-link:hover {
    color: #e2400d; }

.jade-link-underline {
    color: #000;
    text-decoration: underline; }
.jade-link-underline:hover {
    color: #e2400d; }

.jade-link-underline-anim {
    color: #000;
    text-decoration: none;
    position: relative; }
.jade-link-underline-anim:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    bottom: -3px;
    height: 1px;
    background: #000;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease; }
.jade-link-underline-anim:hover {
    color: #e2400d; }
.jade-link-underline-anim:hover:after {
    background: #e2400d;
    width: 100%; }

.jade-link-underline-bottom {
    position: relative;
    display: inline-block;
    color: #000; }
.jade-link-underline-bottom:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    background: #e2400d;
    opacity: 0;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    transform: translateY(5px); }
.jade-link-underline-bottom:hover {
    color: #e2400d; }
.jade-link-underline-bottom:hover:after {
    opacity: 1;
    height: 2px;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0); }

.jade-link-scale-down {
    position: relative;
    display: inline-block;
    color: #000;
    text-decoration: none;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease; }
.jade-link-scale-down:hover {
    color: #e2400d;
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }

.jade-link-lines {
    display: inline-block;
    position: relative; }
.jade-link-lines:before, .jade-link-lines:after {
    content: '';
    height: 1px;
    z-index: -1;
    background: #e2400d;
    position: absolute;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    width: 0; }
.jade-link-lines:before {
    right: 0;
    top: -1px; }
.jade-link-lines:after {
    left: 0;
    bottom: -1px; }
.jade-link-lines:hover {
    color: #e2400d; }
.jade-link-lines:hover:before, .jade-link-lines:hover:after {
    width: 100%; }

.jade-button {
    -webkit-appearance: none;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    margin: 15px 0;
    border: none;
    background: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.4rem;
    cursor: pointer;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }

.jade-button-bg {
    color: #fff;
    background: #127d78; }
.jade-button-bg:hover {
    background: #e2400d; }

.jade-button-bd {
    border: 2px solid #127d78;
    line-height: 46px;
    color: #127d78; }
.jade-button-bd:hover {
    background: #127d78;
    color: #fff; }

.jade-button-bg-bd {
    border: 2px solid #127d78;
    line-height: 46px;
    color: #fff;
    background: #127d78; }
.jade-button-bg-bd:hover {
    background: none;
    color: #127d78; }

.jade-button-bg-dezoom {
    position: relative;
    color: #fff; }
.jade-button-bg-dezoom:before, .jade-button-bg-dezoom:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -moz-transition: all 300ms cubic-bezier(0.25, 0, 0.3, 1);
    -o-transition: all 300ms cubic-bezier(0.25, 0, 0.3, 1);
    -webkit-transition: all 300ms cubic-bezier(0.25, 0, 0.3, 1);
    transition: all 300ms cubic-bezier(0.25, 0, 0.3, 1); }
.jade-button-bg-dezoom:before {
    top: -2px;
    border: 2px solid #e2400d;
    -moz-transform: scale3d(0.7, 0.7, 1);
    -ms-transform: scale3d(0.7, 0.7, 1);
    -webkit-transform: scale3d(0.7, 0.7, 1);
    transform: scale3d(0.7, 0.7, 1); }
.jade-button-bg-dezoom:after {
    background: #127d78; }
.jade-button-bg-dezoom:hover:before {
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
.jade-button-bg-dezoom:hover:after {
    -moz-transform: scale3d(0.8, 0.7, 1);
    -ms-transform: scale3d(0.8, 0.7, 1);
    -webkit-transform: scale3d(0.8, 0.7, 1);
    transform: scale3d(0.8, 0.7, 1);
    background-color: #e2400d; }

.jade-button-bg-x {
    position: relative;
    border: 2px solid #127d78;
    line-height: 46px;
    color: #127d78; }
.jade-button-bg-x:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #127d78;
    z-index: -1;
    opacity: 0;
    -moz-transform: scale3d(0.5, 1, 1);
    -ms-transform: scale3d(0.5, 1, 1);
    -webkit-transform: scale3d(0.5, 1, 1);
    transform: scale3d(0.5, 1, 1);
    -moz-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1);
    -o-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1); }
.jade-button-bg-x:hover {
    color: #fff; }
.jade-button-bg-x:hover:before {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.jade-button-bg-y {
    position: relative;
    border: 2px solid #127d78;
    line-height: 46px;
    color: #127d78; }
.jade-button-bg-y:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #127d78;
    z-index: -1;
    opacity: 0;
    -moz-transform: scale3d(1, 0.5, 1);
    -ms-transform: scale3d(1, 0.5, 1);
    -webkit-transform: scale3d(1, 0.5, 1);
    transform: scale3d(1, 0.5, 1);
    -moz-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1);
    -o-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: all 400ms cubic-bezier(0.2, 1, 0.3, 1); }
.jade-button-bg-y:hover {
    color: #fff; }
.jade-button-bg-y:hover:before {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.jade-button-bg-bd-x {
    position: relative;
    border: 1px solid #127d78;
    line-height: 48px;
    color: #127d78;
    -moz-transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9) 400ms;
    -o-transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9) 400ms;
    -webkit-transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    -webkit-transition-delay: 400ms;
    transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9) 400ms; }
.jade-button-bg-bd-x span {
    position: relative;
    padding: 0 15px;
    z-index: 2;
    background-color: transparent;
    -moz-transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9) 400ms;
    -o-transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9) 400ms;
    -webkit-transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    -webkit-transition-delay: 400ms;
    transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9) 400ms; }
.jade-button-bg-bd-x:after {
    content: '';
    position: absolute;
    height: 1px;
    z-index: -1;
    top: 47%;
    right: -30px;
    left: -30px;
    background: #127d78;
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -moz-transition: all 500ms cubic-bezier(0.43, 0.01, 0.56, 0.99);
    -o-transition: all 500ms cubic-bezier(0.43, 0.01, 0.56, 0.99);
    -webkit-transition: all 500ms cubic-bezier(0.43, 0.01, 0.56, 0.99);
    transition: all 500ms cubic-bezier(0.43, 0.01, 0.56, 0.99); }
.jade-button-bg-bd-x:hover {
    background: #97ddda;
    border-color: transparent;
    -moz-transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    -o-transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    -webkit-transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    transition: all 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9); }
.jade-button-bg-bd-x:hover span {
    background: #97ddda;
    -moz-transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    -o-transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    -webkit-transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9);
    transition: background-color 300ms cubic-bezier(0.09, 0.64, 0.35, 0.9); }
.jade-button-bg-bd-x:hover:after {
    z-index: 1;
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }

.jade-button-bd-anim {
    position: relative;
    border: 1px solid #97ddda;
    line-height: 48px;
    color: #127d78; }
.jade-button-bd-anim .line {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
.jade-button-bd-anim .line:before, .jade-button-bd-anim .line:after {
    content: '';
    display: block;
    position: absolute;
    background: #127d78;
    -moz-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1); }
.jade-button-bd-anim .line:first-child:before, .jade-button-bd-anim .line:first-child:after {
    width: 0;
    height: 1px; }
.jade-button-bd-anim .line:first-child:before {
    top: -1px;
    left: 0; }
.jade-button-bd-anim .line:first-child:after {
    bottom: -1px;
    right: 0; }
.jade-button-bd-anim .line:last-child:before, .jade-button-bd-anim .line:last-child:after {
    width: 1px;
    height: 0; }
.jade-button-bd-anim .line:last-child:before {
    bottom: 0;
    left: -1px; }
.jade-button-bd-anim .line:last-child:after {
    top: 0;
    right: -1px; }
.jade-button-bd-anim:hover .line:first-child:before, .jade-button-bd-anim:hover .line:first-child:after {
    width: 100%; }
.jade-button-bd-anim:hover .line:last-child:before, .jade-button-bd-anim:hover .line:last-child:after {
    height: 100%; }

.jade-push {
    position: relative;
    z-index: 1;
    line-height: 0; }
.jade-push img {
    image-rendering: optimizeSpeed;
    /*                     */
    image-rendering: -moz-crisp-edges;
    /* Firefox             */
    image-rendering: -o-crisp-edges;
    /* Opera               */
    image-rendering: -webkit-optimize-contrast;
    /* Chrome (and Safari) */
    image-rendering: optimize-contrast;
    /* CSS3 Proposed       */
    -ms-interpolation-mode: nearest-neighbor;
    /* IE8+                */
    width: auto;
    height: auto;
    max-width: 100%;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: blur(0);
    -webkit-filter: blur(0); }

.jade-push-zoom:hover {
    z-index: 2; }
.jade-push-zoom:hover img {
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
.jade-push-zoom:hover img.bright {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }

.jade-push-intern-zoom {
    overflow: hidden; }
.jade-push-intern-zoom:hover img {
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
.jade-push-intern-zoom:hover img.bright {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }

.jade-push-dezoom {
    overflow: hidden; }
.jade-push-dezoom img {
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
.jade-push-dezoom:hover img {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); }
.jade-push-dezoom:hover img.bright {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }

.jade-push-blur {
    overflow: hidden; }
.jade-push-blur img {
    -webkit-filter: blur(3px);
    filter: blur(3px); }
.jade-push-blur:hover img {
    -webkit-filter: blur(0);
    filter: blur(0); }
.jade-push-blur.inverted img {
    -webkit-filter: blur(0);
    filter: blur(0); }
.jade-push-blur.inverted:hover img {
    -webkit-filter: blur(3px);
    filter: blur(3px); }

.jade-push-gray {
    overflow: hidden; }
.jade-push-gray img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
.jade-push-gray:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0); }
.jade-push-gray.inverted img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0); }
.jade-push-gray.inverted:hover img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }

.jade-push-gray-blur {
    overflow: hidden; }
.jade-push-gray-blur img {
    -webkit-filter: grayscale(100%) blur(3px);
    filter: grayscale(100%) blur(3px); }
.jade-push-gray-blur:hover img {
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0); }
.jade-push-gray-blur.inverted img {
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0); }
.jade-push-gray-blur.inverted:hover img {
    -webkit-filter: grayscale(100%) blur(3px);
    filter: grayscale(100%) blur(3px); }

.jade-push-text {
    position: relative;
    z-index: 1; }
.jade-push-text .overlay {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    -webkit-transition: all 500ms;
    transition: all 500ms; }
.jade-push-text .push-text {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0 15px;
    opacity: 0;
    line-height: 1.1em;
    color: #fff;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    -webkit-transition: all 500ms;
    transition: all 500ms; }

.jade-push-text-full {
    overflow: hidden; }
.jade-push-text-full .overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
.jade-push-text-full .push-text {
    top: -20px; }
.jade-push-text-full:hover .overlay {
    opacity: 1; }
.jade-push-text-full:hover .push-text {
    opacity: 1;
    top: 40%; }

.jade-push-text-bottom {
    overflow: hidden;
    text-align: left; }
.jade-push-text-bottom .overlay {
    right: 0;
    bottom: -60px;
    left: 0;
    height: 60px; }
.jade-push-text-bottom .push-text {
    position: relative;
    top: 30px;
    padding: 15px; }
.jade-push-text-bottom:hover .overlay {
    opacity: 1;
    bottom: 0; }
.jade-push-text-bottom:hover .push-text {
    opacity: 1;
    top: 0; }

.jade-push-text-bottom-slide {
    overflow: hidden;
    text-align: left;
    display: inline-block; }
.jade-push-text-bottom-slide .overlay {
    right: 0;
    bottom: -70px;
    left: 0;
    height: 70px;
    opacity: 1;
    background: #127d78; }
.jade-push-text-bottom-slide .push-text {
    position: relative;
    top: 0;
    padding: 15px;
    opacity: 1; }
.jade-push-text-bottom-slide:hover img {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
.jade-push-text-bottom-slide:hover .overlay {
    bottom: 0; }

.jade-push-text-slide-img {
    text-align: left;
    display: inline-block; }
.jade-push-text-slide-img .overlay {
    right: 0;
    bottom: 0;
    left: 0;
    height: 70px;
    opacity: 1;
    background: #127d78;
    z-index: -1; }
.jade-push-text-slide-img .push-text {
    position: relative;
    top: 0;
    padding: 15px;
    opacity: 1; }
.jade-push-text-slide-img:hover img {
    -moz-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px); }

.jade-push-text-decal {
    text-align: left;
    display: inline-block; }
.jade-push-text-decal .push-bg {
    position: relative;
    display: block;
    background: #127d78;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    left: 0; }
.jade-push-text-decal .push-text {
    position: relative;
    top: 0;
    padding: 15px;
    opacity: 1;
    color: #000;
    font-weight: 500;
    font-size: 2rem; }
.jade-push-text-decal img {
    position: relative;
    top: 0;
    left: 0; }
.jade-push-text-decal:hover .push-bg {
    left: -10px; }
.jade-push-text-decal:hover img {
    top: 20px;
    left: 20px; }
.jade-push-text-decal:hover .push-text {
    top: -15px; }
.jade-push-text-decal.decal-right .push-bg {
    left: inherit;
    right: 0; }
.jade-push-text-decal.decal-right img {
    left: inherit;
    right: 0; }
.jade-push-text-decal.decal-right:hover .push-bg {
    left: inherit;
    right: -10px; }
.jade-push-text-decal.decal-right:hover img {
    left: inherit;
    right: 20px; }

.jade-input-box {
    position: relative; }
.jade-input-box .input-text {
    border: 1px solid #c1c1c1;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #c1c1c1;
    font-size: 1.5rem;
    height: 40px;
    line-height: 38px;
    padding: 0 15px;
    width: 100%;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out; }
.jade-input-box label {
    text-align: left; }

.jade-input-line-focus .input-text {
    border-radius: 0;
    border: none;
    padding: 0;
    border-bottom: 1px solid #c1c1c1; }
.jade-input-line-focus .input-text:focus + span {
    left: 0;
    right: 0;
    opacity: 1; }
.jade-input-line-focus span {
    content: '';
    display: block;
    height: 2px;
    background: #127d78;
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease; }
.jade-input-line-focus.line-focus-left span {
    left: 0;
    right: 100%; }
.jade-input-line-focus.line-focus-right span {
    left: 100%;
    right: 0; }

.jade-input-placeholder-interact {
    position: relative; }
.jade-input-placeholder-interact label {
    position: absolute;
    left: 0;
    top: 12px;
    color: #c1c1c1;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
.jade-input-placeholder-interact .input-text {
    border-radius: 0;
    border: none;
    padding: 0;
    border-bottom: 1px solid #c1c1c1; }
.jade-input-placeholder-interact .input-text:focus, .jade-input-placeholder-interact .input-text:valid {
    border-bottom: 1px solid #127d78; }
.jade-input-placeholder-interact .input-text:focus + label, .jade-input-placeholder-interact .input-text:valid + label {
    color: #127d78;
    font-size: 1.1rem;
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }

.jade-input-placeholder-bg {
    position: relative;
    overflow: hidden; }
.jade-input-placeholder-bg .input-text {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
    background: none;
    padding: 0 7px;
    color: #fff; }
.jade-input-placeholder-bg .input-text:focus, .jade-input-placeholder-bg .input-text:valid {
    border: transparent; }
.jade-input-placeholder-bg .input-text:focus + label, .jade-input-placeholder-bg .input-text:valid + label {
    top: 0; }
.jade-input-placeholder-bg label {
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    color: #c1c1c1;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease; }
.jade-input-placeholder-bg label:before {
    content: '';
    display: block;
    background: #A8A8A8;
    width: 100%;
    height: 40px; }
.jade-input-placeholder-bg label span {
    display: block;
    height: 40px;
    padding: 12px 0; }

.jade-input-placeholder-fadeout {
    position: relative;
    overflow: hidden;
    padding-top: 20px; }
.jade-input-placeholder-fadeout label {
    position: absolute;
    left: 10px;
    top: 32px;
    color: #c1c1c1; }
.jade-input-placeholder-fadeout label span {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
.jade-input-placeholder-fadeout label:before {
    content: attr(data-text-label);
    display: block;
    position: absolute;
    top: -50px;
    color: #127d78;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }
.jade-input-placeholder-fadeout .input-text {
    z-index: 2;
    background: none;
    padding: 0 10px; }
.jade-input-placeholder-fadeout .input-text:focus, .jade-input-placeholder-fadeout .input-text:valid {
    border-color: #127d78; }
.jade-input-placeholder-fadeout .input-text:focus + label span, .jade-input-placeholder-fadeout .input-text:valid + label span {
    opacity: 0; }
.jade-input-placeholder-fadeout .input-text:focus + label:before, .jade-input-placeholder-fadeout .input-text:valid + label:before {
    top: -30px; }

.control {
    margin: 10px 0;
    position: relative;
    overflow: hidden; }
.control label {
    cursor: pointer;
    position: relative; }
.control input[type="checkbox"] {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    display: inline-block;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0; }
.control input[type="checkbox"] + label {
    float: left;
    display: block;
    min-height: 25px;
    padding: 4px 0 4px 30px;
    width: auto;
    max-width: 100%;
    text-transform: none;
    font-size: 1.4rem; }
.control input[type="checkbox"] + label:after {
    cursor: pointer;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #6d6d6d;
    margin: 0;
    position: absolute;
    left: 0;
    top: 1px; }
.control input[type="checkbox"] + label:before {
    content: "";
    -moz-background-size: 15px auto;
    -o-background-size: 15px auto;
    -webkit-background-size: 15px auto;
    background-size: 15px auto;
    position: absolute;
    height: 15px;
    width: 15px;
    top: 3px;
    left: 3px;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1); }
.control input[type="checkbox"]:checked + label:before, .control input[type="checkbox"]:disabled + label:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
.control input[type="radio"] {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    display: inline-block;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0; }
.control input[type="radio"] + label {
    float: left;
    display: block;
    font-size: 1.4rem;
    padding: 4px 0 4px 30px;
    max-width: 100%;
    min-height: 20px;
    text-transform: none; }
.control input[type="radio"] + label:before {
    cursor: pointer;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #c1c1c1;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    top: 1px;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
.control input[type="radio"] + label:after {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #127d78;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    left: 4px;
    top: 5px;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out; }
.control input[type="radio"] + label:hover:before {
    border-color: #127d78; }
.control input[type="radio"] + label:hover:after {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
.control input[type="radio"]:checked + label:after {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); }

.jade-hamburger {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 80px;
    background: #127d78; }
.jade-hamburger span {
    display: block;
    position: absolute;
    top: 38.5px;
    left: 20px;
    right: 20px;
    height: 3px;
    background: #fff;
    text-indent: -9999px; }
.jade-hamburger span:before, .jade-hamburger span:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    height: 3px;
    background: #fff; }
.jade-hamburger span:before {
    top: -13px; }
.jade-hamburger span:after {
    bottom: -13px; }

.jade-hamburger-cross span {
    -moz-transition: opacity 0s 300ms;
    -o-transition: opacity 0s 300ms;
    -webkit-transition: opacity 0s;
    -webkit-transition-delay: 300ms;
    transition: opacity 0s 300ms; }
.jade-hamburger-cross span:before, .jade-hamburger-cross span:after {
    -webkit-transition-duration: 300ms, 300ms;
    transition-duration: 300ms, 300ms;
    -webkit-transition-delay: 300ms, 0s;
    transition-delay: 300ms, 0s; }
.jade-hamburger-cross span:before {
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform; }
.jade-hamburger-cross span:after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform; }
.jade-hamburger-cross.is-active span {
    background: transparent; }
.jade-hamburger-cross.is-active span:before {
    top: 0;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
.jade-hamburger-cross.is-active span:after {
    bottom: 0;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
.jade-hamburger-cross.is-active span:before, .jade-hamburger-cross.is-active span:after {
    -webkit-transition-delay: 0s, 300ms;
    transition-delay: 0s, 300ms; }

.jade-hamburger-arrow span {
    -moz-transition: background 0s 300ms;
    -o-transition: background 0s 300ms;
    -webkit-transition: background 0s;
    -webkit-transition-delay: 300ms;
    transition: background 0s 300ms; }
.jade-hamburger-arrow span:before, .jade-hamburger-arrow span:after {
    -webkit-transition-duration: 300ms, 300ms;
    transition-duration: 300ms, 300ms;
    -webkit-transition-delay: 300ms, 0s;
    transition-delay: 300ms, 0s; }
.jade-hamburger-arrow span:before {
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform; }
.jade-hamburger-arrow span:after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform; }
.jade-hamburger-arrow.is-active span {
    background: none; }
.jade-hamburger-arrow.is-active span:before {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
.jade-hamburger-arrow.is-active span:after {
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }
.jade-hamburger-arrow.is-active span:before, .jade-hamburger-arrow.is-active span:after {
    -webkit-transition-delay: 0s, 300ms;
    transition-delay: 0s, 300ms; }