.page-layout-checkout,
.checkout-cart-index {

    .block.crosssell {
        display: none;
    }
    .select2-container--open {
        display: block;
        z-index: 100000 !important;
    }

    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"],
    .select2-container--default .select2-selection--single {
        background: transparent;
    }

    .select2-container {
        max-width: 100% !important;
    }

    .page-main {
	    @include phone {
            overflow-x: hidden;
            padding-left: 14px;
            padding-right: 14px;
        }
    }

    .logo-image {
	    @include phone {
            width: 114px !important;
            height: 37px !important;
        }
    }
    .page-header {
        border: none;
    }
    .mobile-locator,
    .nav-toggle {
        display: none;
    }
    header {
        position: absolute;
        background: transparent;
        @include phone {
            height: 72px;
            background: white;
        }
        .header.content {
            border: none;
	        @include phone {
                padding-top: 15px;
            }
        }
    }
    .main-wrapper {
        margin-top: 0;
        background: #f5f5f5;
    }
    .columns .column.main {
        padding-bottom: 0;
    }
    .panier-message-top-right,
    .secured-payment-message-top-left {
        position: absolute;
        z-index: 100;
        top: 35px;
        display: flex;
        align-items: center;
        @include phone {
            display: none;
        }
    }
    .panier-message-top-right{
        right: 32px;
        font-size: 1.3rem;
        letter-spacing: 0.13rem;
        .number-class {
            @extend .step-number;
            margin-left: 10px;
        }
    }
    .secured-payment-message-top-left {
        left: 40px;
        font-size: 1.4rem;
        letter-spacing: 0.14rem;
        .lock-icon {
            margin-right: 12px;
        }
    }
    .opc-sidebar {
        margin-top: 0;
        margin-right: -20px;
        margin-bottom: 0;
        padding: 220px 20px 20px;
        float: none;
        background: white;
        box-sizing: content-box;
        width: 750px;
        max-width: 40%;
        @include phone {
            &:before {
                content: "";
                height: 200px;
                background: white;
                margin-top: -200px;
                position: absolute;
                left: -14px;
                right: -14px;
            }
            padding: 20px;
            max-width: 100%;
            width: 100%;
            margin: 0 -20px;
        }
        .cart-summary {
            float: none;
            width: 100%;
            background: transparent;
        }
        .modal-inner-wrap {
            max-width: 422px;
            margin: 0 auto;
            @include phone {
                max-width: 100%;
            }
        }
        .modal-header {
            display: none;
        }
    }
    .opc-container-cart,
    .opc-container {
        padding-top: 220px;
        padding-bottom: 20px;
        padding-right: 20px;
        width: 100%;
        max-width: 940px;
        @include phone {
            position: relative;
            z-index: 1;
            padding: 80px 0 20px;
        }
    }
    .opc-container-cart {
        display: flex;
        padding: 220px 0% 0;
        justify-content: space-between;
        @include phone {
            flex-direction: column;
            padding: 80px 0 0;
            margin: 0;
        }
    }
    .opc-wrapper {
        float: none;
        width: auto;
        display: flex;
        padding: 0;
        justify-content: space-between;
	    @include phone {
            flex-direction: column;
            padding: 0;
            margin: 0;
        }
        #checkoutSteps {
            max-width: 660px;
            width: 100%;
            @include phone {
                max-width: none;
            }
        }
    }
    .opc-checkout-cart-wrapper {
        max-width: 660px;
        width: 100%;
        display: flex;
        flex-direction: column;
        @include phone {
            max-width: none;
        }
        .form-cart {
            width: 100%;
        }
    }
    .cart-container,
    .checkout-container {
        min-height: 100vh;
        margin-bottom: 0;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        @include phone {
            flex-direction: column;
        }
    }
    .opc-block-summary {
        background: transparent;
    }
    .authentication-wrapper {
        display: none;
    }

    // Reset native styles images
    .product-image-photo {
        position: static;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        width:100%;
    }
}

//STEP ONE

.checkout-cart-index .opc-sidebar:before {
    content: none;
}

//CART
#shopping-cart-table {
    .cart-table-title {
        font-size: 2rem;
        letter-spacing: 0.2rem;
        font-weight: 600;
        color: black;
        span {
            margin-left: 10px;
            vertical-align: middle;
        }
    }
    table tr:nth-child(odd) td {
        background: transparent;
    }
    .actions-toolbar {
        .action-towishlist {
            display: none;
        }
        .action-delete {
            border: none;
            background: transparent;
            padding: 0;
            font-size: 1rem;
            letter-spacing: 0.1rem;
            color: $gray1;
            font-weight: 600;
            font-style: italic;
            margin-bottom: 0;
            &:hover,
            &:active,
            &:focus {
                border: none;
                background: transparent;
                padding: 0;
                color: $gray1;
            }
        }
    }
}

.opc-checkout-cart-wrapper{
    .cart.table-wrapper thead .col:not(.item) {
        @include phone {
            display: table-cell;
        }
    }
    .cart.table-wrapper .items thead + .item,
    .cart.table-wrapper .items > .item {
        border: none;
    }
    .cart.table-wrapper .product-item-photo {
        padding-right: 0;
    }
    .cart.table-wrapper {
        @include phone {
            padding-left: 15px;
        }
        table tr:nth-child(odd) td {
            background: transparent;
        }
        th {
            &.col {
                padding: 0 0 45px;
            }
        }
        .col {
            padding-top: 0;
            &.photo {
                // padding: 0 0 40px;
                width: 120px;
                @include phone {
                    padding: 0 0 15px;
                    width: 60px;
                }
                .product-item-photo {
                    top: 0;
                    position: relative;
                }
                .image {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .item .col.item {
            padding: 0 0 0 23px;
            display: flex;
            flex-direction: column;
            min-height: 120px;
            .actions-toolbar {
                margin-top: auto;
            }
        }
        .product-item-name {
            @include phone {
                font-size: 1.2rem;
            }
            a {
                letter-spacing: 0.12rem;
                font-weight: 600;
            }
        }
        .product-material {
            color: $gray1;
            font-weight: 300;
            font-size: 1.4rem;
            margin-bottom: 12px;
            @include phone {
                font-size: 1.2rem;
            }
        }
        .item-options {
            margin-bottom: 0;
            dt,
            dd {
                font-weight: 600;
                font-size: 1.2rem;
                letter-spacing: 0.12rem;
                @include phone {
                    font-size: 1rem;
                }
            }
            dt {
              &.no-after {
                &:after {
                  content: "";
                  display: none;
                }
              }
            }
        }
        .col.qty {
            @include phone {
                padding-left: 46px;
                &:before {
                    content: none;
                }
            }
            .input-text {
                margin-top: 0;
                width: 40px;
                background: transparent;
            }
        }
        .subtotal {
            @include phone {
                float: right;
                margin-top: 18px;
                margin-bottom: 30px;
                &:before {
                    content: none;
                }
            }
        }
        .price-including-tax .price,
        .price-excluding-tax .price {
            font-weight: 600;
            color: $gray1;
            font-size: 1.2rem;
            letter-spacing: 0.12rem;
        }
    }
}

//GIFTS
.gift-item-block {
    @include phone {
        border: none;
    }
    .content {
        border: none;
        padding-bottom: 0;
    }
    .control {
        @include phone {
            margin: 0;
        }
    }
    .check-gift {
        display: flex;
        align-items: center;
        .price-gift {
            margin-left: auto;
        }
    }
    .name-gift {
        @include phone {
            font-size: 1.2rem;
        }
    }
    label {
        font-size: 1.4rem;
        letter-spacing: 0.14rem;
    }
    .price-gift {
        color: $gray1;
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 0.12rem;
        margin-left: auto;
        @include phone {
            align-self: flex-end;
        }
    }
    .control {
        float: none;
    }
}

.gift-message-block {
    .gift-item-block {
        padding: 20px;
    }
    .control {
        @include phone {
            margin: 0;
        }
    }
    .control input[type="checkbox"] + label {
        @include phone {
            font-size: 1.2rem;
        }
    }
}

.gift-wrap-block,
#gift-options-cart {
    @include phone {
        margin: 0 -20px;
    }
}

.gift-wrap-block {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background: white;
    padding: 20px;
    @include phone {
        margin-bottom: 0;
    }
    .gift-item-block {
        display: flex;
    }
    .control input[type="checkbox"] + label {
        display: flex;
    }
    .image-gift {
        margin-top: -18px;
        margin-right: 25px;
        margin-left: 15px;
    }
}

.cart-container .cart-gift-item {
    width: 100%;
}

.gift-message {
    margin-top: 20px;
    .gift-options-title {
        font-size: 1.4rem;
        letter-spacing: 0.14rem;

    }
}

.gift-message-block {
    .gift-options .actions-toolbar {
        .action-cancel {
            margin-top: 0;
            border-radius: 0;
            background: black;
            border: 1px solid black;
            color: white;
            text-transform: uppercase;
            font-weight: 700;
            padding: 18px 25px;
            font-size: 1.1rem;
            letter-spacing: 0.25rem;
            text-decoration: none;
            &:focus,
            &:active,
            &:hover {
                background: $gray3;
                border-color: $gray3;
                text-decoration: none;
            }
        }
    }
}

//SUMMARY STEP ONE
.checkout-cart-index {
    .cart-summary {
        max-width: 422px;
        margin: 0 auto;
    }
    .cart-totals {
        border: none;
    }

    .alma-cart-eligibility {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: normal;
        padding: 14px 17px;

        .alma-cart-eligibility--message {
            margin-left: 15px;
                @include deskBtn1;
        }
    }

    .cart-container .checkout-methods-items {
        padding-top: 40px;
        border-top: 1px solid #dedede;
        text-align: left;

        .paypal.after:before {
            text-align: center;
        }

        .action.primary {
            font-size: 1.1rem;
            letter-spacing: 0.25rem;
            text-align: center;
        }
        .multicheckout {
            display: none;
        }
    }
}

//MODAL ADDRESS
.page-layout-checkout,
.checkout-cart-index {
    .modal-popup {
        .modal-content,
        .modal-footer,
        .modal-header {
            position: relative;
            padding: 20px;
        }
        header {
            height: auto;
        }
        .modal-title {
            border: none;
            padding-bottom: 0;
            font-size: 2rem;
            letter-spacing: 0.2rem;
            margin-bottom: 0px;
            font-weight: 500;
            text-transform: none;
        }
    }
}

.steps-cart {
    display: flex;
    flex-direction: column;
    @include phone {
        display: none;
    }
    .step {
        font-size: 1.3rem;
        letter-spacing: 0.13rem;
        color: #d8d8d8;
        &:after {
            content: "";
            width: 2px;
            height: 41px;
            background: #d8d8d8;
            display: inherit;
            margin: 3px 0 3px 3px;
        }
        &.step-selected {
            color: black;
            &:after {
                background: linear-gradient(to bottom, #000 0%,#000 50%,#d8d8d8 51%,#d8d8d8 100%);
                background: -webkit-linear-gradient(top, #000 0%,#000 50%,#d8d8d8 51%,#d8d8d8 100%);
            }
        }
        &.step-past {
            color: black;
            &:after {
                background: black;
                display: block;
            }
        }
        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}

//STEP TWO AND THREE
//OPC

.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
    border: none;
    padding-bottom: 0;
    font-size: 2rem;
    letter-spacing: 0.2rem;
    margin-bottom: 30px;
    font-weight: 500;
    text-transform: none;
    @include phone {
        font-size: 1.6rem;
      letter-spacing: 0.16rem;
      margin-bottom: 20px;
    }
}

.page-layout-checkout,
.checkout-cart-index {
    .fieldset {
        display: flex;
        flex-wrap: wrap;
    }
    .field {
        width: 100%;
        &.required,
        &._required {
            & > label {
                display: inline-block;
                text-align: left;
            }
        }
        &:not(.choice) > .label {
            display: inline-block;
            text-align: left;
        }
        &[name="shippingAddress.postcode"],
        &[name="shippingAddress.country_id"],
        &[name="shippingAddress.firstname"],
        &[name="shippingAddress.lastname"]{
            width: 50%;
	        @include phone {
                width: 100%;
            }
        }
        &[name="shippingAddress.postcode"],
        &[name="shippingAddress.firstname"]{
            padding-right: 20px;
	        @include phone {
                padding-right: 0;
            }
        }
        &[name="shippingAddress.country_id"],
        &[name="shippingAddress.lastname"]{
            padding-left: 20px;
	        @include phone {
                padding-left: 0;
            }
        }
    }
    .control {
        margin: 8px 0 0;
    }
    .street {
        .control {
            label,
            .label {
                display: none !important;
            }
        }
    }

    .shipping-address-items {
        display: flex;
        align-items: stretch;
	    @include phone {
            flex-direction:column;
        }
    }

    .shipping-address-item {
        width: 50%;
        color: $gray1;
        font-size: 1.3rem;
        letter-spacing: 0.13rem;
        line-height: 1.5;
	    @include phone {
            width: 100%;
            margin-bottom: 15px;
        }
        &.selected-item {
            border-color: black;
            &:after {
                content: "";
                @extend .tick-white-icon;
                background-color: black;
                padding: 12px;
                background-position: center;
                background-size: 14px auto;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}

#checkout-step-shipping {
    .action {
        @extend .btn-pink;
		    float: left;
        padding: 4px 12px;
        text-transform: none;
        letter-spacing: 0.13rem;
        font-size: 1.2rem;
    }
}


//SHIPPING METHODS
.table-checkout-shipping-method {
    width: 100%;
    & tbody {
        td {
            border: none;
            @include phone {
                padding-right: 0;
            }
        }
        tr {
            &.row-method {
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
                @include phone {
                    display: flex;
                    flex-wrap: wrap;
                }
                &:nth-child(odd) td,
                td {
                    background: white;
                }
            }
            &.row-space {
                td {
                    padding: 0;
                }
                .col-space {
                    height: 20px;
                }
            }
        }
    }
    .method_title {
        color: black;
        text-transform: uppercase;
        font-size: 1.3rem;
        letter-spacing: 0.13rem;
        font-weight: 600;
        @include phone {
            font-size: 1.1rem;
        }
    }
    .col-shipping-radio {
        @include phone {
            width: 50px !important;
        }
    }
    .col-shipping-method {
        @include phone {
            width: calc( 100% - 50px );
        }
    }
    .col-price,
    .carrier_title {
        color: $gray1;
        font-size: 1.4rem;
        letter-spacing: 0.13rem;
        font-weight: 500;
        @include phone {
            font-size: 1.2rem;
        }
    }
    .col-price {
        font-size: 1.2rem;
        padding-right: 24px;
        text-align: right;
        min-width: 100px;
        @include phone {
            padding-top: 0;
            padding-right: 20px;
            width: 100%;
        }
        .price {
            @include phone {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }
    .control {
        margin: 0;
        input[type="radio"] + label {
            margin-left: 18px;
        }
    }
}

#opc-shipping_method {
    max-width: 600px;
    @include phone {
        background: white;
        padding: 20px;
        margin: 0 -20px;
    }
}

#shipping-method-buttons-container {
    margin-top: 50px;
    @include phone {
        margin-top: 0;
    }
    &.actions-toolbar .action.primary {
        font-size: 1.1rem;
        width: 100%;
        max-width: 356px;
    }
}

.checkout-shipping-method .actions-toolbar > .primary {
    float: none;
    width: 100%;
}

//SIDEBAR

.step-number {
    background: black;
    color: white;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    vertical-align: text-top;
    font-size: 0.99rem;
    width: 18px;
    height: 18px;
    border-radius: 100%;
}

.opc-block-summary {
    padding: 0;
    display: flex;
    flex-direction: column;
    .title {
        display: inline-block;
        margin-right: 8px;
        text-transform: none;
    }
    .summary-title {
        order: 1;
    }
    .items-in-cart {
        order: 2;
        .title {
            display: none;
        }
        .product {
            display: flex;
            &.options {
                flex-direction: column;
                .optiondetail {
                    display: flex;
                }
            }
        }
        padding-bottom: 10px;
        margin-bottom: 0;
    }
    .minicart-items-wrapper {
        @include phone {
            margin: 0;
            padding-right: 0;
            overflow: hidden;
            max-height: none;
        }
    }
    .table-totals {
        order: 3;
        margin-top: 20px;
        position: relative;
        &:before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            top: -20px;
            background: #dedede;
        }
    }
    .product-item {
        .price {
            color: $gray1;
            font-size: 1.2rem;
            letter-spacing: 0.12rem;
        }
        .product-item-inner {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            .subtotal {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }
    }
    .product-subtitle {
        font-weight: 400;
        color: $gray1;
        font-size: 1.2rem;
        letter-spacing: 0;
        display: block;
        margin-bottom: 10px;
    }
    .product-image-container {
        width: auto !important;
        height: auto !important;
    }
    .product-image-wrapper {
        width: 118px;
        height: 118px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
        img{
            max-width: 62px;
            height: auto;
        }
    }
    .minicart-items {
        .product-item {
            padding: 10px 0;
            &:not(:first-child) {
                border: none;
            }
        }
        .product-item-name {
            font-size: 1.2rem;
            letter-spacing: 0.1rem;
            font-weight: 600;
            margin-bottom: 5px;
        }
        .product-item-details {
            display: flex;
            padding-left: 20px;
            width: 100%;
            .details-qty {
                margin-top: 0;
            }
        }
        .value,
        .values,
        .label {
            font-weight: 600;
            font-size: 1rem;
            letter-spacing: 0.1rem;
        }
        .label {
            padding: 0 10px 0 0;
        }
    }
}

.opc-estimated-wrapper {
    display: none;
}

//PAYMENT STEP:

.secured-payment-message-top-left {
    @include phone {
        display: none;
    }
}

.panier-message-top-right {
    @include phone {
        display: none;
    }
}

.checkout-payment-method {
    .payment-method-title {
        position: relative;
        @include phone {
            padding: 15px 20px;
        }
        @include tablet {
            display: flex;
            align-items: center;
        }
        input[type="radio"] {
            @include phone {
                position: absolute;
                left: 22px;
                top: 20px;
            }
        }
        label {
            display: flex;
            align-items: center;
            font-size: 1.3rem;
            font-weight: 600;
            letter-spacing: 0.13rem;
            text-transform: uppercase;
            @include phone {
                flex-direction: column;
                align-items: flex-start;
            }

            > *:first-child {
                margin-right: 20px;

                @include phone {
                    margin-left: 30px;
                    margin-bottom: 10px;
                }
            }

            > * + span,
            > * + .payment-label {
                @include phone {
                    margin-top: 10px;
                }
            }

            .payment-label {
                display: flex;
                flex-direction: column;
            }
            span {
                font-size: 1.3rem;
                letter-spacing: 0.2rem;
                @include phone {
                    display: block;
                    margin-top: 2px;
                    letter-spacing: 0.11rem;
                }
            }
            .action-help {
                color: $gray1;
                font-size: 1.3rem;
                text-decoration: underline;
                text-transform: none;
                letter-spacing: 0;
                margin-left: 0;
            }
        }
    }
    .payment-method-billing-address {
        @include phone {
            padding: 0 20px;
        }
    }
    .payment-method-content {
        padding: 0;
    }
    .checkout-billing-address {
        label {
            font-size: 1.4rem;
            letter-spacing: 0.14rem;
        }
        .billing-address-details {
            padding-left: 34px;
            font-size: 1.3rem;
            letter-spacing: 0.13rem;
            line-height: 1.6;
            color: $gray1;
            font-weight: 500;
            @include phone {
                padding-left: 28px;
            }
            a {
                color: $gray1;
                @include phone {
                    display: none;
                }
            }
        }
    }
    .actions-toolbar {
        margin-bottom: 15px;
        @include phone {
            margin: 0 16px !important;
            padding: 14px;
        }
    }
    .discount-code {
        display: none;
    }
}

.payment-method {
    .allowed-payment-icon {
        margin-right: 18px;
    }
    .payment-method-checkbox {
        margin-left: 64px;
        font-size: 1.4rem;
        letter-spacing: 0.14rem;
        font-weight: 500;
        @include phone {
            margin-left: 0;
            font-size: 1.3rem;
            letter-spacing: 0.13rem;
        }
        .label {
            a {
                text-decoration: underline;
            }
            @include phone {
                font-size: 1.3rem;
                letter-spacing: 0.13rem;
            }
        }
    }

    &.alma.payment-method {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-top: 1px solid #cccccc;

        .alma-method-logo{
            position: initial;
            padding: 0;
        }

        .payment-method-title {
            border: none;
            flex-basis: fit-content;
            max-width: 80%;
        }

        .payment-method-content {
            @include phone {
                padding: 0 20px;
            }

            .alma-plan-choices label {
                width: initial;
                margin-right: 10px;
            }
        }
    }
}

.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
    font-size: 1.1rem;
    letter-spacing: 0.25rem;
}

.checkout-payment-method .actions-toolbar {
    .primary {
        margin-top: 45px;
        float: none;
        text-align: left;
        .action {
            text-align: center;
            margin-top: 0;
            max-width: 310px;
            width: 100%;
        }
    }
}

.payment-method .payment-method-title .label .allowed-payment-icon {
    float: none;
    .payment-icon{
        &.payment_method_monetico_cb {
            @extend .cb-icon;
            content: none;
        }
        &.payment_method_monetico_american {
            @extend .american-icon;
        }
    }
}

//SUMMARY SUBTOTALS
.cart-summary .table.totals,
.opc-block-summary .table-totals {
    & > tbody > tr > th,
    & > tbody > tr > td,
    & > tfoot > tr > th,
    & > tfoot > tr > td {
        padding: 0;
        line-height: 2.4rem;
    }
    tr:nth-child(odd) td {
        background: transparent;
    }
    th,
    td,
    .label {
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.15rem;
    }
    .label {
        padding: 0;
    }
    tr.grand.totals{
        td,
        th {
            color: black;
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: 0.16rem;
        }
    }
}

.cart-totals .grand .amount strong,
.opc-block-summary .table-totals .grand .amount strong {
    font-weight: 500;
}

//SHIPPING INFORMATION SIDEBAR

.opc-block-shipping-information {
    margin-top: 30px;
    padding: 0;
    .shipping-information {
        border: 1px solid black;
        border-radius: 4px;
        padding: 24px;
    }
    .shipping-information-title {
        font-size: 1.6rem;
        letter-spacing: 0.16rem;
        margin-bottom: 14px;
        font-weight: 600;
    }
    .shipping-information-content {
        color: $gray1;
        font-size: 1.3rem;
        letter-spacing: 0.13rem;
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
        font-weight: 500;
        @include phone {
            flex-direction: column;
        }
        .action {
            min-width: 90px;
            align-self: flex-end;
            padding: 4px 8px;
            font-size: 1.2rem;
            letter-spacing: 0.13rem;
            text-transform: none;
            @include phone {
                align-self: flex-start;
                margin-top: 15px;
            }
        }
    }
    .ship-to {
        margin-bottom: 30px;
    }
}

//EMPTY CART
.checkout-cart-index {
    .column.main {
        min-height: 100vh;
    }
    .cart-empty {
        padding-top: 200px;
        text-align: center;
        a {
            text-decoration: underline;
        }
    }
}

//CHECKOUT SUCCESS

.checkout-onepage-failure {
    .page-main {
        margin: 0 auto;
        padding: 0 20px;
        text-align: center;
    }
    p {
        color: $gray1;
        font-size: 1.3rem;
    }
    .page-title {
        color: black;
        text-transform: none;
        font-size: 2rem;
        letter-spacing: 0.2rem;
        margin-bottom: 30px;
    }
}

.checkout-onepage-success {
    .page-main {
        margin: 0 auto;
        padding: 0;
    }
    .summary-title,
    .order-details-items,
    .block-order-details-view,
    .checkout-success {
        max-width: 900px;
        margin: 0 auto;
        padding: 0 20px;
    }
    .gray-bg {
        background: #f5f5f5;
    }
    .checkout-success {
        text-align: center;
        padding: 60px 40px;
        p {
            color: $gray1;
            font-size: 1.3rem;
        }
        .page-title {
            color: black;
            text-transform: none;
            font-size: 1.6rem;
            margin-bottom: 30px;
        }
        .actions-toolbar {
            text-align: center;
        }
    }
    .summary-title {
        padding-top: 60px;
        h2{
            text-transform: uppercase;
            color: $gray1;
            border-bottom: 1px solid #dedede;
            font-size: 1.2rem;
            letter-spacing: 0.24rem;
            padding-bottom: 20px;
            margin-bottom: 0;
        }
    }
    .order-title {
        display: none;
    }

    .order-details-items {
        border: none;
        .table-order-items tbody + tbody {
            border: none;
        }
        .actions-toolbar {
            display: none;
        }
    }

    .order-items {
        thead {
            display: none !important;
        }
        tr:nth-child(odd) td {
            background: transparent;
        }
        &:not(.cart):not(.totals) tfoot > tr:first-child th,
        &:not(.cart):not(.totals) tfoot > tr:first-child td {
            border-color: #dedede;
        }
        .product-image {
            padding-left: 0;
            width: 118px;
        }
        .subtotal {
            vertical-align: bottom;
            padding-right: 0;
        }
        tbody{
            .price-including-tax .price, .price-excluding-tax .price {
                font-weight: 400;
            }
            .price {
                font-size: 1.2rem;
                letter-spacing: 0.1rem;
                color: $gray1;
            }
        }
        table > tbody > tr > td {
            padding: 27px 10px;
        }
        table > tfoot > tr > td {
            padding: 5px;
            color: $gray1;
            strong {
                font-weight: 400;
                color: black;
            }
        }
        .product-info-container {
            @include phone {
                padding-left: 10px;
            }
        }
        .item-options {
            p {
                margin-bottom: 3px;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
            }
        }
    }

    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
        @include phone {
            min-width: auto;
            display: block;
            thead,
            tbody {
                display: block;
            }
            tfoot {
                width: 100%;
                tr {
                    td {
                        width: 50%;
                    }
                }
            }
        }
    }

    .box-order-billing-method,
    .box-order-billing-address {
        display: none;
    }

    .block-order-details-view {
        padding-top: 50px;
        .block-title {
            display: none;
        }
        .block-content {
            border: 1px solid black;
            border-radius: 4px;
            padding: 0px 24px;
        }
        .box-title {
            font-size: 1.6rem;
            letter-spacing: 0.16rem;
            margin-bottom: 14px;
            font-weight: 600;
            display: block;
            margin-bottom: 14px;
        }
        .box {
            margin: 30px 0;
        }
        .box-content {
            color: $gray1;
            font-size: 1.3rem;
            letter-spacing: 0.13rem;
            display: flex;
            justify-content: space-between;
            line-height: 1.5;
            font-weight: 500;
            @include phone {
                flex-direction: column;
            }
        }
    }
    .image-container {
        background: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        &:after{
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }
}
