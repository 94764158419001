.cms-no-route {
    .main-wrapper{
        margin: 0;
    }
    #maincontent{
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        .column.main{
            padding-bottom: 0;
        }
    }
    [data-content-type="row"]{
        max-width: 100%;
    }
    figure{
        margin: 0;
    }
    .cover {
        color: $white;
        width: 100%;
        margin: 0;

        .cover-text {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30px;

            @include phone {
                bottom: 130px;
            }

            h1,.page-title {
                @include deskH1;
                color: $white;
                position: unset;
                margin: unset;
                width: unset;
                transform: unset;
                text-align: unset;
                display: flex;
                justify-content: center;
            }

            p,.page-text {
                @include deskB1;
                color: $white;
                display: flex;
                justify-content: center;
                @include phone {
                    padding: 0 20px;
                }
            }
            a,
            .page-redirect {
                color: $white;
                text-decoration: none;
                @include deskBtn1;

                [data-element="link_text"] {
                    @include deskBtn1;
                }
            }
        }
        img {
            height: unset;
            margin: 0;
            width: 100%;
        }
    }
}
