.contact-index-index {

	.main-wrapper {
		@include tablet-down {
			margin: 0;
		}
	}

	.page-main {
		padding: 0 40px;
		margin: auto;
		
		@include tablet-down {
			padding: 0;
		}

		.contact-container-top {
			#menu-contact {
				display: flex;
				margin-bottom: 80px;
				margin-top: 40px;

				@include tablet-down {
					flex-direction: column;
					margin: 0 16px 32px;
				}

				a {
					text-decoration: none;
					color: $darkgray;
				}

				.menu-contact-item {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					min-height: 176px;
					border-top: solid 1px $grayfilet;
					border-bottom: solid 1px $grayfilet;
					border-left: solid 1px $grayfilet;
					border-right: none;

					&:last-child {
						border-right: solid 1px $grayfilet;
					}

					@include tablet-down {
						height: 120px;
    					min-height: 120px;
						border-top: solid 1px $grayfilet;
						border-right: solid 1px $grayfilet;
						border-left: solid 1px $grayfilet;
						border-bottom: none;
	
						&:last-child {
							border-bottom: solid 1px $grayfilet;
						}
					}

					h3 {
						margin: 0;
						padding-top: 10px
					}

					p {
						@include deskB2;
						text-align: center;
						color: $gray4;
						padding: 10px;
						margin: 0;
					}

					&.active {
						color: $white;
						background-color: $darkgray;

						h3,
						p {
							color: $white;
						}
					}
					@include desktop {
						&:hover {
							color: $white;
							background-color: $darkgray;
	
							h3,
							p {
								color: $white;
							}
						}
					}
				}
			}

			#div-contact-form {
				display: none;
				justify-content: center;

				&.active {
					display: flex;
				}

				.form.contact {
					width: 50%;
					
					@include tablet-down {
						width: 100%;
						margin: 0 18px 0 14px;
					}

					.h1 {
						@include deskH1;
						display: none;
						justify-content: center;

						&.active {
							display: flex;
						}

						@include tablet-down {
							@include deskH2;
						}
						
					}

					.fieldset {
						margin: 36px 0 40px;
						
						@include tablet-down {
							margin: 32px 0 3px;
						}
						.field {
							padding: 0;

							.label {
								@include deskB2;
								margin-bottom: 1px;
							}

							.control {
								margin: 5px 0 0 0;
								width: 100%;

								input {
									width: 100%;
									padding: 14px 16px 13px 16px;

									&#telephone {
										width: 100%;
									}
								}

								select {
									width: 100%;
									height: 48px;
									padding: 14px 16px 13px 16px;

									&#indicatif {
										width: 100px;
									}
								}
								
								#option-hidden {
									display: none;
								}

								.select2-selection__rendered {
									color: $darkgray !important;
								}

							}
						
							.tel-indicatif {
								display: flex;
							}


						}

						.select-shop {
							display: none;

							&.active {
								display: block;
							}
						}
					}

					.actions-toolbar {
						margin-bottom: 80px;

						@include tablet-down {
							margin-bottom: 32px;
						}
						.action.submit.primary {
							width: 100%;
							padding: 16px 0;
						}
					}

				}
			}
		}

	}

	.select2-search {
		display: none;
	}
}
