//
//  Typography
//  ---------------------------------------------

//  Headings


//	Colors:
$black: #000000;
$disable: #757575;
$darkgray: #121212;
$darkgray50: rgba(18, 18, 18, 0.5);
$darkgray20: rgba(18, 18, 18, 0.2);
$white: #ffffff;
$white50: rgba(255, 255, 255, 0.5);

$grayfilet: #cfd1d2;
$lightgray: #D9D9D9;


$gray1: #919191;
$gray2: #545454;
$gray3: #656565;
$gray4: #757575;
$gray5: #d8d8d8;
$gray6: #979797;
$gray7: #dddddd;
$gray8: #272727;
$gray9: #aaaaaa;
$gray10: #f3f3f3;
$gray11: #919191;
$gray12: #f5f5f5;
$gray13: #999999;
$gray14: #e5e5e5;
$gray15: #747474;
$gray16: #adadad;

$bgbeige: #fafafa;
$bgbeige2: #fff3dB;
$bgbeige3: #dfdbd9;
$bgbeigeclair: #fdfdfd;

$pink1: #fef5ef;
$pink2: #ffefe4;

$red: #ED2939;
// GENERAL
$max-width: 1920px;
$header-height: 96px;
$header-height-mobile: 64px;
$top-banner-height: 24px;
$top-banner-height-mobile: 32px;


// MIXINS
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

@mixin center-vertical {
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-horizontal {
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-both {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// INPUTS
$width-checkbox: 18px;
$height-checkbox: 18px;
$padding-checkbox: 0;
$fw-checkbox: 400;
$color-label-checkbox: black;
$height-input: 48px;
$color-input: $gray4;
$border-input: $grayfilet;
