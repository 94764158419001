header {
    .header.content {

        .navigation,
        .navigation_home {
            position: absolute;
            width: fit-content;
            height: 25px;
            top: 35px;
            left: 133px;
            margin: 0;
            background: none;

            &.navigation_home {
                right: 176px;
                left: auto;
            }

            img {
                display: none;
                position: fixed;
                height: 488px;
                width: 406px;
                left: 0;
                top: $header-height - 1px;
            }

            .navbar-collapse {
                position: relative;
                float: left;
                width: 100%;
                display: block;

                .navbar-nav {
                    position: relative;
                    float: left;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    column-gap: 24px;
                    flex-direction: row;

                    li.level0 {
                        position: relative;
                        float: left;
                        margin-right: 0;

                        a {
                            text-decoration: none;
                        }

                        .nav-cover {
                            z-index: 10;
                        }

                        .level-top {
                            color: $black;
                            @include deskB1;
                            padding: 0 0 35px 0;
                            border-bottom: 2px solid transparent;

                            &:hover,
                            &.active {
                                border-bottom: 2px solid $black;
                            }

                            .ui-menu-icon {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            position: fixed;
                            width: 100vw;
                            height: 488px;
                            left: 0 !important;
                            top: $header-height - 1px !important;
                            box-shadow: none;
                            background-color: $white;
                            padding: 0;
                            border-top: 1px $grayfilet solid;
                            z-index: 5;

                            .megamenu-content {
                                display: flex;
                                height: 100%;
                                margin: 0 0 0 488px;
                                column-gap: 54px;
                                padding-top: 61px;

                                >div.children {
                                    >a {
                                        @include deskUI1;
                                        color: $black;
                                        margin-bottom: 26px;
                                        display: block;

                                        &:after {
                                            display: none;
                                        }

                                        span {
                                            @include deskUI1;
                                        }
                                    }

                                    .subchildren {
                                        @include deskB1;

                                        .push {
                                            display: none;
                                        }

                                        .others {
                                            a {
                                                float: left;
                                                clear: both;
                                                margin-bottom: 8px;
                                                position: relative;

                                                &.top {
                                                    margin-top: -41px;
                                                }

                                                span {
                                                    color: $gray4;

                                                    &:hover {
                                                        color: $black;
                                                    }

                                                    &.product_count {
                                                        color: $black;
                                                        display: none;
                                                        position: absolute;
                                                        top: -1px;
                                                        @include chiffres;
                                                        margin-left: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.mobile-menu {
    display: none;
    position: fixed;
    width: 100%;
    top: 64px;
    height: 100%;
    background-color: $black;
    z-index: 12;
    overflow-y: auto;
    overflow-x: hidden;

    .mobile-navigation {
        background-color: $white;
        @include deskB1;
        padding-bottom: 40px;

        a {
            text-decoration: none;
            color: $darkgray;
        }

        .accordion-opener,
        .accordion-content a:not(.submenu-item):not(.submenu-opener),
        .accordion-link {
            width: 100%;
            padding: 14px 16px 17px 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;


            .text {
                width: 90%;
                position: absolute;
                left: 16px;
            }

            .text-submenu {
                width: 90%;
                position: absolute;
                left: 16px;
            }

            .arrow {
                width: 13px;
                @include icon-svg('arrow_right_black', 13px, 12px);
            }
        }

        .accordion-opener {
            &.openend {
                .text {
                    left: 50px !important;
                    @include deskB1;
                }
            }

            .text {
                &.text-bold {
                    left: 50px !important;
                    @include deskBtn1;
                }
            }

            .close-burger-menu {
                @include icon-svg('close_black', 24px, 24px);
                cursor: pointer;
                z-index: 12;
            }

            .arrow {
                width: 13px;
                @include icon-svg('arrow_right_black', 13px, 12px);
            }

            &.opened {
                .arrow {
                    @include icon-svg('arrow_up_black', 13px, 12px);
                    left: 16px;
                }

                &.haveGrandChildren {
                    .arrow {
                        @include icon-svg('arrow_left_black', 13px, 12px);
                        position: absolute;
                        left: 16px;
                    }
                }
            }
        }

        .accordion-content {
            width: 100%;
            display: none;

            &.opened {
                display: block;
            }

            a:not(.submenu-item) {
                background-color: $white;
                color: $gray1;
            }

            .submenu-opener {
                background-color: $white !important;
                color: $black !important;

                .text-submenu {
                    font-size: 17px;
                }
            }
        }

        .accordion-content {

            .img-category {
                display: flex;
                justify-content: center;
                padding-bottom: 15px;

                img {
                    width: 345px;
                    height: 170px;
                    margin-top: 10px;
                }
            }

            .submenu {
                width: 100%;
                overflow-y: auto;
                background-color: $white;
                transition-duration: 800ms;
                transition-property: right;
                z-index: 12;

                &.opened {
                    right: 0;

                    .submenu-opener {
                        width: 100%;
                        padding: 14px 17px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .return,
                .title,
                .wrapper {
                    width: 100%;
                    padding: 0 16px;
                }

                .return,
                .title {
                    color: $black;
                }

                .return {
                    position: relative;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    padding-left: 37px;

                    &:after {
                        content: "";
                        position: absolute;
                        left: 18px;
                        @include center-vertical;
                        @include icon-svg('arrow_left_black', 24px, 24px);
                    }
                }

                .title {
                    display: block;
                    margin: 17px 0;
                }

                .wrapper {
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    &.open {
                        display: flex;
                    }

                    .submenu-item {
                        position: relative;
                        width: 80%;
                        padding: 8px 0 8px 0;

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                        .name {
                            @include center-vertical;
                            color: $gray1;
                            text-align: right;
                            font-size: 17px;
                            float: left;
                        }
                    }
                }
            }

            .arrow {
                position: relative;
                top: 0;
                right: 0px;
                @include icon-svg('arrow_right_black', 16px, 16px);
            }

            .arrow-open {
                position: relative;
                top: 0;
                right: 0px;
                @include icon-svg('arrow_up_black', 16px, 16px);
            }
        }

        .d-none {
            display: none;
        }
    }

    .mobile-subnavigation {
        color: $white;

        a {
            text-decoration: none;
        }

        .mobile-subnavigation-content {
            display: flex;
            flex-direction: column;
            row-gap: 14px;
            padding: 56px 24px 75px;

            a,
            div {
                color: $white;
                position: relative;

                span {
                    padding-left: 7px;
                    @include deskB1;
                }
            }

            .nav-account {
                &:before {
                    content: '';
                    @include icon-svg('account_white', 24px, 24px);
                    vertical-align: -20%;
                }
            }

            .nav-search {
                &:before {
                    content: '';
                    @include icon-svg('search_white', 24px, 24px);
                    vertical-align: -20%;
                }
            }

            .nav-cart {
                &:before {
                    content: '';
                    @include icon-svg('bag_white', 24px, 24px);
                    vertical-align: -20%;
                }
            }
        }

        .mobile-subnavigation-bottom {
            bottom: 40px;
            position: relative;

            .nav-social-links {
                display: flex;
                column-gap: 24px;
                padding: 0 24px 24px;

                a {
                    height: 24px;
                    width: 24px;
                    display: block;
                }
            }

            .mobile-switcher {
                position: relative;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 70px;
                background: $black;

                .accordion-opener {
                    background-color: $black;
                    color: $white;
                    @include deskUI2;
                    position: absolute;
                    bottom: 46px;
                    left: 24px;

                    .text {
                        color: $white;
                    }

                    .arrow {
                        position: absolute;
                        top: 5px;
                        right: -20px;
                        @include icon-svg('arrow_down_white', 16px, 16px);
                    }
                }

                .accordion-content {
                    width: 100%;
                    display: none;
                    position: fixed;
                    bottom: 0;
                    background-color: $white;
                    padding-bottom: 27px;
                    padding-top: 5px;

                    &.opened {
                        display: block !important;
                    }

                    a {
                        @include deskB1;
                        background-color: $white;
                        color: $black;
                        height: 56px;
                        display: flex;
                        justify-content: space-between;
                        padding: 16px;
                    }
                }
            }
        }
    }
}
