/// La maison

.cms-maison {

    .columns .column.main {
        padding-bottom: 0;
        max-width: 100dvw;
    }
    .without-inner .row-full-width-inner {
        max-width: inherit;
    }
    .top-sticky {
        margin-top: -180px;
    }
    [data-content-type="banner"] {
        &.first-banner {
            [data-element="content"] {
                width: 50%;
                display: inline-block;
                @include tablet-down {
                    width: 100%;
                }
                h1 {
                    @include deskDisplay2;
                    color: $white;
                    @include tablet-down {
                        @include MobileDisplay2;
                    }
                }
                p {
                    @include deskB1;
                    color: $white;
                    padding-top: 25px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            .pagebuilder-poster-content {
                @include tablet-down {
                    position: absolute;
                    bottom: 80px;
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
        }
    }

    .anchors {
        margin: 55px 0 50px;

        .row-full-width-inner {
            max-width: none;
            width: max-content;

            [data-content-type="html"] {
                display: flex;
                column-gap: 39px;
                max-width: 100dvw;
                overflow: auto;
                white-space: nowrap;
                padding: 0 15px 0 15px;
                margin: 0 auto;

                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;

                a:not(:last-child) {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 50%;
                        right: -21px;
                        transform: translateY(50%);
                        @include icon-svg('dot_black', 4px, 4px);
                    }
                }
            }
        }
    }

    //Header de la timeline
    .timeline-header .column-timeline-header {
        width: 100% !important;
        padding-top: 75px;
        margin-right: 20px;
        margin-left: 20px;
        color: $darkgray;

        @include tablet-down {
            padding-top: 25px;
        }

        h2 {
            @include deskDisplay2;
            text-transform: uppercase;
            width: 50%;
            @include tablet-down {
                @include MobileDisplay2;
                width: 100%;
            }
        }
        [data-content-type="text"] p {
            @include deskH2;
            @include tablet-down {
                @include deskH3;
            }
        }
    }

    //Timeline
    .timeline {
        margin-right: 20px;
        margin-left: 20px;
        color: $darkgray;

        .column-dates {
            width: 100% !important;
            @include tablet-down {
                width: 70% !important;
                max-width: 70% !important;
            }
        }
    }

    [data-content-type="milestone"] {

        border-bottom-width: 1px;
        border-bottom-color: $grayfilet;
        border-bottom-style: solid;
        display: inline-block;

        @include tablet-down {
            border-bottom: none;
            width: 100%;
            text-align: left;
            padding-top: 25px;
        }

        .milestone-year {
            @include DeskDisplay1;
            min-width: 260px;
            display: inline-block;
            text-align: center;
            @include tablet-down {
                @include MobileDisplay1;
                min-width: inherit;
                padding-bottom: 20px;
                width: 100%;
                text-align: left;
                border-bottom-width: 1px;
                border-bottom-color: #cfd1d2;
                border-bottom-style: solid;
            }
        }
        .milestone-desc {
            display: inline-block;
            @include deskB2;
            margin-left: 76px;
            color: $darkgray;
            max-width: 35%;

            @include tablet-down {
                max-width: 100%;
                margin-left: 0px;
                width: 100%;
                padding-top: 20px;
            }
        }

        .milestone-image {
            display: inline-block;
            margin-left: 76px;
            @include DeskDisplay1;
            img {
                vertical-align: baseline;
                @include tablet-down {
                    padding-top: 40px;
                    padding-bottom: 40px;
                }
            }
            @include tablet-down {
                display: inline-flex;
                width: 50%;
                margin-left: 0px;
                line-height: inherit;
                font-size: inherit;
            }
        }

        &[data-appearance="collage-right"] {
            .milestone-image {
                float: right;
                @include tablet-down {
                    float: left;
                    margin-left: 0px;
                    display: inline-block;
                }
            }
        }

        &[data-appearance="default"] {
            .milestone-desc {
                margin-left: 0px;
                //float: right;
                bottom: 25px;
                position: relative;
            }
            .milestone-image {
                margin-right: 25%;
            }
        }

        &.without-bottom-line {
            border-bottom: none;
            margin-bottom: -2px;
            
            @include tablet-down {
                margin-bottom: 0;
            }
        }
        &.no-date {
            @include tablet-down {
                padding-top: 0px;
                .milestone-year {
                    display: none;
                    visibility: hidden;
                }
            }
        }

        &.full-image {
            display: flex;
            height: 100%;
            justify-content: space-between;
            position: relative;

            @include tablet-down {
                flex-direction: column;
            }

            .milestone-year {
                align-self: flex-end;
            }
            .milestone-desc {
                position: absolute;
                margin-left: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                
                @include tablet-down {
                    position: relative;
                }

                &::before {
                    content: '|';
                    @include DeskDisplay1;
                    text-align: center;
                    min-width: 260px;
                    height: calc(100% - 260px);
                    max-height: 260px;
                    overflow: hidden;
                    display: inline-block;
                    margin: 0;
                    margin-bottom: -2px;

                    @include tablet-down {
                        display: none;
                    }
                }

                &::after {
                    content: '|';
                    @include DeskDisplay1;
                    line-height: 220px;
                    text-align: center;
                    min-width: 260px;
                    height: calc(100% - 260px - 260px);
                    overflow: hidden;
                    display: inline-block;
                    margin: 0;

                    @include tablet-down {
                        display: none;
                    }
                }
            }
        }
    }
    [data-content-type="column"] {
        &.column-century {
            width: 25% !important;
            @include tablet-down {
                max-width: 30%;
            }
        }
        .century {
            @include DeskDisplay1;
            text-align: right;
            // Scroll
            position: sticky;
            top: 110px;
            float: right;
            left: 160px;

            @include tablet-down {
                @include MobileDisplay1;
                text-align: right;
                padding-top: 25px;
            }
        }
    }

    // Black banner
    [data-content-type="blackbanner"] {
        .blackbanner-image {
            margin-left: 40px;
            margin-top: 90px;
            margin-bottom: 110px;
            margin-right: 98px;
            float: left;
            @include tablet-down {
                margin: inherit;
                float: none;
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
        .blackbanner-desc {
            @include deskB1;
            max-width: 714px;
            width: 52%;
            color: $white;
            margin-top: 310px;
            display: inline-block;
            @include tablet-down {
                margin: 50px 15px 50px 15px;
                width: auto;
            }
        }
        .blackbanner-title {
            @include deskDisplay2;
            color: $white;
            display: flex;
            margin-top: 80px;
            text-transform: uppercase;
            max-width: 585px;
            width: 52%;
            @include tablet-down {
                @include MobileDisplay2;
                margin-left: 15px;
                margin-bottom: 65px;
                margin-top: 0px;
                width: 66%;
            }
        }
    }

    .collection-vertical-slider {
        @include tablet-down {
            max-width: 100dvw;
            overflow: hidden;
        }

        [data-content-type="column-line"] {
            [data-content-type="column"] {
                width: 70% !important;

                @include tablet-down {
                    width: 100% !important;
                }

                &.vertical-slider {
                    width: 30% !important;
                    @include tablet-down {
                        display: none !important;
                    }
                }
            }
        }
    }

    @include desktop {
        .collection-vertical-slider {
            display: flex;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .collection-slider-sticky {
                position: sticky;
                top: 0;
                height: 100%;
                max-height: 100vh;

                .collection-vertical-slide {

                    .absolute-full {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .mask-outer {
                        transform: translateY(100%);
                        overflow: hidden;
                        transition: transform 0.5s ease-out 0.5s;
                    }

                    .mask-inner {
                        transform: translateY(-100%);
                        transition: transform 0.5s ease-out 0.5s;
                    }

                    &.show {
                        .mask-outer {
                            transform: translateY(0);
                            transition: transform 0.5s ease-out 0.5s;
                        }
                        .mask-inner {
                            transform: translateY(0);
                            transition: transform 0.5s ease-out 0.5s;
                        }
                    }
                }
            }

            [data-content-type="collection-slide"] {
                min-height: 100vh;
                transition: opacity 0.5s ease-out;

                &.in-view {
                    transition: opacity 0.5s ease-out;
                }
            }

        }
    }

    [data-content-type="collection-slide"] {

        display: flex;
        //TODO fix background with opacity ??

        @include tablet-down {
            display: block;

            .first-column [data-element="collection_title"],
            .second-column,
            .third-column {
                padding-right: 15px;
                padding-left: 15px;
            }
        }

        &.bg-beige2 {
            background-color: $bgbeige2;
            opacity: 0.08;
        }
        &.bg-beige3 {
            background-color: $bgbeige3;
            opacity: 0.08;
        }

        .first-column,
        .second-column {
            min-height: 752px;
            width: 50%;
        }

        .first-column {
            border-right-width: 1px;
            border-right-color: $grayfilet;
            border-right-style: solid;

            @include tablet-down {
                width: 100%;
                min-height: inherit;
                border-right: none;
                display: flex;
                flex-direction: column-reverse;
            }

            [data-element="collection_title"] {
                @include deskH1;
                padding-top: 200px;
                margin-left: 45px;
                @include tablet-down {
                    margin-top: 60px;
                    margin-left: 0px;
                    margin-bottom: 30px;
                    padding-top: 0px;
                }
            }

            .collection-slide-picto {
                margin-left: 45px;
                margin-top: 225px;
                max-width: 233px;

                @include tablet-down {
                    max-width: 100%;
                    margin: 0;

                    img {
                        width: 100%;
                    }
                }
            }

        }
        .second-column {

            [data-element="collection_desc"] {
                @include deskB1;
                color: $darkgray;
                padding-top: 208px;
                margin-left: auto;
                margin-right: auto;
                max-width: 408px;
                @include tablet-down {
                    padding-top: inherit;
                    width: 100%;
                    max-width: 100%;
                    margin: 0;
                }
            }
            @include tablet-down {
                width: 100%;
                min-height: inherit;
                padding-bottom: 50px;
            }

        }
        .third-column {
            display: none;
            visibility: hidden;
            img {
                width: 100%;
                height: auto;
            }
        }
    }


    //Section history
    .history {

        margin: 125px 20% 125px 20%;
        @include tablet-down {
            margin: 50px 15px 50px 15px;
        }

        [data-content-type="text"] > * {
            @include deskH2;
            color: $darkgray;
            @include tablet-down {
                @include DeskBody1;
            }
            .notes {
                @include DeskBody1;
                color: $darkgray;
            }
        }
    }

    [data-content-type="row"].section-history {
        margin-top: 150px;

        @include tablet-down {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 50px;
        }

        [data-content-type="heading"] {
            @include deskDisplay2;
            color: $darkgray;
            width: 50%;
            float: right;
            margin-bottom: 100px;
            @include tablet-down {
                @include MobileDisplay2;
                width: 100%;
                float: left;
                margin-bottom: 30px;
            }
        }

        [data-content-type="text"] * {
            @include deskB1;
            color: $darkgray;
        }

        [data-content-type="column-line"] {
            [data-content-type="column"] {
                &[data-appearance="align-top"] {
                    [data-content-type="text"] {
                        color: $darkgray;
                        margin-right: 50%;
                        @include tablet-down {
                            width: 100%;
                        }

                        p {
                            @include deskH2;

                            @include tablet-down {
                                @include deskB1;
                            }
                        }
                    }
                }
                &[data-appearance="full-height"] {
                    padding-right: 80px;
                    @include tablet-down {
                        padding: inherit;
                    }
                }
            }
        }
    }

    [data-content-type="row"].section-history-right {
        margin-top: 150px;

        @include tablet-down {
            margin-right: 15px;
            margin-left: 15px;
            margin-top: 50px;
        }
        [data-content-type="heading"] {
            @include deskDisplay2;
            color: $darkgray;
            width: 75%;
            margin-bottom: 25px;
            @include tablet-down {
                @include MobileDisplay2;
            }
        }

        [data-content-type="text"] {
            @include deskB1;
            color: $darkgray;
        }


        [data-content-type="column"] {
            &[data-appearance="align-center"] {
                @include tablet-down {
                    padding: 50px 0 0 0 !important;
                }
            }
        }
    }

    [data-content-type="row"].section-history-center {
        margin-top: 150px;

        @include tablet-down {
            margin-top: 0;
            margin-bottom: 0;
            padding: 50px 15px 50px 15px;
            max-width: 100dvw;
            overflow: hidden;
        }

        [data-content-type="heading"] {
            @include deskDisplay2;
            color: $darkgray;
            width: 75%;
            float: right;
            margin-bottom: 100px;
            @include tablet-down {
                @include MobileDisplay2;
                margin-bottom: 25px;
            }
        }

        [data-content-type="text"] {
            @include deskB1;
            color: $darkgray;
        }

        [data-content-type="column-line"] {
            [data-content-type="column"] {
                &[data-appearance="align-top"] {
                    [data-content-type="text"] {
                        @include deskH2;
                        color: $darkgray;
                        margin-right: 50%;
                        margin-top: 20px;
                    }
                }
                &[data-appearance="full-height"] {
                    padding-right: 80px;
                    @include tablet-down {
                        padding: inherit;
                    }
                }
            }
        }
    }

    [data-content-type="row"].history-gallery {
        margin-top: 130px;
        @include tablet-down {
            margin-top: 0;
        }
        [data-element="link"],
        [data-element="empty_link"] {
            @include deskB1;
            color: $darkgray;
            opacity: 0.7;
            padding: 0 0 0 0;
            margin: 0 0 0 0;

            &:hover {
                text-decoration: none;
            }
        }

        [data-content-type="column-group"] {
            margin-left: 40px;
            margin-right: 15%;
        }

        [data-content-type="column"] {
            margin-right: 20px;
        }

        @include tablet-down {

            padding-right: 5px;
            padding-left: 5px;

            [data-content-type="column-group"] {
                margin-right: 0px !important;
                margin-left: 0px !important;
                [data-content-type="column"] {
                    max-width: 50%;
                    margin: 0 0 0 0;
                    padding: 15px 5px 15px 5px;
                }
            }
        }
    }

    [data-content-type="mosaic"] {

        padding: 150px 40px 150px 40px;
        @include tablet-down {
            padding: 0px 0px 0px 0px;
            max-width: 100dvw;
            overflow: hidden;
        }

        .mosaic-row {
            width: 50%;
            float: left;
            @include tablet-down {
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
                &.split {
                    width: 50%;
                    padding-right: 0px;
                    padding-left: 0px;
                    .mosaic-row-item {
                        width: 100%;
                        &.right {
                            margin-right: 15px;
                        }
                        .mosaic-image-bottom {
                            margin-left: 10px;
                        }
                        .mosaic-image-right {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .mosaic-row-item {
            width: 50%;
            display: inline-block;
            min-height: 345px;

            @include tablet-down {
                padding-right: 5px;
                padding-left: 5px;
                margin-bottom: 10px;
            }

            &.right {
                margin-left: 50%;
                @include tablet-down {
                    margin-left: 0px;
                    min-height: inherit;
                }
            }
            &.left {
                margin-right: 50%;
                @include tablet-down {
                    margin-right: 0px;
                    padding-right: 5px;
                    padding-left: 5px;
                    min-height: inherit;
                }
            }
            &.full {
                width: 100%;
                padding-bottom: 12px;
                padding-left: 12px;
                @include tablet-down {
                    padding-left: 5px;
                    padding-bottom: 0px;
                }
            }
            [data-element="image_left"] {
                padding-right: 10px;
                padding-top: 10px;
                @include tablet-down {
                    padding-right: 0px;
                    padding-top: 0px;
                }
            }
        }
    }

    [data-content-type="row"].section-history-center.section-black {

        margin-top: 0px;
        padding-bottom: 0;

        [data-content-type="heading"] {
            padding-top: 210px;
            margin-bottom: 25px;
            @include tablet-down {
                padding-top: 50px;
            }
        }

        [data-content-type="slider"] {
            padding-top: 100px;
            padding-bottom: 140px;

            @include tablet-down {
                padding-top: 60px;
                padding-bottom: 0px;
                min-height: 300px !important;
            }

            .slick-list {
                overflow: visible !important;

                @include tablet-down {
                    max-width: 100dvw;
                }

                .slick-track {
                    @include desktop {
                        transform: none !important;

                        &.sliding {
                            transition: all 0.3s ease-in !important;
                        }
                    }
                    
                    @include tablet-down {
                        overflow: hidden;
                    }
                }
            }
            .slick-slide {
                width: 245px;
                margin-right: 12px;
                transition: all 0.3s ease-in;
                opacity: 0.5;
                @include tablet-down {
                    width: 277px;
                }

                &.slick-current,
                &.slick-active {
                    transition: all 0.3s ease-in;
                  }

                .pagebuilder-poster-content {
                    @include tablet-down {
                        position: relative;
                        min-height: 300px;
                        top: 270px;
                        left: -25px;
                        @include deskB2;
                        color: $grayfilet;
                    }
                }
            }

            .slick-current {
                opacity: 1;
            }
        }
    }

    .color-slider {
        margin-top: -2px;

        .row-full-width-inner {
            margin: 0;
            max-width: none;
            width: 100%;

            [data-content-type="heading"] {
                @include deskDisplay2;
                word-wrap: normal;
                position: absolute;
                max-width: 290px;
                margin: 18.5rem 0 0 15rem;
                padding: 0;
                z-index: 10;

                @include tablet-down {
                    position: relative;
                    @include MobileDisplay2;
                    margin: 80px 15px 40px;
                }
            }

            [data-appearance="colors"] {
                position: relative;
                min-height: 600px;

                .slick-list {
                    .slick-track {
                        transition: transform 3s ease 0s !important;

                        .slick-slide {
                            position: relative;
                            opacity: 0;
                            transition: opacity 1s ease-out;
                            border: none;

                            &.slick-current {
                                opacity:1 !important;
                                transition:opacity 1s ease-in;
                            }

                            .pagebuilder-overlay {
                                display: none;
                            }

                            .pagebuilder-poster-content {
                                display: none;
                            }
                        }
                    }
                }
                .slick-dots {
                    list-style-type: none;
                    padding: 0;
                    position: absolute;
                    bottom: 50%;
                    transform: translateY(80%);
                    display: flex;
                    flex-direction: column;

                    @include tablet-down {
                        transform: translateY(60%);
                    }

                    li {
                        height: auto;
                        width: auto;
                        margin: auto;

                        button {
                            @include deskDisplay2;
                            background: transparent;
                            width: auto;
                            border: none;
                            text-indent: inherit;
                            height: auto;
                            padding: 0;
                            color: $darkgray20;

                            @include tablet-down {
                                @include MobileDisplay3;
                            }

                            &:before {
                                content: none;
                            }
                            &:hover {
                                color: $darkgray;
                            }
                        }

                        &.slick-active {
                            button {
                                color: $darkgray;
                            }
                        }
                    }
                }
            }
        }
    }

    [data-content-type="row"].section-color {
        margin: 145px 135px 130px 155px;

        @include tablet-down {
            margin: 50px 15px 25px 15px;
        }

        [data-content-type="heading"] {
            @include deskH2;
            color: $darkgray;
            width: 63%;

            @include tablet-down {
                width: 100%;
                margin-bottom: 30px;
            }
        }

        [data-content-type="text"] {
            color: $darkgray;
            width: 70%;
            margin-top: 6px;

            @include tablet-down {
                width: 100%;
            }
        }

    }

    [data-content-type="row"] .anchor {
        display: block;
        height: $header-height + $top-banner-height;
        margin-top: - ($header-height + $top-banner-height);
        visibility: hidden;

        @include tablet-down {
            height: $header-height-mobile + $top-banner-height-mobile;
            margin-top: - ($header-height-mobile + $top-banner-height-mobile);
        }
    }
}

