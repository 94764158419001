header.page-header {
    position: fixed;
    width: 100%;
    height: $header-height;
    z-index: 9999;
    background-color: transparent;
    border-bottom: none;

    @include tablet-down {
        height: $header-height-mobile;
    }

    &.page-header {
        margin-bottom: 0;
    }

    &.sticky {
        background-color: $white;
    }
    &.d-none{
        display: none;
    }

    .header.content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;

        @include tablet-down {
            padding: 0;
        }

        .mobile-locator {
            display: none;
            position: absolute;
            right: 50px;
            width: 30px;
            height: 48px;
            text-indent: -10000px;
            @include center-vertical;
            padding: 0;

            &:before {
                content: "";
                position: absolute;
                @include center-both;
                @include icon-retina('map-pin', 14px, 20px)
            }

            @include tablet-down {
                display: block;
            }
        }

        .block-switch,
        .navigation_home,
        .navigation {
            @include tablet-down {
                display: none;
            }
        }

        .block-switch {
            position: absolute;
            left: 32px;
            top: 36px;

            select.language-select {
                border: none;
                max-width: 70px;
                padding: 0 0 0 5px;
                @include deskB1;
            }

            .select2-container {
                position: relative;
                z-index: 1000;
            }

            .select2 {
                .selection {
                    .select2-selection {
                        height: auto;
                        border: 0;
                        background-color: transparent;

                        .select2-selection__rendered {
                            color: $black;
                            @include deskB1;
                        }

                        .select2-selection__arrow {
                            height: 20px;
                            left: 68px;

                            b {
                                @include icon-svg('arrow_down_black', 16px, 16px);
                            }
                        }
                    }
                }
            }
        }

        .nav-toggle {
            @include tablet-down {
                display: block;
                @include center-vertical;
                background: none;
                left: 16px;
                top: 34px;

                &:before {
                    content: "";
                    position: relative;
                    float: left;
                    @include icon-svg('burger_black', 24px, 24px);
                }
            }
        }

        .logo {
            position: absolute;
            @include center-horizontal;
            top: 23px;
            margin: 0;
            margin-left: 8px;

            @include tablet-down {
                top: 16px;
                margin: 0;

                img {
                    height: 38px !important;
                }
            }

            .logo-image {
                &.white {
                    display: none;
                }

                &.black {
                    display: block;
                }
            }
        }

        .block-search {
            height: 24px;
            position: absolute;
            right: 110px;
            top: 35px;
            width: 24px;
            padding: 0;

            @include tablet-down {
                left: 60px;
                top: 22px;
                margin-top: 0;
            }

            &:after {
                content: '';
                @include icon-svg('search_black', 24px, 24px);
            }

            .text {
                display: none;
            }
        }

        .block-account {
            height: 24px;
            position: absolute;
            right: 68px;
            top: 35px;
            width: 24px;

            @include tablet-down {
                right: 60px;
                top: 22px;
            }

            &:after {
                content: '';
                @include icon-svg('account_black', 24px, 24px);
            }

            span.text {
                display: none;
            }
        }

        .minicart-wrapper {
            span.text {
                @include tablet-down {
                    display: none;
                }
            }
        }
    }

    &.sticky {
        background-color: $white;

        .header.content {
            .block-switch {
                .select2 {
                    .selection {
                        .select2-selection {
                            background-color: $white;

                            .select2-selection__rendered {
                                color: $black;
                            }

                            .select2-selection__arrow {
                                b {
                                    @include icon-svg('arrow_down_black', 16px, 16px);
                                }
                            }
                        }
                    }
                }
            }

            .nav-toggle {
                @include tablet-down {
                    &:before {
                        @include icon-svg('burger_black', 24px, 24px);
                    }
                }
            }

            .block-search {
                &:after {
                    @include icon-svg('search_black', 24px, 24px);
                }
            }

            .block-account {
                &:after {
                    @include icon-svg('account_black', 24px, 24px);
                }
            }

            .logo {
                .logo-image {
                    &.white {
                        display: none;
                    }

                    &.black {
                        display: block;
                    }
                }
            }

            .minicart-wrapper {
                &:after {
                    @include icon-svg('bag_black', 24px, 24px);
                }

                .counter-number-mobile {
                    background: $black;
                    color: $white;
                }

                .showcart {
                    color: $black;

                    .counter {
                        span {
                            color: $black;
                        }
                    }
                }
            }

            .navigation,
            .navigation_home {
                .navbar-collapse {
                    .navbar-nav {
                        li.level0 {
                            .level-top {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
    @include desktop {
        &:hover {
            background-color: $white;

            .header.content {
                .block-switch {
                    .select2 {
                        .selection {
                            .select2-selection {
                                background-color: $white;

                                .select2-selection__rendered {
                                    color: $black;
                                }

                                .select2-selection__arrow {
                                    b {
                                        @include icon-svg('arrow_down_black', 16px, 16px);
                                    }
                                }
                            }
                        }
                    }
                }

                .block-search {
                    &:after {
                        @include icon-svg('search_black', 24px, 24px);
                    }
                }

                .block-account {
                    &:after {
                        @include icon-svg('account_black', 24px, 24px);
                    }
                }

                .logo {
                    .logo-image {
                        &.white {
                            display: none;
                        }

                        &.black {
                            display: block;
                        }
                    }
                }

                .minicart-wrapper {
                    &:after {
                        @include icon-svg('bag_black', 24px, 24px);
                    }

                    .counter-number-mobile {
                        background: $black;
                        color: $white;
                    }

                    .showcart {
                        color: $black;

                        .counter {
                            span {
                                color: $black;
                            }
                        }
                    }
                }

                .navigation,
                .navigation_home {
                    .navbar-collapse {
                        .navbar-nav {
                            li.level0 {
                                .level-top {
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.checkout-cart-index header.page-header .header.content .nav-toggle,
.checkout-index-index header.page-header .header.content .nav-toggle {
    @include tablet-down {
        display: none;
    }
}

.checkout-cart-index,
.checkout-index-index {
    header {
        &.sticky {
            background-color: transparent;
        }
        &:hover {
            background-color: transparent;
        }
    }
    .block-search {
        display: none;
    }
}

.cms-index-defaultindex {
    header {
        transition: background-color 0.4s;
        background-color: transparent;
    }
}

.nav-open {
    .page-wrapper {
        left: 0;
    }

    .mobile-menu {
        @include tablet-down {
            display: block;
        }
    }

    .nav-toggle {
        right: 16px;
        left: auto !important;

        &:before {
            background: url(../images/icons/close_black.svg) !important;
        }

        &:after {
            display: none;
        }
    }

    .block-search {
        display: none;
    }

    .block-account {
        display: none;
    }

    .minicart-wrapper {
        display: none !important;
    }
}



// Home and maison
body.cms-home,
body.cms-maison {
    .header.content {
        .block-switch {
            .select2 {
                .selection {
                    .select2-selection {
                        .select2-selection__rendered {
                            color: $white;
                        }

                        .select2-selection__arrow {
                            b {
                                @include icon-svg('arrow_down_white', 16px, 16px);
                            }
                        }
                    }
                }
            }
        }

        .nav-toggle {
            @include tablet-down {
                &:before {
                    @include icon-svg('burger_white', 24px, 24px);
                }
            }
        }

        .block-search {
            &:after {
                @include icon-svg('search_white', 24px, 24px);
            }
        }

        .block-account {
            &:after {
                @include icon-svg('account_white', 24px, 24px);
            }
        }

        .logo {
            .logo-image {
                &.white {
                    display: block;
                }

                &.black {
                    display: none;
                }
            }
        }

        .minicart-wrapper {
            &:after {
                @include icon-svg('bag_white', 24px, 24px);
            }

            .counter-number-mobile {
                background: $white;
                color: $black;
            }

            .showcart {
                color: $white;

                .counter {
                    span {
                        color: $white;
                    }
                }
            }
        }

        .navigation,
        .navigation_home {
            .navbar-collapse {
                .navbar-nav {
                    li.level0 {
                        .level-top {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}