.catalog-topnav {
    display: block;
    position: absolute;
    top: 5px;
    width: 80%;
    height: 24px;
    padding: 0;
    margin-top: 0;
    z-index: 20;

    @include tablet-down {
        top: 0;
        padding: 0 15px;
        width: 50%;
    }

    &.sticky {
        position: fixed !important;
        padding: 0 0 0 40px;
        left: 0;
        top: $header-height + 4px !important;

        @include tablet-down {
            top: $header-height-mobile + 4px !important;
            padding: 0 0 0 20px;
        }
    }

    .filters-container {
        display: flex;
        justify-content: flex-start;
        column-gap: 70px;

        .filter-options {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            min-height: auto;
            column-gap: 25px;
            justify-content: flex-start;

            .filter-options-title {
                @include deskB1;
                padding: 0;
                padding-right: 32px;
                margin-bottom: 0;
                position: relative;

                &:after {
                    content: "";
                    @include icon-svg('arrow_down_black', 16px, 16px);
                    position: absolute;
                    top: 5px;
                    right: 0;
                }

                .selected-filter-count {
                    display: none;
                    @include chiffres;
                    position: absolute;
                    margin-left: 5px;
                }

                &.active {
                    @include deskB1bold;

                    .selected-filter-count {
                        display: inline-block;
                    }
                }
            }

            .filter-options-content {
                display: none;
                min-width: 300px;
                position: absolute;
                top: 45px;
                left: 0;
                background: $white;

                @include tablet-down {
                    position: relative;
                    width: 100%;
                    min-width: 100%;
                    top: auto;
                    clear: both;
                    border: none;
                    margin-bottom: 0;
                    border-bottom: 1px solid #ebebeb;
                    overflow: auto;
                }

                .item {
                    padding: 10px 30px;
                    font-weight: 500;
                    color: $gray1;
                    font-size: 1.4rem;

                    @include tablet-down {
                        padding: 6px 14px;
                    }

                    &:hover {
                        color: black;
                    }

                    .count {
                        display: none;
                    }
                }
            }
        }

        .reset-filters {
            @include deskUI1;
            margin-top: 5px;
            text-decoration: none;
            color: $darkgray;
        }
    }

    .mobile-filter {
        .show-filters {
            position: relative;
            @include deskB1;

            &:after {
                content: "";
                @include icon-svg('filter_black', 24px, 24px);
                position: absolute;
                top: -1px;
                right: -33px;
            }
        }

        .mobile-filters-container {
            display: none;
            position: fixed;
            width: 100dvw;
            height: 100dvh;
            top: 0;
            left: 0;
            background-color: $white;
            z-index: 100;
            padding: 59px 16px 35px;

            &.open-filters {
                display: block;
            }

            .container {
                position: relative;
                height: 100%;
                padding: 0;

                .title-container {
                    display: flex;

                    .action {
                        margin-top: 2px;

                        &:before {
                            content: '';
                            @include icon-svg('close_black', 24px, 24px);
                        }
                    }

                    .title {
                        @include deskH2;
                        margin-left: 15px;
                    }
                }

                .reset-filters-mobile {
                    @include deskBtn3;
                    position: absolute;
                    top: 3px;
                    right: 0;
                    color: $darkgray;
                }

                .content .filter-options {
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid $grayfilet;
                    @include deskB1;
                    margin-top: 34px;

                    .option {
                        padding: 15px 0px 16px;
                        border-bottom: 1px solid $grayfilet;
                        display: flex;
                        justify-content: space-between;
                        position: relative;

                        &:after {
                            content: "";
                            @include icon-svg('arrow_right_black', 16px, 16px);
                            position: absolute;
                            top: 20px;
                            right: 20px;
                        }


                        .filter-options-title {
                            @include deskB1;
                            margin: 0;
                            position: relative;

                            &.bold {
                                @include deskB1bold;
                            }

                            .selected-filter-count {
                                @include chiffres;
                                position: absolute;
                                margin-left: 2px;
                            }
                        }


                        .filter-options-content {
                            display: none;
                            position: fixed;
                            width: 100dvw;
                            height: 100dvh !important;
                            top: 0;
                            left: 0;
                            background-color: $white;
                            margin: 0;
                            padding: 59px 16px 35px;
                            border-radius: 0;
                            overflow: auto;

                            &.opened {
                                display: block;
                            }

                            .container {
                                position: relative;
                                height: 100%;

                                .title-container-content {
                                    display: flex;

                                    .action {
                                        margin-top: 2px;

                                        &:before {
                                            content: '';
                                            @include icon-svg('arrow_back_black', 24px, 24px);
                                        }
                                    }

                                    .title {
                                        @include deskH2;
                                        margin-left: 15px;
                                    }
                                }

                                .reset-filter-content-mobile {
                                    @include deskBtn3;
                                    position: absolute;
                                    top: 3px;
                                    right: 0;
                                    color: $darkgray;
                                }

                                .items {
                                    border-top: 1px solid $grayfilet;
                                    margin-top: 34px;
                                    margin-bottom: 25px;

                                    &.filter-items-price {
                                        border: none;

                                        .slider-container {
                                            padding: 4px 0 9px;

                                            .slider {
                                                background: $lightgray;
                                                height: 4px;
                                                margin: 0;

                                                .ui-slider-range {
                                                    background: $darkgray;
                                                    height: 4px;
                                                    position: absolute;
                                                }

                                                .ui-slider-handle {

                                                    border: 2px solid $darkgray;
                                                    background: $darkgray !important;
                                                    height: 12px;
                                                    width: 12px;
                                                    transform: translate(-0, -4px);
                                                }
                                            }

                                            .slider-display {
                                                @include deskB1;
                                                text-align: left;
                                                color: $gray4;
                                                margin-top: 22px;
                                            }
                                        }
                                    }

                                    .item {
                                        padding: 0;

                                        a {
                                            padding: 15px 0px 14px;
                                            border-bottom: 1px solid $grayfilet;
                                            display: flex;
                                            justify-content: space-between;
                                            position: relative;
                                            color: $gray4;
                                            text-decoration: none;

                                            &.link-selected {
                                                color: $gray4;
                                            }

                                            .count {
                                                display: none;
                                            }

                                            .checkbox {
                                                &:after {
                                                    content: '';
                                                    @include icon-svg('checkbox_empty', 24px, 24px);
                                                    margin-top: 1px;
                                                    margin-right: 16px;
                                                }

                                                &.checked:after {
                                                    @include icon-svg('checkbox_checked', 24px, 24px);
                                                }
                                            }
                                        }
                                    }
                                }

                                .actions-content {
                                    text-align: center;

                                    button {
                                        width: 100%;
                                        height: 48px;
                                        background-color: $darkgray;
                                        color: $white;
                                        @include deskBtn1;
                                        border: none;
                                        margin-bottom: 16px;
                                    }

                                    .action.close {
                                        @include deskBtn3;
                                    }
                                }
                            }

                        }
                    }
                }

                .actions {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    text-align: center;

                    button {
                        width: 100%;
                        height: 48px;
                        background-color: $darkgray;
                        color: $white;
                        @include deskBtn1;
                        border: none;
                        margin-bottom: 16px;
                    }

                    .action.close {
                        @include deskBtn3;
                    }
                }
            }
        }
    }
}

.filters-tooltipster {

    .tooltipster-arrow {
        display: none;
    }

    .tooltipster-box {
        margin: 0;
        background-color: $white;
        border-radius: 4px;
        border: 1px solid $grayfilet;

        .tooltipster-content {
            overflow: hidden;
            margin: 8px 8px 0;

            .filter-options-content {
                margin: 0;

                .item {
                    &.hightlighted {
                        background-color: $bgbeige;
                    }

                    a {
                        display: flex;
                        margin-bottom: 8px;
                        text-decoration: none;

                        input {
                            display: none;
                        }

                        .label {
                            color: $gray4;
                            padding: 8px 18px;
                            display: inline-block;
                            width: 100%;
                        }

                        .checkbox {
                            &:after {
                                content: '';
                                @include icon-svg('checkbox_empty', 24px, 24px);
                                margin-top: 7px;
                                margin-right: 18px;
                            }

                            &.checked:after {
                                @include icon-svg('checkbox_checked', 24px, 24px);
                            }
                        }

                    }
                }

                .am-filter-items-price {
                    .amshopby-slider-container {
                        padding: 20px 5px 10px 10px;
                        margin: 0;

                        .am-slider {
                            background: $lightgray;
                            height: 4px;
                            margin: 0;

                            .ui-slider-range {
                                background: $darkgray;
                                height: 4px;
                            }

                            .ui-slider-handle {
                                border: 2px solid $darkgray;
                                background: $darkgray !important;
                                height: 12px;
                                width: 12px;
                                transform: translate(-7px, -4px);
                            }
                        }

                        .amshopby-slider-display {
                            @include deskB1;
                            text-align: left;
                            color: $gray4;
                            margin-top: 22px;
                        }
                    }
                }

            }
        }
    }

    .count {
        display: none;
    }
}

//SORTER
body.sorter-open {
    overflow: hidden;
}

.toolbar-products {
    padding: 0;
    margin-bottom: 35px;

    @include tablet-down {
        margin-bottom: 18px;
        display: flex;
        justify-content: flex-end;
        padding: 0 15px;
    }

    &.sticky {
        position: fixed;
        width: 100%;
        height: 40px;
        left: 0;
        top: $header-height - 2px;
        background-color: $white;
        z-index: 15;
        padding: 0 40px;

        @include tablet-down {
            top: $header-height-mobile;
            padding: 0 20px;
        }
    }

    .toolbar-sorter {
        @include tablet-down {
            display: none;
        }

        .select2 {
            .selection {
                .select2-selection {
                    height: auto;

                    .select2-selection__rendered {
                        padding: 0;
                        color: $black;
                        @include deskB1;
                    }

                    .select2-selection__arrow {
                        height: 16px;
                        position: absolute;
                        right: 0;
                        @include center-vertical;

                        b {
                            @include icon-svg('arrow_down_black', 16px, 16px);
                        }
                    }
                }
            }

            &.select2-container--open .select2-selection__arrow b {
                background: url(../images/icons/arrow_down_black.svg) !important;
            }
        }
    }

    .toolbar-sorter-mobile {
        display: none;

        @include tablet-down {
            display: block;
            padding: 0;
        }

        .hidden {
            display: none;
        }

        .sorter-label {
            position: relative;
            @include deskB1;
            padding-right: 20px;

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                @include icon-svg('arrow_down_black', 16px, 16px);
            }
        }

        #sorter-form {
            position: fixed;
            top: 0;
            left: 0;
            width: 100dvw;
            height: 100dvh;
            background-color: $white;
            z-index: 100;
            padding: 59px 16px 36px;

            .container {
                position: relative;
                height: 100%;
                padding: 0;

                .title-container {
                    display: flex;

                    .action {
                        margin-top: 2px;

                        &:before {
                            content: '';
                            @include icon-svg('close_black', 24px, 24px);
                        }
                    }

                    .title {
                        @include deskH2;
                        margin-left: 15px;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid $grayfilet;
                    @include deskB1;
                    margin-top: 34px;

                    .option {
                        padding: 15px 0px;
                        border-bottom: 1px solid $grayfilet;
                        display: flex;
                        justify-content: space-between;

                        .input {
                            visibility: hidden;
                        }

                        .radio {
                            @include icon-svg('radio_empty', 24px, 24px);
                            margin-top: 1px;
                            margin-right: 16px;
                        }

                        &.checked-initial,
                        &.checked {
                            .radio {
                                @include icon-svg('radio_checked', 24px, 24px);
                            }

                            &.not-selected {
                                .radio {
                                    @include icon-svg('radio_empty', 24px, 24px);
                                }
                            }
                        }
                    }
                }

                .actions {
                    position: absolute;
                    bottom: 0;
                    width: 100%;

                    button {
                        width: 100%;
                        height: 48px;
                        background-color: $darkgray;
                        color: $white;
                        @include deskBtn1;
                        border: none;
                        margin-bottom: 16px;
                    }

                    .action.close {
                        @include deskBtn3;
                    }
                }
            }
        }
    }
}

.select2-container--sorter-options.select2-container--open .select2-dropdown {
    border: 1px solid $grayfilet;
    border-radius: 4px;
    left: auto;
    right: -104px;
    width: fit-content !important;

    .select2-results__options {
        padding: 6px;

        li.select2-results__option {
            display: flex;
            width: 330px;
            justify-content: space-between;
            border: none;
            color: $gray4;
            @include deskB1;
            padding: 12px 20px;

            &:after {
                content: '';
                @include icon-svg('radio_empty', 24px, 24px);
            }

            &[aria-selected="true"] {
                &:after {
                    background: url(../images/icons/radio_checked.svg) !important;
                }
            }

            p {
                margin: 0;
            }

            &.select2-results__option--highlighted {
                background-color: $bgbeige;
            }
        }
    }
}