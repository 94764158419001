.pages {
	display: flex;
	justify-content: flex-end;
	strong.page {
		color: black;
		font-weight: 500;
	}
	a.page {
		color: $gray6;
		&:visited {
			color: $gray6;
		}
		&:hover {
			color: black;
		}
	}
	.item {
		border: 1px solid $gray6;
		color: $gray6;
		line-height: 40px;
		min-width: 40px;
		text-align: center;
		font-weight: 500;
		margin: 0 6px 0 0;
		&.pages-item-next {
		}
		&.current {
			border-color: $pink1;
			background: $pink1;
		}
	}
	.action {
		border: none;
		&.previous {
			margin-right: 0;
		}
		&.next {
			margin-left: 0;
		}
	}
}
