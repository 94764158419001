.btn-pink-blog {
	@extend .btn-pink;
	display: inline-block;
	padding: 7px 14px;
	text-transform: none;
	letter-spacing: 0.14rem;
}

.blog-search-index,
.blog-category-view,
.blog-index-index,
.blog-post-view {
	.columns {
		max-width: 1140px;
		margin: 0 auto;
	}
	&.page-layout-2columns-right {
		.column.main {
			width: 71%;
		}
		.sidebar-additional {
			width: 29%;
			padding-left: 48px;
		}
	}
	.block-reorder {
		display: none;
	}
	.widget {
		padding: 0 29px;
		&.block-static-block {
			padding: 0;
		}
		.block-title {
			margin-bottom: 20px;
			& > strong {
				font-weight: 600;
				letter-spacing: 0.16rem;
				font-size: 1.6rem;
			}
		}
		ul {
			font-size: 1.4rem;
			line-height: 1;
			color: $gray1;
			font-weight: 500;
			margin: 0;
			padding: 0;
			list-style-type: none;
			li {
				margin: 0;
				padding: 0;
			}
		}
	}
}

.post-holder {
	background: transparent;
	border-bottom: 1px solid #cecece;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 0;
	&:hover {
		box-shadow: 0 0 0 transparent;
	}
	.post-header {
		.post-title {
			text-transform: none;
			a {
				font-size: 2.4rem;
				letter-spacing: 0.24rem;
				color: black;
			}
		}
	}
	.post-categories {
		a {
			@extend .btn-pink-blog;
		}
	}
	.post-comments {
		.bubble-icon {
			margin-right: 10px;
		}
		a {
			display: flex;
			align-items: center;
		}
	}
	.post-header {
		.post-info {
			padding-bottom: 22px;
			.item {
				margin-right: 15px;
				font-size: 1.2rem;
				font-weight: 500;
				letter-spacing: 0.2rem;
			}
			.value {
				font-style: normal;
			}
		}
	}
}

.post-list {
	.post-content {
		.post-read-more {
			@extend .btn-pink-blog;
			font-size: 1.4rem;
		}
	}
}

.post-content {
	.post-description {
		.post-ftimg-hld {
			float: none;
			margin: 0 0 20px 0;
			width: 100%;
		}
	}
}

.post-text-hld {
	margin-bottom: 35px;
}

.post-description p,
.post-description {
	font-size: 1.4rem;
	color: $gray1;
	letter-spacing: 0.14rem;
	font-weight: 500;
	line-height: 1.43;
}

//SIDEBAR
.widget {
	&.blog-search {
		float: none;
		margin-top: 65px;
		padding: 0;
		input {
			height: 55px;
			color: black;
		}
		.action.search {
			@extend .search-icon;
			right: 18px;
			top: 50%;
			transform: translateY(-50%);
			&:before {
				content: none;
			}
		}
	}
	&.block-recent-posts {
		font-size: 1.4rem;
		letter-spacing: 0.09rem;
		.block-content {
			.item {
				padding: 16px 0;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #cecece;
			}
		}
		.post-posed-date {
			font-size: 1.2rem;
			color: $gray1;
		}
		.post-ftimg-small {
			max-width: 60px;
			margin-right: 30px;
		}
	}
	&.block-archive,
	&.block-categories {
		a {
			color: $gray1;
		}
		ul li {
			padding: 6px 0;
			font-size: 1.4rem;
			letter-spacing: 0.09rem;
			line-height: 1;
			font-weight: 500;
		}
	}
	&.block-archive {
		.block-content {
			color: $gray1;
			font-size: 1.4rem;
			letter-spacing: 0.09rem;
			line-height: 1;
			font-weight: 500;
		}
		a {
			margin: 6px 0;
			display: inline-block;
		}
	}
}

