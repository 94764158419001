.faq-container {
    max-width: 900px;
    margin: 0 auto;

    h3 {
        font-weight: 600;
        font-size: 2.5rem;
        letter-spacing: 0.4rem;
        margin-bottom: 60px;
    }

    h4 {
        font-weight: 600;
        font-size: 2rem;
        letter-spacing: 0.12rem;
        text-transform: none;
        margin-bottom: 40px;
    }

    h5 {
        font-weight: 600;
        font-size: 1.4rem;
        letter-spacing: 0rem;
        text-transform: none;
        margin-bottom: 20px;
    }

    & > ul {
        margin: 0 0 30px;
        padding: 0;
        list-style-type: none;
        border-bottom: 1px solid #dadada;
        ul {
            list-style-type: circle;
            padding-left: 40px;
        }
        div,
        ul,
        p {
            color: $gray1;
            font-weight: 500;
            font-size: 1.4rem;
            letter-spacing: 0rem;
            line-height: 1.5;
        }
        div,
        p {
            line-height: 2;
        }

        a,
        strong {
            color: black;
            font-weight: 500;
        }
    }
    .question {
        margin: 30px 0;
    }
    .address {
        padding: 30px;
        color: black;
    }
    table {
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 2px;
        font-weight: 300;
        border: 1px solid black;
        & > thead > tr > th, 
        & > thead > tr > td,
        & > tbody > tr > th, 
        & > tbody > tr > td, 
        & > tfoot > tr > th, 
        & > tfoot > tr > td {
            padding: 18px 14px;
        }
        tbody {
            color: #393939;
            letter-spacing: 0;
            line-height: 1.3;
            strong {
                font-weight: 600;
            }
        }
        thead {
            color: $gray1;
            font-weight: 500;
        }
    }
}
