// Play / Pause / Mute / Unmute button for video
div[data-background-type="video"] {
	position: relative;

	.video-play-button {
		width: 20px;
		height: 20px;
		position: absolute;
		left: 25px;
		bottom: 25px;
		background: url(../images/controls/pause.svg) no-repeat 7px center;
		border: none;
		padding: 0;
		z-index: 5;

		@include desktop {
			display: none;
		}

		&.play-off {
			background: url(../images/controls/play.svg) no-repeat 7px center;
		}
	}

	.video-sound-button {
		width: 20px;
		height: 20px;
		position: absolute;
		left: 55px;
		bottom: 25px;
		background: url(../images/controls/muted.svg) no-repeat center center;
		border: none;
		padding: 0;
		z-index: 5;

		@include desktop {
			display: none;
		}

		&.sound-on {
			background: url(../images/controls/unmuted.svg) no-repeat center center;
		}
	}

	&:hover {

		.video-sound-button,
		.video-play-button {
			display: block;
		}
	}

	// Titre, text, button
	.video-description {
		position: absolute;
		bottom: 0;
		text-align: center;

		[data-content-type="heading"] {
			@include deskH1;
			color: $white;

			@include tablet-down {
				@include deskH2;
			}
		}

		[data-content-type="text"] {
			@include deskB1;
			color: $white;
		}


		[data-content-type="buttons"] {
			a {
				text-decoration: none;
			}

			span {
				@include deskBtn1;
				color: $white;
			}
		}
	}
}

.cms-maison, 
.cms-index-index,
.catalog-product-view {
	[data-content-type="row"][data-background-type="video"]
	{
		padding-top: 56.25%;

		@include tablet-down {
			padding-top: 124%;
		}
	}
}

.category-moodboard {
	div[data-background-type="video"]
	{
		@include tablet-down {
			padding-top: 124%;
		}
	}
}