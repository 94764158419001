//LIST
.page-products {
    .main-wrapper {
        @include tablet-down {
            margin: 0;
        }

        .page-main {
            padding: 0 40px 100px;

            @include tablet-down {
                padding: 0;
            }

            .columns {
                position: relative;
                float: left;
                width: 100%;
                display: flex;
                flex-direction: column;

                .column.main {
                    float: none;
                    width: 100%;
                    padding-left: 0;

                    @include tablet-down {
                        padding-bottom: 22px;
                    }

                    #amasty-shopby-product-list {
                        position: relative;
                        float: left;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 30px;

                        @include tablet-down {
                            margin-top: 12px;
                        }

                        .products-grid {
                            position: relative;
                            float: left;
                            width: 100%;
                            padding: 0;
                            margin: 0;

                            @include tablet-down {
                                padding: 0 16px;
                            }

                            .product-items {
                                display: grid;
                                grid-gap: 13px;
                                grid-row-gap: 37px;
                                grid-template-columns: repeat(4, 1fr);
                                margin-left: 0;

                                @include tablet-down {
                                    grid-gap: 10px;
                                    grid-row-gap: 13px;
                                    grid-column-gap: 10px;
                                    grid-template-columns: repeat(2, 1fr);
                                    margin-top: 0;
                                }

                                .product-item {
                                    margin: 0;
                                    grid-column: span 1;
                                    grid-row: span 1;
                                    width: auto;
                                    margin-bottom: 0;
                                    padding-left: 0px;

                                    &.fake-item {
                                        grid-row: span 2;
                                        grid-column: span 2;
                                        display: flex;

                                        @include tablet-down {
                                            display: none;
                                        }

                                        img {
                                            max-width: 100%;
                                        }

                                        .product-item-name {
                                            color: black;
                                            text-align: center;
                                        }

                                        .product-item-description {
                                            color: black;
                                            text-align: center;
                                            width: 100%;
                                        }

                                        .price-box .price {
                                            color: white;
                                        }


                                        img {
                                            width: 100%;
                                            position: relative;
                                        }

                                        .product-item-info {
                                            position: relative;
                                        }

                                        .product-item-details {
                                            position: relative;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                            padding: 0;
                                        }
                                    }

                                    .product-item-info {
                                        width: 100%;
                                        position: relative;

                                        .news-tag {
                                            position: absolute;
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-around;
                                            width: 94px;
                                            height: 24px;
                                            @include deskUI3;
										    z-index: 10;
                                            color: $white;
                                            background-color: $darkgray;
                                        }

                                        .product-item-photo {
                                            background: transparent;
                                            display: block;
                                            overflow: hidden;
                                            display: block;
                                            width: 100%;
                                            background: #f5f5f5;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            img {
                                                width: 100%; //190px;
                                                height: auto;
                                                max-width: 100%; //55%;
                                                position: relative;
                                                display: block;

                                                &:last-child {
                                                    display: none;
                                                }
                                            }

                                            @include desktop {
                                                &:hover {
                                                    img {
                                                        display: none;

                                                        &:last-child {
                                                            display: block;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .product-item-details {
                                            .product-item-name {
                                                .product-item-link {
                                                    overflow: hidden;
                                                    display: -webkit-box;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;
                                                    word-break: keep-all;
                                                    -webkit-hyphens: none;
                                                    -moz-hyphens: none;
                                                    -ms-hyphens: none;
                                                    -o-hyphens: none;
                                                    hyphens: none;
                                                }
                                            }

                                            .product-subtitle {
                                                @include deskB2;
                                                color: $gray4;
                                              }
                                        }
                                    }

                                    .product-item-inner {
                                        display: none;
                                    }
                                }
                            }

                        }

                        .cross-category {
                            text-align: center;
                            max-width: 745px;
                            margin: 62px auto;

                            @include tablet-down {
                                text-align: left;
                                margin: 0 15px;
                            }

                            h2 {
                                @include deskH2;
                                margin-bottom: 9px;
                            }
                        }
                    }
                    .item.category-moodboard {
                        figure{
                            margin: 0;
                        }
                        height: auto;
                        display: inline-block;

                        [data-content-type="buttons"] {
                            position: absolute;
                            width: 100%;
                            bottom: 62px;
                        }

                        .category-moodboard-content {
                            position: relative;
                            height: 100%;
                            & div,figure,img{
                                height: 100% !important;
                            }
                        }

                        .buttons-moodboard{
                            align-items: flex-end;

                            > div{
                                display: flex !important;
                                justify-content: center;
                                a{
                                    white-space: nowrap;
                                    margin: 0;
                                    color: $white;
                                    span {
                                        @include deskBtn1;
                                    }
                                }
                            }
                        }
                        @include phone {
                            height: auto;
                            width: 100dvw;
                            margin: 0 -16px;
                            img {
                                width: 100%;
                            }
                        }

                        @include medium-menu {
                            height: auto;
                            margin-left: auto;
                            margin-right: auto;
                            width: 100%;
                            img {
                                height: auto;
                                width: 100%;
                            }
                        }

                        @include bigdesktop {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        &.item.category-moodboard-position-1 {

                            @include phone {
                                float: none;
                            }
                        }

                        &.item.category-moodboard-position-2,
                        &.item.category-moodboard-position-0 {
                            &.left-pos {
                                float: left !important;
                            }

                            @include phone {
                                float: none;
                            }
                        }

                    }

            }

        }
    }

    .sidebar {
        display: none !important;
    }

    .am_shopby_apply_filters {
        display: none !important;
    }
}
}
//Make Search and listing one column layout:
.page-products {
    .sidebar-main {
        float: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .block-wishlist {
        display: none;
    }
}
