.product-item-name,
.product.name a {
    @include deskH3;
    margin-top: 12px;
    margin-bottom: 9px;
    text-decoration: none;

    @include tablet-down {
        @include deskB1;
        margin-top: 19px;
        margin-bottom: 0;
    }

}

.product-item-description {
    margin: 0 0 6px 0;
    color: $gray4;
    @include deskB2;
}

.product-item {
    .price-box {
        margin: 3px 0;

        .price {
            @include deskB2;
            color: $gray4;
        }
    }
}

//VIEW
.catalog-product-view {

    .main-wrapper {
        @include tablet-down {
            margin: 0;
        }

        .messages {
            @include tablet-down {
                height: $header-height-mobile + $top-banner-height-mobile;

                position: absolute;
                top: $header-height-mobile;
                z-index: 10;
            }
        }

        #maincontent {
            padding: 0;

            .columns .column.main {
                position: relative;
                padding-bottom: 0;

                .product-main-wrapper {
                    display: flex;
                    position: relative;
                    margin-bottom: 40px;

                    @include tablet-down {
                        flex-direction: column;
                        align-items: center;
                    }

                    .product.media {
                        float: none;
                        width: 58%;
                        margin: 0;
                        position: relative;
                        left: 40px;
                        padding-right: 45px;

                        @include tablet-down {
                            width: 100%;
                            left: unset;
                            padding-right: unset;
                        }

                        // Custom scrollbar
                        &::-webkit-scrollbar {
                            width: 0;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: transparent;
                        }

                        .gallery-slider {
                            width: 100%;
                            text-align: center;

                            img {
                                width: 100%;

                                &.small {
                                    width: 50%;
                                    float: inline-start;
                                }
                            }
                            .iframeDiv{
                                pointer-events: none;
                                &.small {
                                    width: 50%;
                                    float: inline-start;
                                }
                                > iframe {
                                    width: 100vw;
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }


                            .product-images-carousel {
                                position: relative;

                                .slick-dots {
                                    padding: 0;
                                    position: absolute;
                                    bottom: 20px;

                                    li {
                                        width: 6px;
                                        height: 6px;

                                        button {
                                            border: none;
                                            background: $darkgray20;
                                            width: 6px;
                                            height: 6px;
                                        }

                                        &.slick-active button {
                                            border-color: $darkgray;
                                            background: $darkgray;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .product-info-main {
                        padding-left: 45px;
                        position: sticky;
                        height: 100%;
                        top: 100px;

                        @include tablet-down {
                            padding-right: 18px;
                            padding-left: 18px;
                        }

                        // Product name
                        .page-title-wrapper {
                            .page-title {
                                @include deskH2;
                                margin-bottom: 0;
                                margin-top: 1px;

                                @include tablet-down {
                                    margin-top: 25px;
                                }

                                span {
                                    @include deskH2;
                                }
                            }
                        }

                        // Product material
                        .product-subtitle {
                            @include deskB1;
                            margin-bottom: 25px;
                            color: $gray4;

                            @include tablet-down {
                                margin-bottom: 0;
                            }
                        }

                        // Product price
                        .product-info-price {
                            margin-bottom: 47px;

                            @include tablet-down {
                                margin-bottom: 25px;
                            }

                            .price-wrapper {
                                .price {
                                    @include deskB1;
                                }
                            }
                        }

                        // ALMA
                        #alma-widget {
                            margin-bottom: 20px;

                            @include tablet-down {
                                margin: 0 auto 40px;
                            }

                            .alma-payment-plans-container {
                                width: 100%;
                                border: 1px solid $grayfilet;
                            }
                        }

                        // Product options, product buttons
                        .product-add-form,
                        .product.alert.stock {
                            //SIZE OPTIONS
                            .product-options-wrapper {
                                .fieldset {
                                    display: flex;
                                    flex-direction: column;
                                    margin: 0;
                                    position: relative;

                                    .field {
                                        margin: 0 0 25px;

                                        @include tablet-down {
                                            margin: 0 0 7px;
                                            order: 1;
                                        }

                                        >.label {
                                            display: none;
                                        }

                                        &:not(:first-child):last-of-type {
                                            margin-bottom: 15px;

                                            @include tablet-down {
                                                margin-bottom: 0;
                                            }
                                        }

                                        &.width-pdf {
                                            max-width: 70%;

                                            @include tablet-down {
                                                max-width: 100%;
                                            }
                                        }

                                        &.pdf {
                                            width: fit-content;
                                            position: absolute;
                                            right: 0;
                                            top: 20px;

                                            @include tablet-down {
                                                width: 100%;
                                                position: initial;
                                                order: 0;
                                                text-align: end;
                                            }

                                            a {
                                                color: $gray1;
                                                text-decoration: underline;
                                            }
                                        }

                                        .control {
                                            margin-bottom: 0;

                                            .label {
                                                display: block;
                                            }

                                            select {
                                                height: 48px;
                                                @include deskB1;
                                                color: $gray4;
                                                border: none;
                                                background-color: $bgbeige;
                                            }

                                            .select2-container {
                                                max-width: 100%;
                                                width: auto !important; // Override JS value

                                                .select2-selection {
                                                    background: transparent;
                                                    border: none;
                                                    background-color: $bgbeige;

                                                    .select2-selection__rendered {
                                                        @include deskB1;
                                                        padding-top: 14px;
                                                    }
                                                }
                                            }

                                            input[type="checkbox"]+label {
                                                &::after {
                                                    width: 16px;
                                                    height: 16px;
                                                    left: 3px;
                                                    top: 3px;
                                                    border-radius: 2px;
                                                    border: 1px solid $gray4;
                                                }

                                                &::before {
                                                    left: 2px;
                                                    top: 2px;
                                                }

                                                &:hover::after {
                                                    border: 1px solid $gray4;
                                                }
                                            }

                                            #engraving-span {
                                                @include deskB1;
                                                padding-left: 8px;
                                                margin-right: 5px;
                                            }
                                        }
                                    }
                                }
                            }

                            .box-tocart {
                                display: block;
                                margin-top: 15px;

                                @include tablet-down {
                                    margin-top: 10px;
                                }

                                .actions {
                                    display: block;
                                    width: 100%;
                                    justify-content: space-between;
                                    padding: 0;

                                    @include tablet-down {
                                        flex-direction: column;
                                    }

                                    &>* {
                                        width: 100%;
                                        margin: 0;
                                        margin-bottom: 15px;
                                        height: 48px;
                                        @include deskBtn1;

                                        &.button {
                                            border: 1px solid $gray4;

                                            &:hover {
                                                border: 1px solid $gray4;
                                                color: $darkgray;
                                            }
                                        }

                                        &.action:hover {
                                            background: $darkgray;
                                            border: 1px solid $darkgray;
                                        }
                                    }

                                    .action.tocart,
                                    .action.product-stock-alert {
                                        @include tablet-down {
                                            margin-bottom: 15px;
                                        }
                                    }

                                    button {
                                        @include tablet-down {
                                            margin-right: 0;
                                        }

                                    }

                                    #product-stock-alert-no-stock {
                                        @include deskB1;
                                        text-transform: none;
                                        margin-bottom: 7px;
                                    }
                                }

                                .paypal.before {

                                    &::before {
                                        display: none;
                                    }

                                    .action-paypal {
                                        margin-top: 0;
                                        height: 48px;

                                        span {
                                            @include deskBtn1;
                                        }
                                    }
                                }
                            }
                        }

                        //TABS INFO
                        .product.info.detailed {
                            margin-bottom: 15px;

                            @include tablet-down {
                                margin-bottom: 35px;
                            }

                            .product.data.items {
                                margin-bottom: 0;

                                &:before {
                                    content: none;
                                }

                                &>.item {
                                    &.title {
                                        float: none;
                                        width: 100%;
                                        text-align: left;
                                        margin: 0 0 15px 0;
                                        padding: 0;
                                        border-bottom: 1px solid $grayfilet;

                                        .switch {
                                            @include deskH4;
                                            display: block;
                                            width: 100%;
                                            height: 40px;
                                            position: relative;
                                            padding: 0;
                                            margin: 0;
                                            border: none;
                                            background: transparent;
                                            color: $darkgray;

                                            &:after {
                                                @include icon-svg('arrow_down_black', 16px, 16px);
                                                position: absolute;
                                                top: 0;
                                                right: 0;
                                                content: '';
                                            }
                                        }

                                        &.active {
                                            border-bottom: none;
                                            margin: 0;

                                            .switch:after {
                                                @include icon-svg('arrow_up_black', 16px, 16px);
                                            }
                                        }

                                    }

                                    &.content {
                                        float: none;
                                        border: none;
                                        border-bottom: 1px solid $grayfilet;
                                        background: transparent;
                                        padding: 0 0 15px 0;
                                        margin: 0 0 17px 0;
                                        @include deskB2;

                                        >* {
                                            @include deskB2;
                                            margin: 0;
                                        }

                                        p {
                                            @include deskB2;
                                            margin: 0;
                                        }
                                    }
                                }
                                .item.title{
                                    white-space: nowrap;
                                }
                            }
                        }

                        // Related products
                        .block.related {
                            .products-related {
                                margin: 0;

                                .product-items {
                                    margin: 0;
                                    display: flex;
                                    flex-direction: column;

                                    @include tablet-down {
                                        flex-direction: row;
                                    }

                                    .product-item {
                                        width: 100%;
                                        margin: 6px 0 0 0;

                                        .product-item-info {
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            column-gap: 30px;

                                            @include tablet-down {
                                                flex-direction: column;
                                            }

                                            .product-item-photo {
                                                width: 34%;

                                                @include tablet-down {
                                                    width: 100%;
                                                }

                                                .product-image-photo {
                                                    position: relative;
                                                    margin: 0;
                                                }
                                            }

                                            .product-item-details {
                                                width: 100%;


                                                @include tablet-down {
                                                    .product-item-name {
                                                        margin-top: 10px;
                                                    }
                                                }

                                                .price-box {
                                                    .price.formatted {
                                                        @include deskB2;
                                                    }
                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }

                //Related container
                .related-wrapper {
                    .product-wear-with {
                        .row-full-width-inner {
                            margin: 0;
                            max-width: none;

                            .pagebuilder-column {
                                margin: 0;
                                position: relative;
                                text-align: center;

                                figure {
                                    margin: 0;

                                    img {
                                        width: 100%;
                                    }
                                }

                                [data-content-type='heading'] {
                                    position: absolute;
                                    left: 50%;
                                    bottom: 8rem;
                                    transform: translateX(-50%);
                                }

                                [data-content-type='text'] {
                                    position: absolute;
                                    left: 50%;
                                    bottom: 2rem;
                                    transform: translateX(-50%);
                                }
                            }

                        }
                    }

                    .block.crosssell {
                        margin: 120px 20px 96px 40px;

                        @include tablet-down {
                            margin: 38px 0px 35px 16px;
                        }

                        #block-crosssell-heading {
                            @include deskH1;

                            @include tablet-down {
                                @include deskH2;
                            }
                        }

                        .products-crosssell {
                            position: relative;

                            .pagingInfo {
                                @include chiffres;
                                position: absolute;
                                top: -35px;
                                right: 50px;

                                @include tablet-down {
                                    display: none;
                                }
                            }

                            .products {
                                margin: 20px 0;

                                .slick-arrow {
                                    position: absolute;
                                    top: -30px;
                                    background: none;
                                    width: fit-content;
                                    height: fit-content;
                                    opacity: 1;

                                    &.slick-prev {
                                        right: 90px;
                                        left: auto;

                                        &::before {
                                            @include icon-svg('arrow_left_gray', 9px, 17px);
                                            content: '';
                                        }
                                    }

                                    &.slick-next {
                                        right: 15px;

                                        &::before {
                                            @include icon-svg('arrow_right_gray', 9px, 17px);
                                            content: '';
                                        }
                                    }
                                }

                                .product-item-info {
                                    width: 98%;

                                    .product-item-photo {
                                        display: flex;
                                        background: $gray12;
                                        align-items: center;
                                        justify-content: center;

                                        img {
                                            margin: 0;
                                            position: relative;
                                            width: 100%;
                                        }
                                    }

                                    .product-item-name a {
                                        @include deskB1;
                                    }
                                }
                            }
                        }
                    }

                    .product-category {
                        .row-full-width-inner {
                            margin: 0;
                            max-width: none;
                            position: relative;
                            text-align: center;

                            figure {
                                margin: 0;

                                img {
                                    width: 100%;
                                }
                            }

                            .product-category-heading {
                                @include deskH1;
                                position: absolute;
                                left: 50%;
                                bottom: 8rem;
                                transform: translateX(-50%);

                                @include tablet-down {
                                    @include deskH2;
                                    bottom: 3rem;
                                }
                            }

                            [data-content-type='buttons'] {
                                position: absolute;
                                left: 50%;
                                bottom: 3rem;
                                transform: translateX(-50%);

                                @include tablet-down {
                                    display: none
                                }

                                .pagebuilder-button-link {
                                    color: $darkgray;
                                    text-decoration: none;

                                    span {
                                        @include deskBtn1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .price-box.price-tier_price {
            display: none;
        }

        #product-stock-alert-button {

            &:hover,
            &:focus,
            &:active {
                background: $darkgray;
                border-color: $darkgray;
            }
        }
    }


    .select2-container.select2-container--open .select2-dropdown {
        border: 1px solid $gray7;
        border-radius: 0;

        .select2-results__options {
            padding: 8px 0;

            li.select2-results__option {
                @include deskB1;
                display: flex;
                justify-content: space-between;
                border: none;
                color: $darkgray;

                &[aria-disabled=true] {
                    display: none;
                }

                p {
                    margin: 0;
                }

                .envelope {
                    @include icon-svg('envelope_gray', 24px, 24px);
                }

                &.no-stock {
                    color: $gray4;
                }

            }

            &.select2-results__option--highlighted {
                background-color: $bgbeige;
            }
        }
    }

	@include tablet-down {
		&>.select2-container.select2-container--open:not(.select2-container--language-theme-footer) {
			position: fixed !important;
			bottom: 0;
			height: 100dvh;
			top: 0 !important;
			left: 0 !important;
			width: 100%;
			background-color: $darkgray50;
			z-index: 10000;

			.select2-dropdown {
				border: none;
				border-radius: 0;
				width: 100dvw !important;
				right: 0;
				left: 0;
				bottom: 0;

				.select2-results__options {
					padding: 10px 0 24px 0;

					li.select2-results__option {
						width: auto;
						display: flex;
						justify-content: space-between;
						border: none;
						padding: 15px 20px;

						p {
							margin: 0;
						}
					}
				}
			}
		}
	}

    .modal-popup.confirm .modal-inner-wrap {
        min-width: 640px;
        top: 50%;
        transform: translate(-50%, -50%);
        @include deskB1;

        @include tablet-down {
            width: 90dvw;
            min-width: unset;
            transform: translate(0%, -60%);

            .modal-content {
                padding-top: 30px;
            }
        }

        .modal-footer {
            padding: 22px 10% 50px;
            .action-accept {
                height: 48px;
                width: 100%;
            }
        }
    }
}




//FOTORAMA
._block-content-loading {
    .loading-mask {
        padding-bottom: 40% !important;

        background: transparent;
    }
}

.fotorama__spinner {
    width: 110px;
    height: 110px;
}

.fotorama {
    @include tablet-down {
        margin-top: 0;
    }

    margin-top: 30px;
}

.fotorama:not(.fotorama--fullscreen) {
    &.is-worn {
        .fotorama__stage {
            @include tablet-down {
                height: 100vw !important;
                max-height: 680px;
            }

            height: 680px !important;
            max-height: 680px;
        }
    }
}

.is-worn .fotorama__stage__frame .fotorama__img {
    @include tablet-down {
        max-width: inherit;
    }
}

.fotorama__caption {
    display: none;
}

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
    @include tablet-down {
        display: none !important;
    }
}

.fotorama__nav-wrap--vertical {
    .fotorama__nav__shaft {
        background: transparent;
    }
}

.fotorama__nav__frame {
    margin-bottom: 28px;
}

.fotorama__thumb {
    background: transparent;
}

.fotorama__thumb-border {
    opacity: 0;
}

.fotorama__nav {
    @include tablet-down {
        margin-top: 10px;
    }
}

.fotorama__active .fotorama__dot {
    border-color: $darkgray;
    background: $darkgray;
}

.fotorama__dot {
    width: 6px;
    height: 6px;
    border-color: $darkgray20;
    background: $darkgray20;
}


//FOTORAMA FULLSCREEN
.fotorama--fullscreen {
    z-index: 10000 !important;

    background: #f5f5f5;

    .fotorama__fullscreen-icon {
        @extend .cross-icon;

        width: 80px;
        height: 80px;

        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px 24px;
    }

    .fotorama__zoom-in,
    .fotorama__zoom-out {
        display: none;
    }

    .fotorama__stage {
        height: 100vh !important;
    }

    .fotorama__wrap {
        height: 100%;
    }
}


// Modal alert stock
.product-alert-stock-modal {

    .action-close {
        padding: 10px;
    }

    @media (max-width: 767px) {
        &.modal-slide {
            right: 10px;
            left: 10px;
        }
    }

    // Content
    .modal-inner-wrap {
        max-width: 640px;
        text-align: center;
        box-shadow: unset;

        @media (max-width: 767px) {
            max-width: 334px;
            position: absolute !important;
            height: auto !important;
            margin: 5rem auto !important;
            background-color: white !important;

            .modal-content {
                padding-right: 2rem !important;
                padding-left: 2rem !important;
            }
        }
    }

    [id="product_alert_stock_popup"] {
        margin-top: 30px;

        @media (max-width: 767px) {
            padding-bottom: 0px;
        }
    }

    // Modal title
    h1 {
        word-break: break-word;
        @include deskH2;
    }

    // Field
    .fieldset {
        margin-bottom: unset;
        padding: unset;
        margin-top: 15px;

        &.space {
            margin-bottom: -5px;
        }

        .field {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -5px;

            p {
                @include deskB1;
                margin: unset;
            }

            label {
                display: flex;

                span {
                    @include deskB2;
                }
            }
        }
    }

    div.detail-p {
        max-width: 510px;
        width: 100%;
        margin-top: 25px;

        @include tablet-down {
            max-width: 270px;
            margin-top: 20px;
        }
    }

    .action.primary {
        display: block;
        width: 100%;
        max-width: 510px;
        margin: auto;
        margin-bottom: 20px;
        max-height: 48px;

        @include tablet-down {
            max-width: 270px;
        }
    }

    .check-error.error {
        border-color: $red;
    }

    .messages {
        margin: 30px auto;
        max-width: 270px;

        @include tablet-down {
            margin-bottom: 15px;

        }

        &.error {
            display: flex;
            max-width: unset;
            margin: 0 0 10px 35px;

            @include tablet-down {
                margin: 0 0 15px 10px;
            }
        }

        .message {
            @include deskB2;
            margin: 0;
            padding: 0;
            color: $gray4;

            &.error {
                color: $red;
                float: left;
                background: unset;
                position: unset;
                margin: unset;
                padding: unset;
            }
        }
    }
}
