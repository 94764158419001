.top-banner-open {
	// Header
	header.page-header {
		height: $header-height + $top-banner-height;

		.header.content {
			padding-top: 22px + $top-banner-height;

			.logo {
				top: 24px + $top-banner-height;
			}

            .block-switch {
                top: 38px + $top-banner-height;
            }

            .block-search {
                top: 36px + $top-banner-height;
            }
            
            .block-account {
                top: 36px + $top-banner-height;
            }

            .minicart-wrapper {
                top: 36px + $top-banner-height;
            }

            .navigation,
            .navigation_home {
                top: 36px + $top-banner-height;

				img {
					top: $header-height + $top-banner-height;
				}

				.navbar-collapse .navbar-nav li.level0 .dropdown-menu {
					top: $header-height + $top-banner-height !important;
				}
            }
		}

		@include tablet-down {
			height: $header-height-mobile + $top-banner-height-mobile;

			.header.content {
				padding-top: 0;

				.mobile-locator{
					margin-top: $top-banner-height-mobile / 2;
				}
				
				.nav-toggle{
					top: 34px + $top-banner-height-mobile;
				}
				
				.block-account,
				.block-search,
				.minicart-wrapper {
					top: 22px + $top-banner-height-mobile;
				}
			}
		}

		.top-banner {
			position: absolute;
			z-index: 6;
			top: 0;
			right: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			height: $top-banner-height;
			background-color: $black;
			color: $white;
			text-align: center;
			@include deskUI2;
			
			@include tablet-down {
				height: $top-banner-height-mobile;
			}

			.top-banner-content {
				width: 100%;

				div {
					display: flex;
					justify-content: center;
					column-gap: 10px;
					overflow: hidden;
					height: $top-banner-height;
					align-items: center;
					padding-top: 1px;

					@include tablet-down {
						height: $top-banner-height-mobile;
					}
		
					p {
						margin-bottom: 0;
						font-size: inherit;
						line-height: $top-banner-height;
						overflow: hidden;
						height: $top-banner-height;

						@include tablet-down {
							height: $top-banner-height-mobile;
							line-height: $top-banner-height-mobile;
						}
					}

					a {
						border-bottom: 1px solid $white;
						color: $white;
						font-size: inherit;
					}
				}

			}
		}
	}

	.toolbar-products.sticky {
		top: $header-height + $top-banner-height -1px;

		@include tablet-down {
			top: $header-height-mobile + $top-banner-height-mobile;
		}
	}

	// Filter category and search
	.catalog-topnav.sticky {
		top: $header-height + $top-banner-height + 4px !important;

		@include tablet-down {
			top: $header-height-mobile + $top-banner-height-mobile !important;
		}
	}

	& > .select2-container--language-theme.select2-container--open {
		top: 70px + $top-banner-height !important;
	}

	// Mobile open menu
	.nav-open & {
		.mobile-menu {
			@include tablet-down {
				top: $header-height-mobile + $top-banner-height-mobile;
			}
		}
	}

	.main-wrapper {
		margin-top: $header-height + $top-banner-height;

		@include tablet-down {
			margin-top: $header-height-mobile + $top-banner-height-mobile;
		}
	}

	// Search
	#search-modal {
		top: $header-height + $top-banner-height;

		@include tablet-down {
			top: $header-height-mobile + $top-banner-height-mobile;
		}
	}
}
