.slideshow {
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  margin-top: -$header-height;
  margin-bottom: 104px;
  @include center-horizontal;

  @include phone {
    margin-bottom: 25px;
  }

  .slideshow-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .slide {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    margin-top: $header-height;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.is-active {
      display: block;
    }

    &.fullscreen {
      height: 100%;
      margin-top: 0px;
    }

    &.is-loaded {
      opacity: 1;
    }

    .caption {
      padding: 0 100px;
    }

    #ytplayer {
      position: absolute;
      float: left;
      width: 100%;
      height: 100%;
    }

    .video-container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100vh;

      video {
        display: block;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }

    .video-poster-container {
      display: none;
    }

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      z-index: 1;
      background-size: cover;
      image-rendering: optimizeQuality;

      img,
      picture {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    &.is-active {
      &.first {
        img {
          &.image_desktop {
            transform: scale(1.4);
            transition: 4s;
          }
        }
      }
    }

    &.is-loaded {
      &.first {
        img {
          &.image_desktop {
            transform: scale(1);
          }
        }
      }
    }

    &-content {
      position: absolute;
      @include center-vertical;
      width: 40%;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      @include phone {
        width: 60%;
      }

      .caption {
        padding: 0px;

        .title {
          font-family: FuturaBT;
          font-size: 36px;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 15.7px;
          color: inherit;
          text-transform: uppercase;
          margin-bottom: 60px;

          @include phone {
            font-size: 26px;
          }
        }

        .text {
          font-family: FuturaBT;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.88;
          letter-spacing: normal;
          color: inherit;
          margin-bottom: 70px;

          @include phone {
            display: none;
          }
        }

        .btn {
          position: relative;
          float: left;
          color: inherit;
          font-family: FuturaBT;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 3.9px;
          text-transform: uppercase;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          @include phone {
            display: block;
            font-size: 12px;
            @include center-horizontal;
          }

          span {
            position: relative;
            float: left;

            @include phone {
              clear: both;
            }

            &.line {
              width: 57px;
              height: 2px;
              margin: 0px 0px 0px 12px;

              @include phone {
                margin: 12px 0px 0px 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }

      &.left {
        left: 8%;

        .caption {

          .title,
          .text,
          .btn {
            text-align: left;
          }
        }
      }

      &.right {
        right: 8%;

        .caption {

          .title,
          .text,
          .btn {
            text-align: right;
          }

          .btn {
            float: right;
          }
        }
      }

      &.center {
        width: 40%;
        left: 30%;

        .caption {
          margin-top: 60px;

          .title,
          .text,
          .btn {
            text-align: center;
          }

          .btn {
            position: relative;
            float: left;
            @include center-horizontal;
          }
        }
      }

      @include phone {
        &.left,
        &.right,
        &.center {
          width: 40%;
          left: 30%;

          .caption {
            margin-top: 60px;

            .title,
            .text,
            .btn {
              text-align: center;
            }

            .btn {
              position: relative;
              float: left;
              @include center-horizontal;
            }
          }
        }
      }
    }

    &.video-slide {
      .slide-content {
        display: none;
      }

      .image-container {
        display: none;
      }

      @include phone {
        #ytplayer {
          display: none;
        }

        .slide-content {
          display: block;
        }

        .image-container {
          display: block;

          img {
            .img_mobile {
              display: block;
            }
          }
        }
      }
    }

    &.video-mp4 {
      .image-container {
          display: none;
      }
    }
  }

  .pagination {
    position: absolute;
    @include center-vertical;
    right: 38px;
    list-style: none;
    z-index: 8;

    .page {
      position: relative;
      float: left;
      clear: both;
      width: 10px;
      height: 10px;
      margin-bottom: 20px;
      border-radius: 100%;
      cursor: pointer;
      background-color: $white;

      &:last-child {
        margin-bottom: 0px;
      }

      &.is-active {
        background-color: $black;
        cursor: auto;
      }
    }

    @include phone {
      top: auto;
      transform: none;
      bottom: 22px;
      right: 22px;

      .page {
        clear: none;
        margin-bottom: 0px;
        margin-right: 10px;
        border: 1px solid $white;
        background-color: transparent;

        &:last-child {
          margin-right: 0px;
        }

        &.is-active {
          background-color: $white;
        }
      }
    }
  }

  .tobottom {
    position: absolute;
    @include center-horizontal;
    bottom: 50px;
    width: 40px;
    height: 40px;
    border: 1px solid $white;
    border-radius: 100%;
    z-index: 9;

    &:after {
      content: "";
      position: absolute;
      @include icon-retina('select-white', 11px, 7px);
      @include center-horizontal;
      bottom: 5px;
    }

    @include phone {
      width: 36px;
      height: 36px;
      bottom: 20px;
    }
  }
}
