.button,
button {
	//reset
  font-family: inherit;
	font-size: 100%;
	line-height: 1.15; 
	margin: 0; 
	overflow: visible;
	text-transform: none;
	border: none;
  box-shadow: 0 0 0 transparent;

	background: transparent;
	border: 1px solid $darkgray;
	color: $darkgray;
	border-radius: 0;
	padding: 18px 25px;
	font-size: 1.2rem;
	letter-spacing: 0.09rem;
	&:focus,
	&:active,
	&:hover{
		background: transparent;
	  border: 1px solid $gray5;
	  color: $gray3;
	}
}

a.button{
  display: inline-block;
}

.gray-button,
.black-button,
a.black-button,
a.action.primary,
.action.primary {
	@include deskBtn1;
  	border-radius: 0;
	background: $darkgray;
	border: 1px solid $darkgray;
	color: white;
  	padding: 18px 25px;
	&:focus,
	&:active,
	&:hover {
		color: white;
		background: $gray3;
		border-color: $gray3;
	}
}

.btn-pink,
a.action.secondary,
.action.secondary {
  border-radius: 0;
	background: $pink1;
	border: 1px solid $pink1;
	color: $gray4;
	text-transform: uppercase;
	font-weight: 500;
	padding: 18px 25px;
	font-size: 1.2rem;
	letter-spacing: 0.28rem;
	&:focus,
	&:active,
	&:hover {
		color: $darkgray;
		background: $pink2;
		border-color: $pink2;
	}
}

.gray-button {
	background: #f5f5f5;
	border-color: #f5f5f5;
	color: $darkgray;
	&:focus,
	&:active,
	&:hover {
		background: #f5f5f5;
		border-color: #f5f5f5;
		color: $darkgray;
	}
}

.small-button {
	padding: 4px 10px;
}



// Paypal button

.tocart.action-paypal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    margin-top: 20px;
    margin-bottom: 20px;

    @include phone {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    input {
        margin-left: 12px;
    }
}