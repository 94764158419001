body {
    @include deskB1;
}

body h1 {
    @include deskH1;
}

body h2 {
    @include deskH2;
}

body h3 {
    @include deskH3;
}

body h4 {
    @include deskH4;
}

body p,
body span {
    @include deskB1;
}

button span {
    @include deskBtn1;
}

body a, 
body .alink {
    @include deskB1;
    color: $darkgray;
    text-decoration: none;

    &:hover,
    &:visited, 
    &:active {
        color: $darkgray;
        text-decoration: none;
    }
}

body strong{
    font-family: JostMedium;
    font-weight: 500;
}

body .breadcrumbs * {
    @include deskB1;
}

body .title{
    @include deskB1;
}

body blockquote {
    margin: 0;
    p{
        font-family: $main-font;
        @extend p;
        font-style: italic;
        &:before {
            content: '\201C';
        }
        &:after {
            content: '\201D';
        }
    }
}

body cite {
    font-style: normal;
    font-weight: normal;
    line-height: 1.88;
    font-size: 1.6rem;
}
