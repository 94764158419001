$minicart-width: 600px;
$minicart-image-size: 168px;

.minicart-items .action.delete:before {
    display: none;
}

.minicart-items .action.delete>span {
    position: relative;
}

.modal-open-stop-scroll {
    overflow: hidden;
}

@include phone {
    max-width: 75px;
}

header {
    .header.content {
        .minicart-wrapper {
            position: absolute;
            top: 35px;
            right: 22px;
            margin: 0;
            
            @include tablet-down {
                right: 16px;
                top: 22px;
            }

            &:after {
                content: '';
                @include icon-svg('bag_black', 24px, 24px);
            }

            &#minicart-desktop {
                @include phone {
                    display: none;
                }
            }

            &#minicart-mobile {
                display: none;

                @include phone {
                    display: block;
                }
            }

            .counter-number-mobile {
                background: $black;
                color: $white;
                display: inline-block;
                text-align: center;
                line-height: 14px;
                vertical-align: text-top;
                font-size: 11px;
                width: 14px;
                height: 14px;
                border-radius: 100%;
                position: absolute;
                top: 10px;
                right: 0;
            }

            @include tablet-down {
                position: absolute;
                right: 16px;
                top: 22px;
                margin: 0;
            }

            .showcart {
                float: left;
                padding-bottom: 4px;
                font-size: 13px;
                letter-spacing: 1.3px;
                color: $black;
                width: 24px;
                height: 24px;
                position: absolute;

                @include tablet-down {
                    display: none;
                }

                &:before {
                    display: none;
                }

                .text {
                    position: relative;
                    float: left;
                    width: auto;
                    height: auto;
                    clip: unset;
                    margin: 0;
                }

                .counter {
                    position: relative;
                    float: left;
                    width: auto;
                    height: auto;
                    min-width: auto;
                    margin: 0 0 0 18px;
                    padding: 0;
                    background: none;
                    line-height: normal;
                    border-radius: 0;

                    span {
                        font-size: 13px;
                        letter-spacing: 1.3px;
                        color: $black;
                    }
                }
            }

            .ui-dialog {
                position: fixed;
                background-color: rgba($black, 0.5);
                width: 100vw;
                height: 100vh;
                left: 0;
                top: 0;
                z-index: 1000;

                .block-minicart {
                    position: absolute;
                    width: $minicart-width;
                    height: 100%;
                    top: 0;
                    right: 0;
                    min-width: auto;
                    margin: 0;
                    padding: 10px;
                    background-color: $white;

                    &:before {
                        display: none;
                    }

                    a {
                        text-decoration: none;
                    }

                    .block-title {
                        display: block;
                        padding: 21px 20px 48px 20px;

                        strong {
                            position: relative;

                            .text {
                                @include deskH2;
                            }

                            .qty {
                                position: absolute;
                                right: -15px;
                                @include deskB1;
                            }
                        }
                    }

                    .block-content {
                        position: relative;
                        padding: 0 0 0 20px;

                        #btn-minicart-close {
                            top: -90px;
                            right: 12px;
                        }

                        .subtitle.empty {
                            text-align: initial;
                            padding: 26px 0;
                            @include deskB1;
                        }

                        .minicart-items-wrapper {
                            min-height: 175px;
                            height: auto !important;
                            border: 0;
                            padding: 0 15px 0 0;
                            margin: 0;

                            ol {
                                width: 100%;

                                li {
                                    width: 100%;
                                    padding: 0 0 10px;
                                    position: relative;

                                    &.product-item:not(:first-child) {
                                        border: none;
                                    }

                                    .product-item-photo {
                                        background: #fafafa;
                                        width: $minicart-image-size;
                                        height: $minicart-image-size;

                                        .product-image-container {
                                            width: $minicart-image-size !important;
                                            height: $minicart-image-size;

                                            span,
                                            img {
                                                width: $minicart-image-size !important;
                                                height: $minicart-image-size !important;
                                            }
                                        }
                                    }

                                    .product-item-details {
                                        padding-left: $minicart-image-size;
                                        margin-left: 15px;

                                        .product-item-name {
                                            margin-bottom: 6px;
                                            margin-right: 30px;
                                            word-break: keep-all;
                                            -webkit-hyphens: none;
                                            -moz-hyphens: none;
                                            -ms-hyphens: none;
                                            -o-hyphens: none;
                                            hyphens: none;

                                            a {
                                                @include deskB1;
                                                color: $black;

                                                &:hover {
                                                    text-decoration: none;
                                                }
                                            }
                                        }

                                        .product-material {
                                            color: $gray1;
                                            margin-bottom: 1px;
                                            @include deskB2;
                                        }

                                        .product-options-qty {
                                            display: flex;
                                            column-gap: 5px;

                                            .product.options-wrapper {
                                                margin: 0;
                                                display: flex;

                                                &:after {
                                                    content: '•';
                                                    padding-left: 10px;
                                                    padding-top: 2px;
                                                }

                                                .option-item {
                                                    display: flex;
                                                }

                                                .label,
                                                .values {
                                                    margin: 0;
                                                    padding: 0;
                                                    @include deskB2;
                                                    color: $gray4;

                                                    span {
                                                        @include deskB2;
                                                    }
                                                }

                                                .label {
                                                    padding-right: 4px;
                                                }
                                            }

                                            .product-item-pricing {
                                                display: flex;
                                                align-items: baseline;

                                                .qty {
                                                    margin-top: 0;
                                                    width: 100%;

                                                    .label,
                                                    input {
                                                        margin: 0;
                                                        padding: 0;
                                                        @include deskB2;
                                                        color: $gray4;
                                                    }

                                                    .label {
                                                        width: auto;

                                                        &:after {
                                                            content: ' : ';
                                                        }
                                                    }

                                                    input {
                                                        width: 20px;
                                                        height: auto;
                                                        min-width: auto;
                                                        border: 0;
                                                        pointer-events: none;
                                                    }
                                                }
                                            }
                                        }

                                        .price-container {
                                            .price-wrapper {
                                                color: $gray1;
                                                @include deskB2;

                                                .price {
                                                    font-weight: 400;
                                                }
                                            }
                                        }

                                        .product-actions {
                                            width: auto;
                                            position: absolute;
                                            top: 2px;
                                            right: 8px;

                                            a {
                                                background: url(../images/icons/bin.svg) center no-repeat;
                                                height: 20px;
                                                width: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .subtotal {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-top: 1px solid $grayfilet;
                            padding: 14px 0 50px 174px;
                            margin: 8px 22px 0 0;

                            .label,
                            .amount .price {
                                @include deskB2;
                            }
                        }
                    }

                    .actions {
                        padding: 22px 40px 0 20px;
                        position: absolute;
                        width: 100%;
                        bottom: 50px;
                        text-align: center;

                        .primary,
                        .secondary {

                            button,
                            a,
                            span {
                                width: 100%;
                                display: block;
                                width: 100%;
                                border: none;
                                padding: 17px 0;
                                @include deskBtn1;

                            }
                        }

                        .alma-cart-eligibility {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 16px;
                            @include deskBtn1;

                            span {
                                @include deskBtn1;
                            }
                        }

                        .extra_actions {
                            .action-paypal {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0;
                                padding: 10px 0;
                                @include deskBtn1;

                                input {
                                    margin-left: 12px;
                                }
                            }
                        }

                        .secondary {
                            margin-top: 16px;

                            .viewcart {
                                border: solid 1px $black;
                                color: $darkgray;
                                padding: 0;

                                &.disable {
                                    border: solid 1px $disable;
                                    pointer-events: none;
                                }
                            }
                        }
                    }

                }
            }

            &.active {
                .ui-dialog {
                    .block-minicart {
                        transition-delay: 100ms;
                        transition-duration: 500ms;
                        transition-property: right;
                    }
                }
            }
        }
    }
}
