// Screen size variables
$screen-xs-min: 768px;  // Phone
$screen-sm-min: 1024px; // Tablet
$screen-md-min: 1200px; // Desktop
$screen-lg-min: 1460px;
$screen-xl-min: 1700px;

// Mixins
@mixin phone-only { @media (max-width: #{$screen-xs-min - 1}) {@content;} }

@mixin phone { @media (max-width: #{$screen-xs-min}) {@content;} }
@mixin bigphone { @media (max-width: #{$screen-sm-min}) {@content;} }
@mixin tablet { @media (min-width: #{$screen-xs-min + 1}) {@content;} }
@mixin desktop { @media (min-width: #{$screen-sm-min + 1}) {@content;} }
@mixin bigdesktop { @media (min-width: #{$screen-md-min}) {@content;} }

@mixin tablet-down { @media (max-width: #{$screen-sm-min}) {@content;} }
@mixin desktop-down { @media (max-width: #{$screen-md-min}) {@content;} }
@mixin mediumscreen { @media (max-width: #{$screen-lg-min}) {@content;} }
@mixin bigscreen { @media (max-width: #{$screen-xl-min}) {@content;} }

@mixin tablet-product { @media (max-width: #{$screen-sm-min - 1}) {@content;} }

@mixin portrait-tablet {
    @media only screen
    and (min-device-width : #{$screen-xs-min})
    and (max-device-width : #{$screen-sm-min})
    and (orientation : portrait) {
        @content;
    }
}

@mixin medium-menu {
    @media
    (min-width : 769px)
    and (max-width : 1440px) {
        @content;
    }
}
//Retina images
@mixin image-2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio : 1.3),
        (-o-min-device-pixel-ratio: 2.6 / 2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
            // on retina, use image that's scaled by 2
            background-image: url($image);
            background-size: $width $height;
    }
}

@mixin icon-retina($name, $width, $height) {
    display: inline-block;
    width: $width;
    height: $height;
    background: url(../images/icons/#{$name}.png);
    @include image-2x("../images/icons/#{$name}@3x.png", $width, $height);
}

@mixin icon-svg($name, $width, $height) {
    display: inline-block;
    width: $width;
    height: $height;
    background: url(../images/icons/#{$name}.svg);
    background-size: cover;
}

@mixin mobileH1 {
    //styleName: Mobile/Heading 1;
    font-family: Jost;
    font-size: 25px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: -0.02em;
    font-feature-settings: "ss01" 1;
}
@mixin deskH1 {
    //styleName: Desk/Heading 1;
    font-family: Jost;
    font-size: 30px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: -0.02em;
    font-feature-settings: "ss01" 1;
}
@mixin deskH2 {
    //styleName: Desk/Heading 2;
    font-family: Jost;
    font-size: 23px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.02em;
    font-feature-settings: "ss01" 1;
}
@mixin deskH3 {
    //styleName: Desk/Heading 3;
    font-family: Jost;
    font-size: 19px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.02em;
    font-feature-settings: "ss01" 1;
}
@mixin deskH4 {
    //styleName: Desk/Heading 4;
    font-family: Jost;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    font-feature-settings: "ss01" 1;
}
@mixin deskB1 {
    //styleName: Desk/Body 1;
    font-family: Jost;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    font-feature-settings: "ss01" 1;
}
@mixin deskB1bold {
    //styleName: Desk/Body 1;
    font-family: JostMedium;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    font-feature-settings: "ss01" 1;
}
@mixin deskB2 {
    //styleName: Desk/Body 2;
    font-family: Jost;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
    font-feature-settings: "ss01" 1;
}
@mixin deskBtn1 {
    //styleName: Desk/Button 1;
    font-family: RalewaySemiBold;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
}
@mixin deskBtn2 {
    //styleName: Desk/Button 2;
    font-family: Jost;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    font-feature-settings: "ss01" 1;
}
@mixin deskBtn3 {
    //styleName: Desk/Button 3;
    font-family: Jost;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    font-feature-settings: "ss01" 1;
    text-decoration: underline;
}
@mixin deskUI1 {
    //styleName: Desk/UI 1;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
}
@mixin deskUI2 {
    //styleName: Desk/UI 2;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
@mixin deskUI3 {
    //styleName: Desk/UI 3;
    font-family: RalewaySemiBold;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
}

@mixin chiffres {
    //styleName: Chiffres;
    font-family: Jost;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
}

@mixin deskDisplay2 {
    //styleName: Desk/Display 2;
    font-family: Jost;
    font-size: 68px;
    font-weight: 400;
    line-height: 68px;
    font-feature-settings: "ss01" 1;
}

@mixin DeskDisplay1 {
    //styleName: Desk/Display 1;
    font-family: Jost;
    font-size: 260px;
    font-weight: 300;
    line-height: 260px;
    letter-spacing: -0.06em;
    font-feature-settings: "ss01" 1;
}

@mixin DeskBody1 {
    //styleName: Desk/Body 1;
    font-family: Jost;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.63px;
    font-feature-settings: "ss01" 1;
}

@mixin MobileDisplay1 {
    //styleName: Mobile/Display 1;
    font-family: Jost;
    font-size: 85px;
    font-weight: 300;
    line-height: 85px;
    letter-spacing: -0.04em;
    font-feature-settings: "ss01" 1;
}

@mixin MobileDisplay2 {
    //styleName: Mobile/Display 2;
    font-family: Jost;
    font-size: 30px;
    font-weight: 400;
    line-height: 37.2px;
    font-feature-settings: "ss01" 1;
}

@mixin MobileDisplay3 {
    //styleName: Mobile/Display 3;
    font-family: Jost;
    font-size: 44px;
    font-weight: 400;
    line-height: 45.76px;
    font-feature-settings: "ss01" 1;
}

@mixin bold {
    font-family: JostMedium;
    font-weight: 500;
}
