.page-footer {
	.block.newsletter {
		margin: 0;
		width: 331px;
		padding-top: 24px;

		@include tablet-down {
			width: auto;
			position: initial;
			margin-left: auto;
			padding-top: 16px;
			padding-bottom: 43px;
		}

		.title {
			display: block;
			margin-bottom: 25px;

			@include tablet-down {
				display: none;
			}

			.gray {
				padding-top: 6px;
			}
		}

		.mobile-title {
			display: none;
			@include deskB1;

			@include tablet-down {
				display: block;
				display: flex;
				justify-content: space-between;
			}

			&:after {
				@include tablet-down {
				  content: '';
				  @include icon-svg('arrow_down_white', 16px, 16px);
				  margin: 4px 5px 0 0;
				}
			  }
	
			  &.open:after {
				@include tablet-down {
				  @include icon-svg('arrow_right_white', 16px, 16px);
				}
			  }
		}

		.content {
			position: relative;

			@include tablet-down {
				width: 311;
				padding-top: 14px;
			}

			&.hidden {
				@include tablet-down {
					display: none;
				}
			}

			.form.subscribe {
				padding-bottom: 3px;
				border-bottom: 1px solid $gray4;

				.field.newsletter {
					margin: 0;

					.control {
						margin: 0;

						&:before {
							content: none;
						}

						input {
							border: none;
							padding: 0;
							height: 30px;
							margin: 0;
							color: $white;
							background-color: $darkgray;
							margin: 0;
							@include deskB1;

							@include tablet-down {
								@include deskB2;
							}

							&::placeholder {
								color: $white;
								opacity: 1;
							}

							&:focus {
								border: none;
								outline: none;
								box-shadow: none;
							}
						}
					}
				}

				.actions {
					.action.subscribe {
						border: none;
						color: $white;
						padding: 0;
						text-decoration: underline;
						border-radius: 0;
						@include deskB1;

						@include tablet-down {
							@include deskB2;
							padding: 3px 6px;
						}
					}
				}
			}

			.messages {
				position: absolute;
				bottom: 0;
				width: 100%;

				.message {
					@include deskB1;
					text-align: left;
					color: $white;
					background-color: $darkgray;
					padding: 10px 0 6px;
					margin: 0 0 1px;

					@include tablet-down {
						@include deskB2;
					}

					div {
						position: relative;
						padding-right: 20px;

						&::before {
							content: none;
						}

						&::after {
							content: '';
							position: absolute;
							right: 0;
							top: 3px;
							@include icon-svg('check_white', 16px, 16px);
						}
					}
				}
			}
		}

		#newsletter-error {
			position: absolute;
			bottom: -3px;
			transform: translateY(100%);
			margin-top: 0;
		}

		&:target {
			padding-top: 153px;
		}

		@media (max-width: #{$screen-sm-min}) {
			&:target {
				padding-top: 79px;
			}
		}
	}
}