.lock-icon {
	@include icon-svg('lock', 15px, 19px);
}

.gift-icon {
	@include icon-svg('gift', 20px, 19px);
}

.return-icon {
	@include icon-svg('return', 19px, 19px);
}

.search-icon {
	@include icon-svg('search', 20px, 20px);
}

.pin-icon {
	@include icon-svg('map-pin', 14px, 20px);
}

.plus-icon {
	@include icon-svg('plus', 19px, 24px);
}

.bubble-icon {
	@include icon-svg('bubble', 17px, 14px);
}

.tick-icon {
	@include icon-svg('tick', 12px, 9px);
}

.tick-white-icon {
	@include icon-svg('tick-white', 12px, 9px);
}

.arrow-icon {
	@include icon-svg('down', 11px, 7px);
}

.cross-icon {
	@include icon-svg('close', 14px, 14px);
}

.cb-icon {
	@include icon-retina('cb', 50px, 30px);
}

.american-icon {
	@include icon-retina('american', 50px, 30px);
}

.present-icon {
	@include icon-retina('present', 110px, 110px);
}
