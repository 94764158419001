.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #d8d8d8;
    transition: .4s;
    min-width: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: white;
      transition: .4s;
    }
  }

  input:checked+.slider {
    background-color: #000;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #000;
  }

  input:checked+.slider:before {
    transform: translateX(14px);
  }
}

.switch-hide {
  display: none;
}
