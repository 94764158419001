.cookie-notice {
	top: auto;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 50px;
	background: white;
	padding: 35px 50px;
	width: 100%;
	max-width: 1260px;
	display: flex;
	justify-content: space-between;
	@include tablet-down {
		bottom: 20px;
		left: 20px;
		right: 20px;
		width: auto;
		flex-direction: column;
	}
	.cookie-notice-link,
	.cookie-notice-text {
		color: $gray2;
		font-family: $main-font;
		font-size: 1.2rem;
		letter-spacing: 0.15rem;
	}
	.close-cookie-image {
		position: absolute; 
		width: 14px; 
		height: 14px; 
		top: 14px; 
		right: 14px; 
		cursor: pointer;
		&:before,
		&:after { 
			background: black; 
			content: ''; 
			left: 0; 
			position: absolute; 
			height: 1px; 
			top: 50%; 
			width: 100%;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
	.close-cookie {
		@extend .button;
		padding: 15px 25px;
		margin: 0 20px;
		@include tablet-down {
			margin: 0 auto;
			max-width: 100px;
		}
	}
}
