.account {
	.table-wrapper {
		width: 100%;
		max-width: 786px;
		border: 1px solid black;
		border-radius: 3px;
		@include phone {
			width: auto;
		}
	}
}

table {
	tr {
		td,
		th {
			font-weight: 500;
			font-size: 1.4rem;
			letter-spacing: 0.1rem;
		}
		th {
			color: $gray1;
		}
		td {
			color: #393939;
		}
		&:nth-child(odd) {
			td {
				background: #f7f7f7;
			}
		}
	}
	& > thead{
		& > tr {
			& > th,
			& > td {
				padding: 17px 10px;
			}
		}
	}
	& > tbody,
	& > tfoot {
		& > tr {
			& > th,
			& > td {
				padding: 10px;
			}
		}
	}
}

//UNDO MAGENTO RESPONSIVE TABLES

@include phone {
	.table-wrapper {
		overflow-x: auto;
		.table:not(.cart):not(.totals):not(.table-comparison) {
			min-width: 768px;
			width: auto;
			display: table;
			& > thead {
				display: table-header-group;
				& > tr {
					display: table-row;
					td,
					th {
						display: table-cell;
					} 
				}
			}
			& > tbody {
				display: table-row-group;
				& > tr {
					display: table-row;
					td,
					th {
						display: table-cell;
						padding: 10px 0;
						&:first-child {
							padding-top: 10px;
						}
						&:last-child {
							padding-bottom: 10px;
						}
						&:before {
							content:"";
						}
					} 
				}
			}
		}
	}
}