/* ----------------------------------------------------------
  Font Face
  Example
  @include font-face("FuturaBT-Bold", "../fonts/FuturaBT-Bold", 700, normal, woff2 woff ttf eot svg);
---------------------------------------------------------- */
@include font-face("FuturaBT", "../fonts/FuturaBT-Bold", 700, normal, woff2 woff ttf eot svg);
@include font-face("FuturaBT", "../fonts/FuturaBT-Medium", 600, normal, woff2 woff ttf eot svg);
@include font-face("FuturaBT", "../fonts/FuturaStd-BookOblique", 600, italic, woff2 woff ttf eot svg);
@include font-face("FuturaBT", "../fonts/FuturaBT-Book", 500, normal, woff2 woff ttf eot svg);
@include font-face("FuturaBT", "../fonts/FuturaBT-Light", 300, normal, woff2 woff ttf eot svg);

@include font-face("RalewaySemiBold", "../fonts/raleway/Raleway-SemiBold", 600, normal, ttf);
@include font-face("Raleway", "../fonts/raleway/Raleway-Medium", 500, normal, ttf);

@include font-face("JostMedium", "../fonts/jost/Jost-Medium", 500, normal, ttf);
@include font-face("Jost", "../fonts/jost/Jost-Regular", 400, normal, ttf);


$main-font: "FuturaBT", Lucida Grande, Lucida Sans Unicode, Lucida Sans, Arial, sans-serif;

$futurabookc : "FuturaBT", Lucida Grande, Lucida Sans Unicode, Lucida Sans, Arial, sans-serif;
