.blog-post-view {

    // Reset position img
    .product-image-photo {
        position: static;
        left: auto;
        right: auto;
        bottom: auto;
        top:auto;
    }
}

.post-view,
.post-view-modern {
	.post-holder {
		border: none;
		.post-header {
			.post-title {
				margin: 30px 0 10px;
			}
		}
	}
	.post-content {
		border-bottom: 1px solid #cecece;
	}

	.post-bottom {
		margin-top: 30px;

		.post-gallery {
			margin: 15px 0 15px 0 !important;
			display: block !important;

			a {
				margin: 0 14px 14px 0;
				border: 1px solid #ccc;
			}
		}

		.related {
			.title {
				border-bottom: none !important;

				strong {
					font-size: 2rem;
					letter-spacing: .4rem;
					font-weight: 600 !important;
					border-bottom: none !important;
				}
			}
			.product-item-info {
				width: 100%;
			}
			.products-grid {
				.product-item {
					width: 50%;
				}
			}
		}
	}

	#post-comments * {
		font-family: $main-font;
		.p-text {
			color: $gray1;
		}
	}

	.comments {
		background: $pink1;
		padding: 32px;

		textarea {
			border: none;
			border-radius: 0;
			padding: 20px 20px 20px 46px;
		}
		.textarea-container {
			position: relative;
			&:before {
				position: absolute;
				top: 25px;
				left: 17px;
				content:"";
				@extend .bubble-icon;
			}
		}
	}

	.c-count {
		border: none;
		color: $gray1;
		font-size: 2rem;
		letter-spacing: 0.2rem;
		padding: 0;
		margin: 0 0 32px 0;
		font-weight: 600;
	}

	.c-replyform,
	.c-post{
		margin-left: 0;
	}

	.c-replyform {
		&.no-active{
			textarea {
				height: 60px;
			}
		}
		textarea {
			height: 80px;
		}
		button {
			@extend .button;
			padding: 13px 20px;
		}
		.mage-error {
			background: white !important;
			border: 1px solid red;
		}
	}

	.c-reply,
	.c-comment {
		margin-bottom: 30px;
	}

	.c-post {
		font-size: 1.4rem;
		letter-spacing: 0.14rem;
		line-height: 1.43;
		.p-name {
			color: black;
			font-weight: 600;
		}
		.p-actions {
			padding-bottom: 0px;
			a {
				color: black;
			}
		}
	}

	.p-replies {
		margin-top: 30px;
		border-left: 1px solid black;
		padding-left: 45px;
	}

    .c-allcomments {
        &, &:visited {
            @extend .button;
            width: auto;
            font-weight: 500;
            font-size: 1.4rem;
        }
    }

	.c-comments {
		.actions {
			text-align: center;
		}
	}
}

.sidebar {
	.block-archive .block-content{
		.item {
			margin: 0 !important;

			a {
				font-weight: normal !important;
			}
		}
	}
}
