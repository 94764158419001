@import 'select2/core';

//UL LIST TO SELECT

ul.zg-ul-select {
	border-radius: 2px;
	box-sizing: border-box;
	cursor: pointer;
	margin: 0;
	padding: 0;
	li.item,
	li {
		margin: 0;
		border-radius: 3px;
		display: none;
		padding: 1.1rem 1.5rem !important;
		font-size: 1.3rem;
		letter-spacing: 0.2rem;
		font-weight: 500;
		color: $gray1;
		border-bottom: 1px solid $gray1;
		&:last-child {
			border: none;
		}
		a {
			color: $gray1;
			padding: 0 !important;
		}
		&.active {
			align-items: center;
			border: 1px solid $gray1;
			border-radius: 2px;
			color: #000;
			display: flex;
			justify-content: space-between;
			strong {
				padding: 0;
			}
			.arrow-icon {
				transform: rotate(180deg);
			}
		}
	}
	&.active {
		border: 1px solid $gray1;
		border-radius: 0 0 2px 2px;
    	border-top: none;
    	li.item,
		li {
			border: none;
			border-bottom: 1px solid $gray1;
			box-shadow: none;
			display: block;
			&:hover {
				color: black;
			}
			&.active {
				color: black;
			}
			&:last-child {
				border: none;
			}
		}
	}
}

#selected--zg-ul-select {
	align-items: center;
	box-sizing: border-box;
	color: black;
	display: flex;
	justify-content: space-between;
	width: auto;
	font-size: 1.3rem;
	letter-spacing: 0.2rem;
	font-weight: 500;
	&.active {
		border: 1px solid $gray1;
		border-radius: 2px 2px 0 0;
		padding: 1.1rem 1.5rem !important;
	}
}

//GRAY SELECT

.gray-select {
	.select2-container {
		text-transform: uppercase;
		font-size: 1.2rem;
		letter-spacing: 0.2rem;
		color: $gray1;
	}
	.select2-container--default {
		.select2-selection--single{
			border: none;
			border-radius: 0;
			background-color: #f5f5f5;
			.select2-selection__arrow b{
				@include icon-retina('select-gray', 11px, 7px);
			}
		}
	}
}


