input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
	border: 1px solid $grayfilet;
	height: 48px;
	color: $darkgray;
	@include deskB2;
	border-radius: 0;
	padding: 0 11px;
	&:focus {
		box-shadow: none;
	}
	&::placeholder {
		color: $gray4;
	}
}

textarea {
	border: 1px solid $grayfilet;
	color: $darkgray;
	@include deskB2;
	border-radius: 0;
	padding: 0 11px;
	height: 144px;
	&:focus {
		box-shadow: none !important;
	}
	&::placeholder {
		color: $gray4;
	}
}

select.mage-error {
	& ~ .select2-container .select2-selection { 
		border-color: $red;
	}
}


.abs-margin-for-forms-desktop, 
.fieldset .legend, 
.column:not(.sidebar-main) form .actions-toolbar, 
.column:not(.sidebar-additional) form .actions-toolbar, 
.login-container .fieldset:after {
	margin-left: 0;
}

.no-label {
	label,
	.label{
		border: 0;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

.fieldset {

	& > .field {
		margin: 0 0 15px;
		&:not(.choice) {
			& > .control {
				float: none;
				width: 100%;
			    display: flex;
			    flex-direction: column;
			}
			& > .label {
				float: none;
				width: 100%;
				display: flex;
			}
		}
		&.no-label,
		&.choice {
			&:before {
				content: none;
			}
		}
		&._required,
		&.required {
			& > .label { 
				&:after {
					color: black;
				}
			}
		}
		&:last-child{
			@include tablet-down {
				margin-bottom: 11px;
			}
		}
	}

	& > .fields { 
		display: flex;
		justify-content: space-between;
		column-gap: 12px;

		@include phone { 
			flex-direction: column;
		}
		& > .field {
			flex-grow: 1;
			width: 50%;
			padding-left: 10px;
			@include tablet-down {
				margin-bottom: 11px;
			}
			@include phone { 
				width: 100%;
				padding-left: 0;
			}
			&:first-child {
				padding-left: 0;
				padding-right: 10px;
				@include phone { 
					padding-right: 0;
				}
			}
			&:last-child{
				@include tablet-down {
					margin-bottom: 11px;
				}
			}
			&._required,
			&.required {
				& > .label {
					&:after {
						color: black;
					} 
				}
			}
		}
	}
	& > .field,
	& > .fields {

		& > .field > .label, 
		& > .label {
			color: black;
			padding-left: 0;
			@include tablet-down {
				margin-bottom: 0;
			}
		}
	}
}

.field.street .field.additional .label {
	position: relative;
}
 
.control {
	display: flex;
	flex-direction: column;
	overflow: visible;
	
	.select2 {
		order: 1;
	}

	.mage-error {
		order: 2;
		@include deskB2;
		border-color: $red;
	}

	&.captcha-image .captcha-img {
		margin-right: auto;
		margin-bottom: 15px;
	}

	// Custom Checkbox
    input[type="checkbox"] {
        opacity: 0;
        display: inline-block;
        float: left;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        & + label {
            float: left;
            display: block;
            min-height: $height-checkbox + 5;
            padding: 0 0 4px $width-checkbox + 10;
            width: auto;
            max-width: 100%;
            text-transform: none;
            &:after {
                cursor: pointer;
                content: "";
                display: inline-block;
                width: $width-checkbox;
                height: $height-checkbox;
                border: 1px solid $grayfilet;
                border-radius: 0;
                margin: 0;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:before {
                content: "";
                border: none;
                position: absolute;
                height: $height-checkbox + $padding-checkbox;
                width: $width-checkbox + $padding-checkbox;
                background: $gray8;
                left: $padding-checkbox / 2 ;
                top: $padding-checkbox / 2 ;
                transition: all 250ms ease-in-out;
                opacity: 0;
                transform: scale(0.1);
            }
            &:hover {
                &:after {
                    border-color: $gray8;
                }
            }
        }
        &:checked + label:before,
        &:disabled + label:before {
            opacity: 1;
            transform: scale(0.5);
        }
    }

	// Custom Radio
	input[type="radio"] {
		& + label {
			margin-right: 15px;
			&:before {
				border: 1px solid $grayfilet;
			}
			&:after {
				top: 4px;
				left: 3px;
				background: $gray8;
			}
			&:hover {
				&:before {
					border-color: $gray8;
				}
			}
		}
	}
}

.select-container {
    width: 100%;
}

.actions-toolbar {
    .secondary {
        a.action {
            margin-top: 0;    
        }
    }
}

//QTY selector

.control.qty {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin: 0;
	.soon-qty-updater {
		color: $gray1;
		padding: 0 6px;
		cursor: pointer;
	}
}
