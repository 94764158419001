// Contact, Category
.page-main,
.cms-page-view {
    .dv-cover {
        margin-bottom: 0;

        @include tablet-down {
            margin-bottom: 22px;
            display: inline-table !important;
            width: 100%;
        }

        &.dv-mention{
            padding: 0 40px;
            @include phone {
                padding: 0;
            }
        }

        .pagebuilder-column-line {
            display: flex;
            column-gap: 12px;

            .pagebuilder-column {
                justify-content: center !important;
                text-align: center;
                display: flex;
                flex-direction: column;
                width: 50%;

                figure.image-left {
                    margin: 0;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                &.column-right {
                    padding: 0 120px;

                    @include tablet-down {
                        padding: 0 40px;

                        &:has(> .mention-title) {
                            padding: 0;
                            text-align: left;
                        }
                    }

                    p {
                        text-align: center;
                        margin: 0;
                        color: $gray4;
                    }

                    .dv-ui p {
                        @include deskUI1;
                        color: $darkgray;

                        @include tablet-down {
                            display: none;
                        }
                    }

                    .dv-title {
                        @include deskH1;
                        margin: 15px 0;

                        &.mention-title {
                            @include phone {
                                margin-left: 16px;
                                margin-top: 30px;
                            }
                        }

                        @include tablet-down {
                            @include deskH2;
                            margin-top: 28px;
                            margin-bottom: 9px;
                        }
                    }

                    .cover-description {
                        @include deskB1;
                        color: $gray4;

                        .morecontent span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
