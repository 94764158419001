section {

  &.home_block_type1,
  &.home_block_type2,
  &.quote {
    position: relative;
    float: left;
    width: 100%;
  }

  &.home_block_type1,
  &.home_block_type2 {
    margin-bottom: 140px;
    @include phone {
      margin-bottom: 77px;
    }
  }

  &.home_block_type1 {

    .mobile_title {
      position: relative;
      float: left;
      width: 100%;
      font-family: FuturaBT;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 7.5px;
      text-align: center;
      text-transform: uppercase;
      color: $black;
      margin-bottom: 30px;
      display: none;

      @include phone {
        display: block;
      }
    }

    .wrapper {
      position: relative;
      float: left;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include phone {
        width: 98vw;
        @include center-horizontal;
      }

      .category,
      .product {
        position: relative;
      }

      &.left {
        .category {
          float: left;
          order: 1;
          margin-right: 10%;

          @include phone {
            float: left;
            order: 1;
            margin-right: 0px;
          }
        }

        .product {
          float: right;
          order: 2;

          @include phone {
            float: left;
            order: 2;
          }
        }
      }

      &.right {
        .category {
          float: right;
          order: 2;
          margin-left: 10%;

          @include phone {
            float: left;
            order: 1;
            margin-left: 0px;
          }
        }

        .product {
          float: left;
          order: 1;

          @include phone {
            float: left;
            order: 2;
          }
        }
      }

      .category {
        width: 62%;

        @include phone {
          width: 100%;
        }

        .container {
          position: relative;
          float: left;
          width: 100%;
          height: auto;
          background-size: cover;
          image-rendering: optimizeQuality;

          .ytplayer {
            position: relative;
            float: left;
            width: 100%;
            height: stretch;
          }

          @include phone {
            .ytplayer {
              display: none;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.image_desktop {
              display: block;
            }

            &.image_mobile {
              display: none;
            }

            @include phone {
              &.image_desktop {
                display: none;
              }

              &.image_mobile {
                display: block;
              }
            }
          }

          a {
            position: absolute;
            left: 48px;
            bottom: 48px;
            color: $white;
            font-family: FuturaBT;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include phone {
              display: none;
            }

            span {
              position: relative;
              float: left;

              &.line {
                width: 57px;
                height: 2px;
                margin: 0px 0px 0px 12px;
                background-color: $white;
              }
            }
          }
        }
      }

      .product {
        width: 28%;

        @include phone {
          width: 90%;
          margin-left: 5%;
          margin-top: 41px;
        }

        .title,
        .text,
        .image-container,
        .name,
        .option,
        .price {
          position: relative;
          float: left;
          width: 100%;
        }

        .title {
          font-family: FuturaBT;
          font-size: 25px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 10px;
          color: $black;
          margin-bottom: 40px;

          @include phone {
            display: none;
          }
        }

        .text {
          font-family: FuturaBT;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.88;
          letter-spacing: normal;
          color: $black;
          margin-bottom: 20px;

          @include phone {
            display: none;
          }
        }

        .image-container {
          position: relative;
          float: left;
          margin-bottom: 21px;
          background-size: cover;
          image-rendering: optimizeQuality;

          img {
            position: relative;
            float: left;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.image_desktop {
              display: block;
            }

            &.image_mobile {
              display: none;
            }

            @include phone {
              &.image_desktop {
                display: none;
              }

              &.image_mobile {
                display: block;
              }
            }
          }
        }

        .name {
          font-family: FuturaBT;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: 2.3px;
          color: $black;
          margin-bottom: 5px;

          @include phone {
            font-size: 14px;
          }
        }

        .option {
          font-family: FuturaBT;
          font-size: 14px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 2px;
          color: $gray11;
          margin-bottom: 17px;

          @include phone {
            font-size: 12px;
          }
        }

        .price {
          font-family: FuturaBT;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.14;
          letter-spacing: 1.4px;
          color: $black;

          @include phone {
            font-size: 12px;
          }
        }

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
        }
      }
    }
  }

  &.quote {
    margin-top: 40px;
    margin-bottom: 168px;

    @include phone {
      display: none;
    }

    .title,
    .subtitle,
    .text,
    .line {
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
    }

    .title {
      font-family: FuturaBT;
      font-size: 45px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 22.5px;
      color: $black;
      margin-bottom: 39px;
    }

    .subtitle {
      font-family: FuturaBT;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 4.4px;
      color: $black;
      margin-bottom: 61px;
    }

    .text {
      font-family: FuturaBT;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.89;
      letter-spacing: 1.8px;
      color: $black;
      margin-bottom: 61px;
    }

    .line {
      width: 98px;
      height: 1px;
      @include center-horizontal;
      background-color: $black;
    }
  }

  &.home_block_type2 {
    width: 98vw;
    @include center-horizontal;

    @include phone {
      margin-top: 0px;
    }

    .mobile_title {
      position: relative;
      float: left;
      width: 100%;
      font-family: FuturaBT;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 7.5px;
      text-align: center;
      text-transform: uppercase;
      color: $black;
      margin-bottom: 30px;
      display: none;

      @include phone {
        display: block;
      }
    }

    .wrapper {
      position: relative;
      float: left;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .category,
      .product {
        position: relative;
      }

      &.left {
        .category {
          float: left;
          order: 1;
          margin-right: 10%;

          @include phone {
            order: 2;
            float: left;
            margin-right: 0px;
          }
        }

        .product {
          float: right;
          order: 2;

          @include phone {
            order: 1;
            float: left;
          }
        }
      }

      &.right {
        .category {
          float: right;
          order: 2;
          margin-left: 10%;

          @include phone {
            order: 2;
            float: left;
            margin-left: 0px;
          }
        }

        .product {
          float: left;
          order: 1;

          @include phone {
            order: 1;
            float: left;
          }
        }
      }

      .category {
        width: 34%;

        @include phone {
          width: 100%;
          margin-top: 58px;
        }

        .title,
        .text {
          position: relative;
          float: left;
          width: 100%;

          @include phone {
            display: none;
          }
        }

        .title {
          font-family: FuturaBT;
          font-size: 25px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 10px;
          color: $black;
          margin-bottom: 40px;
        }

        .text {
          font-family: FuturaBT;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.88;
          letter-spacing: normal;
          color: $black;
          margin-bottom: 20px;
        }

        .container {
          position: relative;
          float: left;
          width: 100%;
          height: auto;
          background-size: cover;
          image-rendering: optimizeQuality;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.image_desktop {
              display: block;
            }

            &.image_mobile {
              display: none;
            }

            @include phone {
              &.image_desktop {
                display: none;
              }

              &.image_mobile {
                display: block;
              }
            }
          }

          a {
            position: absolute;
            left: 48px;
            bottom: 48px;
            color: $white;
            font-family: FuturaBT;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $white;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            span {
              position: relative;
              float: left;

              &.line {
                width: 57px;
                height: 2px;
                margin: 0px 0px 0px 12px;
                background-color: $white;
              }
            }
          }
        }
      }

      .product {
        width: 56%;

        @include phone {
          width: 100%;
        }

        .image-container {
          position: relative;
          float: left;
          width: 100%;
          background-size: cover;
          image-rendering: optimizeQuality;

          img {
            position: relative;
            float: left;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.image_desktop {
              display: block;
            }

            &.image_mobile {
              display: none;
            }

            @include phone {
              &.image_desktop {
                display: none;
              }

              &.image_mobile {
                display: block;
              }
            }
          }

          .main {
            position: absolute;
            width: 54%;
            height: auto;
            @include center-both;
            z-index: 2;

            @include phone {
              width: 80%;
            }
          }

          .bg {
            position: relative;
            float: left;
            width: 100%;
          }
        }

        .centerer {
          position: relative;
          float: left;
          width: auto;
          @include center-horizontal;
          margin-top: 29px;

          @include phone {
            width: 80%;
            margin-left: 10%;
            left: auto;
            transform: none;
          }

          .name,
          .option,
          .price {
            position: relative;
            float: left;
            clear: both;
          }

          .name {
            font-family: FuturaBT;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: 2.3px;
            color: $black;
            margin-bottom: 5px;

            @include phone {
              font-size: 14px;
            }
          }

          .option {
            font-family: FuturaBT;
            font-size: 14px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 2px;
            color: $gray11;
            margin-bottom: 17px;

            @include phone {
              font-size: 12px;
            }
          }

          .price {
            font-family: FuturaBT;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.14;
            letter-spacing: 1.4px;
            color: $black;

            @include phone {
              font-size: 12px;
            }
          }
        }

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
        }
      }
    }
  }
}
