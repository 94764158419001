html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Custom scrollbar
*::-webkit-scrollbar {
  width: 4px;
}
*::-webkit-scrollbar-track {
    background: transparent;
}
*::-webkit-scrollbar-thumb {
    background: $grayfilet;
    border-radius: 6px;
}

body {
  overflow-x: hidden;
  color: black;
}

.breadcrumbs,
.page-header .header.panel,
.header.content,
.footer.content .footer,
.footer.content .copyright,
.page-wrapper>.widget,
.page-wrapper>.page-bottom,
.block.category.event,
.top-container,
.page-main {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

.breadcrumbs,
.page-header .header.panel,
.header.content,
.footer.content,
.page-main,
.page-wrapper>.widget,
.page-wrapper>.page-bottom,
.block.category.event,
.top-container {
  @include phone {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sections.nav-sections {
  margin: 0;
}

.page-wrapper {
  position: relative;
  width: 100%;
}

.main-wrapper {
  position: relative;
  width: 100%;
  margin-top: $header-height;

  @include bigphone {
    margin-top: $header-height-mobile;
  }
}

// VISIBILITY

.mobile-only {
  @include tablet {
    display: none !important;
  }
}

.mobile-hidden {
  @include phone {
    display: none !important;
  }
}

.tablet-only {
  @include desktop {
    display: none !important;
  }
}

.tablet-hidden {
  @include bigphone {
    display: none !important;
  }
}

// COVER

.cover {
  position: relative;
  margin-bottom: 70px;

  @include phone {
    margin: 0 -20px 20px;
  }

  h1 {
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    letter-spacing: 1.74rem;
    width: 90%;

    @include tablet-down {
      font-size: 2.2rem;
      letter-spacing: 0.4rem;
    }

    @include phone {
      font-size: 1.2rem;
      letter-spacing: 0.4rem;
      width: 85%;
      padding: 0 20px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.scroll-down {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border: 1px solid #b3b3b3;
  border-radius: 100%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    border-right: 2px solid #b3b3b3;
    border-bottom: 2px solid #b3b3b3;
    transform: rotate(45deg);
    position: absolute;
    bottom: 8px;
    left: 50%;
    margin-left: -4px;
  }

  @include phone {
    display: none;
  }
}

//responsive embed
.embed-container {
  position: relative;
  padding-bottom: 35.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    @include phone {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

//Messages
.message {
  &.success {
    padding: 16px 20px;
    margin-bottom: 0;
    background-color: #ffefe4;
    text-align: center;
    color: black;
    @include deskB1;

    &>*:first-child:before {
      content: none;
    }
  }
}

//breadcrumbs
.breadcrumbs {
  margin: 0 auto;
  padding: 14px 40px 16px;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.29rem;
  @include phone {
    display: none;
  }
  .item:not(:last-child):after {
    content: "";
    width: 5px;
    height: 5px;
    border: 1px solid black;
    border-radius: 100%;
    vertical-align: middle;
    margin: -3px 8px 0;
  }
  a {
    color: $gray1;
    &:visited,
    &:focus,
    &:active {
      color: $gray1;
      text-decoration: none;
    }

    &:hover {
      color: black;
      text-decoration: none;
    }
  }
  strong {
    font-weight: 500;
    color: black;
  }
}

//Return
.return-link {
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.12rem;
  font-weight: 500;
  &:before {
    content: "";
    @extend .arrow-icon;
    transform: rotate(270deg);
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
  }
}

//POPUPS

.modals-wrapper {
  position: relative;
  z-index: 10000;
}


.modal-popup {
  &.confirm {
    .modal-inner-wrap {
      width: auto;
      min-width: 600px;
      max-width: 75%;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      text-align: center;
      @include phone {
        transform: translateX(0%);
        left: 0;
        right: 0;
        max-width: 100%;
        min-width: auto;
      }
    }
    .action-accept {
      @extend .black-button;
    }
  }
  &.product-alert-stock-modal {
    align-items: center;
    display: flex;
  }
  .modal-title {
    border: none;
    padding-bottom: 0;
    font-size: 2.3rem;
    letter-spacing: 0.2rem;
    margin-bottom: 20px;
    font-weight: 500;
    text-transform: none;
  }
}

//  Loading mask
.loading-old,
.loading-mask {
  background: transparent;
  .loader {
    background-color: transparent;
    height: 110px;
    width: 110px;
  }
}

// MAIN PRELOADER
//fix for scroll push
body {
  overflow-y: scroll;
}

#website-preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: $white;
  opacity: 1;
  visibility: visible;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 190px;
    transition: all 0.5s ease-in-out;
    opacity: 1;

    @include phone{
      width: 140px;
    }
  }

  &.unload {
    transition: visibility 0s, opacity 0.8s linear;
    visibility: visible;
    opacity: 1;
  }

  &.load {
    //transition: opacity 0.8s linear, visibility 0.8s;
    transition: all 0.8s ease-in-out;
    transition-delay: 0.5s;
    opacity: 0;
    visibility: hidden;

    img {
      opacity: 0;
    }
  }
}

