// =============================================
// Soon Jade Frontend SCSS
// =============================================
@charset "UTF-8";

// Import modules
// -> Interactions
@import "_jade-interact";

// Additional styles
a:visited, .alink:visited {
  color: #1eacad;
  text-decoration: none;
}
a, .alink {
  color: #1eacad;
  text-decoration: none;
}
