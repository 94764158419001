// Search page
.catalogsearch-result-index {
	.main-wrapper {
		@include tablet-down {
			margin-top: $header-height-mobile + 6px;
		}

		.page-title {
			margin: 23px 0 57px;
			span{
				@include deskH2;
			}

			@include tablet-down {
				margin: 24px 19px 27px;
			}
		}

		.column.main {
			align-content: center;

			#search-result-product-list {
				position: relative;

				.search.results {
					padding: 0;

					.products-grid {
						position: relative;
						float: left;
						width: 100%;
						padding: 0;
						margin: 0;

						@include tablet-down {
							padding: 0 16px;
						}

						.product-items {
							display: grid;
							grid-gap: 13px;
							grid-row-gap: 37px;
							grid-template-columns: repeat(4, 1fr);
							margin-left: 0;

							@include tablet-down {
								grid-gap: 10px;
								grid-row-gap: 13px;
								grid-column-gap: 10px;
								grid-template-columns: repeat(2, 1fr);
								margin-top: 0;
							}

							.product-item {
								margin: 0;
								grid-column: span 1;
								grid-row: span 1;
								width: auto;
								margin-bottom: 0;
								padding-left: 0px;
								border: none;
								background: none;

								&.fake-item {
									grid-row: span 2;
									grid-column: span 2;
									display: flex;

									@include tablet-down {
										display: none;
									}

									img {
										max-width: 100%;
									}

									.product-item-name {
										color: black;
										text-align: center;
									}

									.product-item-description {
										color: black;
										text-align: center;
										width: 100%;
									}

									.price-box .price {
										color: white;
									}


									img {
										width: 100%;
										position: relative;
									}

									.product-item-info {
										position: relative;
									}

									.product-item-details {
										position: relative;
										bottom: 0;
										left: 0;
										right: 0;
										padding: 0;
									}
								}

								.product-item-info {
									width: 100%;
									position: relative;

									.news-tag {
										position: absolute;
										display: flex;
										align-items: center;
										justify-content: space-around;
										width: 94px;
										height: 24px;
										@include deskUI3;
										z-index: 10;
										color: $white;
										background-color: $darkgray;
									}

									.product-item-photo {
										background: transparent;
										display: block;
										overflow: hidden;
										display: block;
										width: 100%;
										background: #f5f5f5;
										display: flex;
										justify-content: center;
										align-items: center;

										img {
											width: 100%; //190px;
											height: auto;
											max-width: 100%; //55%;
											position: relative;
											display: block;

											&:last-child {
												display: none;
											}
										}

										@include desktop {
											&:hover {
												img {
													display: none;

													&:last-child {
														display: block;
													}
												}
											}
										}
									}

									.product-item-details {
										.product-item-name {
											.product-item-link {
												overflow: hidden;
												display: -webkit-box;
												-webkit-line-clamp: 2;
												-webkit-box-orient: vertical;
												word-break: keep-all;
												-webkit-hyphens: none;
												-moz-hyphens: none;
												-ms-hyphens: none;
												-o-hyphens: none;
												hyphens: none;
											}
										}

										.product-subtitle {
											@include deskB2;
											color: $gray4;
										}
									}
								}

								.product-item-inner {
									display: none;
								}
							}
						}

					}
				}

				.message.notice {
					@include deskH2;
					color: $gray4;
					background: none;
					text-align: center;

					&>*:first-child:before {
						content: none;
					}

					@include tablet-down {
						@include deskH3;
						margin-top: 61px;
					}
				}
			}
		}
	}
}

// Search modal
#search-modal {
	height: 100%;
	width: 100%;
	position: fixed;
	top: $header-height;
	background-color: $darkgray50;

	@include tablet-down {
		top: $header-height-mobile;
	}

	#search-modal-container {
		position: relative;
		width: 100%;
		min-height: 400px;
		height: auto;
		max-height: 85%;
		overflow: auto;
		background-color: $white;
		border-top: $grayfilet solid 1px;
		padding: 22px 40px 33px;

		@include tablet-down {
			height: calc(100dvh - #{$header-height-mobile});
			max-height: calc(100dvh - #{$header-height-mobile});
			padding: 32px 16px 35px;
		}

		.toggle-search {
			position: absolute;
			top: 44px;
			right: 40px;
			z-index: 10;

			@include tablet-down {
				top: 52px;
				right: 16px;
			}

			&::before {
				content: '';
				@include icon-svg('close_black', 24px, 24px);
			}
		}

		.form.minisearch {
			.control {
				margin: 0;

				input {
					width: 80%;
					border: none;
					width: 80%;
					height: 64px;
					@include deskH2;
				}

				#search_autocomplete {
					position: relative;

					.products-grid {
						position: relative;
						float: left;
						width: 100%;
						padding: 34px 0 70px;
						margin: 0;

						@include tablet-down {
							padding: 55px 0 20px;
						}

						.product-items {
							display: grid;
							grid-gap: 2%;
							grid-row-gap: 37px;
							grid-template-columns: repeat(4, 1fr);
							margin-left: 0;

							@include tablet-down {
								grid-gap: 10px;
								grid-row-gap: 13px;
								grid-column-gap: 10px;
								grid-template-columns: repeat(2, 1fr);
								margin-top: 0;
							}

							.product-item {
								margin: 0;
								grid-column: span 1;
								grid-row: span 1;
								width: auto;
								margin-bottom: 0;
								padding-left: 0px;
								border: none;
								background: none;

								&.fake-item {
									grid-row: span 2;
									grid-column: span 2;
									display: flex;

									@include tablet-down {
										display: none;
									}

									img {
										max-width: 100%;
									}

									.product-item-name {
										color: black;
										text-align: center;
									}

									.product-item-description {
										color: black;
										text-align: center;
										width: 100%;
									}

									.price-box .price {
										color: white;
									}


									img {
										width: 100%;
										position: relative;
									}

									.product-item-info {
										position: relative;
									}

									.product-item-details {
										position: relative;
										bottom: 0;
										left: 0;
										right: 0;
										padding: 0;
									}
								}

								.product-item-info {
									width: 100%;
									position: relative;

									.news-tag {
										position: absolute;
										display: flex;
										align-items: center;
										justify-content: space-around;
										width: 94px;
										height: 24px;
										@include deskUI3;
										z-index: 10;
										color: $white;
										background-color: $darkgray;
									}

									.product-item-photo {
										background: transparent;
										display: block;
										overflow: hidden;
										display: block;
										width: 100%;
										background: #f5f5f5;
										display: flex;
										justify-content: center;
										align-items: center;

										img {
											width: 100%; //190px;
											height: auto;
											max-width: 100%; //55%;
											position: relative;
											display: block;

											&:last-child {
												display: none;
											}
										}

										@include desktop {
											&:hover {
												img {
													display: none;

													&:last-child {
														display: block;
													}
												}
											}
										}
									}

									.product-item-details {
										.product-item-name {
											.product-item-link {
												overflow: hidden;
												display: -webkit-box;
												-webkit-line-clamp: 2;
												-webkit-box-orient: vertical;
												word-break: keep-all;
												-webkit-hyphens: none;
												-moz-hyphens: none;
												-ms-hyphens: none;
												-o-hyphens: none;
												hyphens: none;
											}
										}

										.product-subtitle {
											@include deskB2;
											color: $gray4;
										}
									}
								}

								.product-item-inner {
									display: none;
								}
							}
						}
					}

					.message.notice {
						@include deskH2;
						color: $gray4;
						background: none;
						text-align: center;
						padding: 0;
						margin-top: 96px;

						@include tablet-down {
							@include deskH3;
							margin-top: 71px;
						}

						&>*:first-child:before {
							content: none;
						}
					}
				}

				.navigation_search {
					margin-top: 61px;

					@include tablet-down {
						margin-top: 48px;
					}

					a {
						text-decoration: none;
					}

					.dropdown-menu {
						display: contents;

						.megamenu-content {
							display: flex;
							column-gap: 95px;

							@include tablet-down {
								flex-direction: column;
								row-gap: 16px;


								@include tablet-down {
									row-gap: 46px;
								}
							}

							.parent {
								.title span{
									@include deskUI1;
									color: $darkgray;
								}

								.children {
									display: flex;
									flex-direction: column;
									row-gap: 8px;
									padding-top: 21px;

									@include tablet-down {
										padding-top: 17px;
									}

									.gray {
										@include deskB1;
										color: $gray4;

										@include tablet-down {
											margin: 0;
											text-align: left;
										}

										&.bold {
											color: $darkgray;
										}
									}
								}
							}
						}
					}
				}
			}

			.actions {
				text-align: center;

				.action {
					@include deskBtn3;
					border: none;
				}
			}
		}

	}
}

body.search-open {
	overflow: hidden;
}
