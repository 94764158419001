//VIEW
.soon_storelocator-store-view {
	.page-main {
		padding: 0;

		.column.main {
			padding: 0;

			.store-view {
				position: relative;
				display: flex;
				column-gap: 14px;
				margin-bottom: 66px;

				@include tablet-down {
					flex-direction: column;
					margin-bottom: 98px;
				}

				.store-slider {
					position: relative;

					&:has(*) {
						flex: 1 1 0;
					}

					.slideshow-container {
						position: relative;

						.menu {
							position: absolute;
							bottom: 20px;
							left: 50%;
							transform: translate(-50%, -50%);

							@include tablet-down {
								bottom: 10px;
							}

							.dot {
								height: 6px;
								width: 6px;
								background-color: $white;
								opacity: 50%;
								border-radius: 50%;
								display: inline-block;
								margin-left: 24px;
							}

							.active {
								opacity: 100%;
							}
						}

						img {
							width: 100%;
						}
					}
				}

				.information-store {
					flex: 1 1 0;

					.information-store-content {
						margin: 0 36px 0 43px;
						position: relative;

						@include tablet-down {
							margin: 0 16px;
						}

						.store-name {
							margin-bottom: -4px;
							margin-right: 250px;

							@include tablet-down {
								margin-top: 24px;
								margin-right: 80px;
							}
						}

						.category-label {
							@include deskUI3;


							@include tablet-down {
								position: absolute;
								right: 0;
								top: 15px;
							}
						}

						.button-rdv {
							position: absolute;
							top: 0;
							right: 0;
							@include deskBtn1;
							text-align: center;
							color: white;
							padding: 16px 26px 17px;

							@include tablet-down {
								width: 100%;
								top: auto;
								bottom: -73px;
							}
						}

						.store-info {
							border-top: 1px solid $grayfilet;
							margin-top: 18px;

							@include tablet-down {
								margin-top: 26px;
							}

							.info-container {
								border-bottom: 1px solid $grayfilet;

								.into-title {
									display: flex;
									align-items: center;
									column-gap: 16px;
									padding: 16px 0;
									@include deskB1;

									&::before {
										content: "";
										min-width: 16px;
									}

									&.hours {
										&::before {
											@include icon-svg('store_locator/hours', 16px, 16px);
										}
									}

									&.special_opening_time {
										&::before {
											@include icon-svg('store_locator/special_hours', 16px, 16px);
										}
									}

									&.address {
										&::before {
											@include icon-svg('store_locator/adress', 16px, 16px);
										}
									}

									&.whatsapp_number {
										&::before {
											@include icon-svg('store_locator/whatsapp', 16px, 16px);
										}
									}

									&.link-google {
										&::before {
											@include icon-svg('store_locator/maps', 16px, 16px);
										}
									}

									&.services {
										&::before {
											@include icon-svg('store_locator/services', 16px, 16px);
										}
									}

									&.websitelink {
										&::before {
											@include icon-svg('store_locator/website', 16px, 16px);
										}
									}

									&.telephone {
										&::before {
											@include icon-svg('store_locator/phone', 16px, 16px);
										}
									}

									.arrow-down {
										margin-right: 13px;
										margin-left: auto;
										@include icon-svg('arrow_down_black', 16px, 16px);

										@include tablet-down {
											margin-right: 0;
										}
									}
								}

								.collapsible {
									display: none;
									padding-top: 7px;
									padding-bottom: 20px;
									@include deskB1;

									@include tablet-down {
										padding-top: 4px;
									}

									&>* {
										// .opening-hours {
										display: flex;
										justify-content: space-between;
										padding-right: 3%;
										padding-bottom: 25px;

										@include tablet-down {
											padding-bottom: 20px;
										}

										&.opening-hours {
											max-width: 50%;

											@include tablet-down {
												max-width: 65%;
											}
										}
									}
								}

								.hours {
									.days {
										display: grid;
										grid-template-columns: max-content auto;
										row-gap: 10px;
										column-gap: 30px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


//LIST
.soon_storelocator-store-index {
	.page-main {
		padding: 0;

		.column.main {
			padding: 0;

			.store-list {
				.map-area {
					display: flex;
					justify-content: stretch;
					height: calc(100dvh - #{$header-height});
					overflow: hidden;
					flex-direction: column;
					flex-wrap: wrap;

					@include tablet-down {
						height: auto;
					}

					&>div {
						width: calc(35%);

						@include tablet-down {
							width: 100dvw;
						}
					}

					&>div:last-child {
						flex: 0 0 calc(100dvh - #{$header-height});
						width: calc(65%);

						@include tablet-down {
							flex: none;
							width: 100%;
							height: 300px;
							order: 1;
						}
					}

					.searchband {
						height: 10rem;

						.search-block {
							display: flex;
							flex-wrap: wrap;
							padding-left: 40px;
							padding-right: 18px;

							@include tablet-down {
								padding-left: 16px;
								padding-right: 17px;
								padding-bottom: 12px;
							}

							&:first-child {
								width: 100%;
							}

							&:not(:first-child) {
								flex: 1;
							}

							label {
								@include deskUI1;
								width: 100%;
								padding-bottom: 18px;

								@include tablet-down {
									padding-bottom: 16px;
								}
							}

							.input-search-container {
								position: relative;
								width: 100%;
								flex: 1;

								button {
									border: none;
									padding: 0;
									position: absolute;
									top: 50%;
									transform: translateY(-50%);

									.search-icon {
										@include icon-svg('store_locator/pin_search', 24px, 24px);
										margin-left: 15px;
									}
								}

								input {
									padding-left: 55px;
								}
							}

							.geolocation-block {
								.pin-icon {
									@include icon-svg('map-geolocalisation', 48px, 48px);
								}
							}

						}

					}

					#store-list-content {
						height: calc(100% - 10rem);

						@include tablet-down {
							order: 2;
						}

						.selectstores-block {
							text-align: end;
							margin-top: 3px;
							height: 37px;

							@include tablet-down {
								margin-top: 15px;
							}

							label {
								margin-right: 22px;
								color: $gray1;
							}

							.select-container {
								.select2 {
									.selection {
										.select2-selection {
											display: flex;
											height: auto;

											.select2-selection__rendered {
												@include deskB1;
												color: $black;
												padding: 0;
												text-transform: none;
											}

											.select2-selection__arrow {
												height: 20px;
												left: 68px;

												b {
													margin-top: 5px;
													margin-left: 5px;
													@include icon-svg('arrow_down_black', 16px, 16px);
												}
											}
										}
									}
								}
							}
						}


						.scroller {
							overflow-y: auto;
							max-height: 90%;
							padding-left: 20px;

							@include tablet-down {
								max-height: 800px;
								margin-bottom: 30px;
								padding-left: 0;
							}

							.no-store {
								@include deskH3;
								color: $gray4;
								margin: 24px 20px;

								@include tablet-down {
									text-align: center;
									margin-bottom: 65px;
								}
							}

							// Custom scrollbar
							&::-webkit-scrollbar {
								width: 0;
							}

							&::-webkit-scrollbar-track {
								background: transparent;
							}

							&::-webkit-scrollbar-thumb {
								background: transparent;
							}

							#sl-list {
								list-style-type: none;
								padding: 0;
								margin: 0 20px;

								@include tablet-down {
									margin: 0 17px;
								}

								li {
									margin-bottom: 16px;
									border: 1px solid $grayfilet;
									position: relative;
									min-height: 112px;

									&.active {
										border-left: 4px solid $black;
										background-color: $bgbeige;
									}

									.category-label {
										position: absolute;
										right: 0;
										@include deskUI3;
										margin: 15px 13px 0 0;
									}

									.store-name {
										@include deskH2;
										margin: 13px 100px 0 13px;
										display: block;
									}

									.address {
										@include deskB2;
										color: $gray4;
										margin: 7px 0 14px 13px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@include tablet-down {
		&>.select2-container--sorter-options.select2-container--open {
			position: fixed !important;
			bottom: 0;
			height: 100dvh;
			top: 0 !important;
			left: 0 !important;
			width: 100%;
			background-color: $darkgray50;
			z-index: 10000;

			.select2-dropdown {
				border: none;
				border-radius: 0;
				width: 100dvw !important;
				right: 0;
				left: 0;
				bottom: 0;

				.select2-results__options {
					padding: 10px 0 24px 0;

					li.select2-results__option {
						width: auto;
						display: flex;
						justify-content: space-between;
						border: none;
						padding: 15px 20px;

						p {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
